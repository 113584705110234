import { createAction } from 'react-async-client';

import * as actions from '../constants/actionTypes';

export const closeModal = createAction(actions.CLOSE_MODAL);
export const hideModal = createAction(actions.HIDE_MODAL);
export const showModal = createAction(actions.SHOW_MODAL);

export const openVideoModal = createAction(actions.OPEN_VIDEO_MODAL);
export const openPasswordModal = createAction(actions.OPEN_PASSWORD_MODAL);
export const openCoverLetterModal = createAction(actions.OPEN_COVER_LETTER_MODAL);
export const openResumeModal = createAction(actions.OPEN_RESUME_MODAL);
export const openResumeViewModal = createAction(actions.OPEN_RESUME_VIEW_MODAL);
export const openSendResumeModal = createAction(actions.OPEN_SEND_RESUME_MODAL);
export const openHHResumesModal = createAction(actions.OPEN_HH_RESUMES_MODAL);
export const openMotivationModal = createAction(actions.OPEN_MOTIVATION_MODAL);
export const openPersonalityModal = createAction(actions.OPEN_PERSONALITY_MODAL);
export const openCriteriaModal = createAction(actions.OPEN_CRITERIA_MODAL);
export const openPresentationModal = createAction(actions.OPEN_PRESENTATION_MODAL);
export const openPresentationViewModal = createAction(actions.OPEN_PRESENTATION_VIEW_MODAL);
export const openCoverLetterViewModal = createAction(actions.OPEN_COVER_LETTER_VIEW_MODAL);
export const openEventModal = createAction(actions.OPEN_EVENT_MODAL);
export const openConsultationModal = createAction(actions.OPEN_CONSULTATION_MODAL);
export const openConsultationViewModal = createAction(actions.OPEN_CONSULTATION_VIEW_MODAL);
export const openCancelConsultationModal = createAction(actions.OPEN_CANCEL_CONSULTATION_MODAL);
export const openPromotionRequestModal = createAction(actions.OPEN_PROMOTION_REQUEST_MODAL);
export const openTestRequestModal = createAction(actions.OPEN_TEST_REQUEST_MODAL);
export const openDocumentModal = createAction(actions.OPEN_DOCUMENT_MODAL);
