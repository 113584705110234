import React from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';

import UserRoutes from '../../UserRoutes';
import Menu from './Menu';
import Sider from './Sider';

const UserLayout = () => {
    const history = useHistory();

    return <Layout className='layout-box'>
        <Menu history={history} />
        <Layout className='content-box'>
            <Sider />
            <UserRoutes />
        </Layout>
    </Layout>
};

export default UserLayout;
