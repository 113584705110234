import React from 'react';
import { Modal } from 'antd';

const VideoModal = ({ modal }) => (
    <Modal
        {...modal}
        title='Видео'
        width={800}
        footer={null}>
        <iframe
            title='Видео'
            width='100%'
            height='415'
            src='https://www.youtube.com/embed/FqAxgsyraRE?rel=0&amp;showinfo=0'
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen />
    </Modal>
);

export default VideoModal;
