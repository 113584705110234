import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import {Layout, Spin, Empty} from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getStages } from '../actions/asyncActions';

const WrapBox = styled(Layout)`
    background: #fff;
    box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
    border-radius: 12px;
    padding: 24px;
    flex: initial;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 16px;
    }
`;

class Favorites extends Component {
    renderData = () => {
        const { getStages: { data }} = this.props;
        const items = data.items || [];

        return items.length ? items.map(item =>
            <WrapBox key={item.id}>
                <h2>{ item.title }</h2>
                { item.themes.map((theme, index) =>
                    <div key={`theme-${index}`}>
                        <h3>{ theme.title }</h3>
                        { theme.materials.map(material =>
                            <div key={material.id}>
                                <Link to={`/stage/${item.id}/material/${material.id}`}>{ material.title }</Link>
                            </div>
                        )}
                    </div>
                )}
            </WrapBox>
        ) : <WrapBox><Empty description='Нет данных' image={Empty.PRESENTED_IMAGE_SIMPLE} /></WrapBox>;
    }

    render() {
        const { getStages: { meta }} = this.props;

        return meta.pending && !meta.lastSucceedAt ? <Spin /> : this.renderData();
    }
}

export default withAsyncActions({
    getStages: getStages
        .withPayload(() => ({
            q: { favorite: true }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Favorites);
