import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button, Row, Col } from 'antd';
import * as yup from 'yup';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import PersonalAgreementFields from './PersonalAgreementFields';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 }
};
const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 }
};

class ResetForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, values, agreement } = this.props;

        return <Fragment>
            <Field
                name='password'
                component={Input}
                label='Пароль'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                htmlType='password'
                labelError />
            <Field
                name='passwordRepeat'
                component={Input}
                label='Пароль повторно'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                htmlType='password'
                extra='Пароль должен содержать более 6 символов, строчные и заглавные буквы латинского алфавита и цифры'
                labelError
            />
            { !agreement &&
                <PersonalAgreementFields
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    label={<span />} />
            }
            <Row>
                <Col span={{ xs: 24, sm: 24, md: 10 }} />
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14, offset: 10 }}>
                    <Button
                        className='btn-control'
                        type='primary'
                        htmlType='submit'
                        size='large'
                        disabled={isSubmitting || (!values.personalAgreement && !agreement)}>
                        Отправить
                    </Button>
                </Col>
            </Row>
        </Fragment>;
    }
}

const validationSchema = props => yup.object().shape({
    password: yup.string().match('passwordRepeat').min(6).required(),
    passwordRepeat: yup.string().match('password').min(6).required(),
    personalAgreement: props.agreement ? yup.bool() : yup.bool().required()
});

export default withFormWrapper(ResetForm, {
    mapPropsToValues: ({ token }) => ({
        token,
        newsletterAgreement: true
    }),
    validationSchema,
    subscriptions: { values: true }
});
