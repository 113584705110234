import React, { Component } from 'react';
import { Modal, message, Button } from 'antd';
import { getCoverLetterText, getCoverLetterTextHtml } from '@meconsultant/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class CoverLetterViewModal extends Component {
    render() {
        const { modal, params } = this.props;
        const text = getCoverLetterText(params);

        return <Modal
            {...modal}
            title='Сопроводительное письмо'
            footer={null}>
            <div dangerouslySetInnerHTML={{ __html: getCoverLetterTextHtml(params) }} style={{ marginBottom: 15 }} />
            <div>
                <CopyToClipboard text={text} onCopy={() => message.success('Сопроводительное письмо успешно скопировано')}>
                    <Button type='primary'>
                        Скопировать
                    </Button>
                </CopyToClipboard>
            </div>
        </Modal>;
    }
}
