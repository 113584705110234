import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Spin, Alert } from 'antd';

import { getHHResume } from '../actions/asyncActions';

class HHResume extends Component {
    render() {
        const { getHHResume } = this.props;

        return getHHResume.meta.pending ?
            <Spin /> : getHHResume.meta.lastSucceedAt ?
            this.props.children(getHHResume.data.data) : <Alert message='Не удалось загрузить резюме HeadHunter' />;
    }
}

export default withAsyncActions({
    getHHResume: getHHResume
        .withPayload(({ id }) => id)
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(HHResume);
