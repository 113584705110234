export default {
    mixed: {
        required: 'Это поле обязательно',
    },

    string: {
        email: 'Неверный формат email',
        min: 'Минимум 6 символов',
    }
};
