import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { MotivationAnalysisForm } from '@meconsultant/common';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import { putProfilePath, getSettings } from '../../actions/asyncActions';

const ModalMotivation = styled(Modal)`
    .ant-modal-content {
        .ant-modal-body {
            padding: 0 0 10px;
            strong {
                display: block;
                background: #fafafa;
                border-bottom: 1px solid #f0f0f0;
                font-size: 15px;
                padding: 16px 24px;
                margin: 0 -24px 16px;
                @media (max-width: 767px) {
                    padding: 10px 16px;
                    margin: 0 -16px 16px;
                }
            }
            td {
                padding: 0 24px;
                border-bottom: 1px solid #f0f0f0;
                @media (max-width: 767px) {
                    padding: 0 16px;
                }
            }
            .ant-btn {
                margin: 16px 24px 8px;
                @media (max-width: 767px) {
                    margin: 16px 16px 8px;
                }
            }
        }
    }
`;

class MotivationModal extends Component {
    render() {
        const { modal, params, putProfilePath, scale } = this.props;

        return <ModalMotivation
            {...modal}
            title='Анализ мотивации'
            footer={null}>
            <MotivationAnalysisForm
                formAction={putProfilePath}
                data={params || {}}
                scale={scale} />
        </ModalMotivation>;
    }
}

const stateToProps = state => ({
    scale: pathOr([], ['motivation', 'scale'], getSettings.selectData(state))
});

export default asyncConnect({
    putProfilePath: putProfilePath
        .withSuccessHandler(({ close }) => {
            message.success('Анализ мотивации успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(MotivationModal);
