import React, { Component } from 'react';
import { toSuccess, asyncConnect } from 'react-async-client';
import { Button, Spin } from 'antd';
import { pathOr } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { generateCriteriaPdf } from '@meconsultant/common';
import { FilePdfOutlined, EditOutlined } from '@ant-design/icons';

import { getProfilePath } from '../../actions/asyncActions';
import { openCriteriaModal } from '../../actions/modalActions';
import { PUT_PROFILE_PATH } from '../../constants/actionTypes';

class Criteria extends Component {
    renderData = () => {
        const { data } = this.props.getProfilePath;
        const criteria = pathOr([], ['criteria'], data);
        const customCriteria = pathOr([], ['customCriteria'], data);
        const categories = pathOr({}, ['categories'], data);

        return <div>
            { criteria.map((name, index) =>
                <div key={`criteria-${index}`} style={{ marginBottom: 15 }}>
                    <div><strong>{ name }{(categories[name] || []).length ? ':' : ''}</strong></div>
                    { (categories[name] || []).map((item, i) =>
                        <div key={`category-${index}-${i}`}>- { item }</div>
                    )}
                </div>
            )}
            { customCriteria.map((name, index) =>
                <div key={`custom-criteria-${index}`}>
                    { name }
                </div>
            )}
        </div>;
    }

    render() {
        const { getProfilePath: { meta, data }, openCriteriaModal } = this.props;

        return <div>
            <div style={{ textAlign: 'right', marginBottom: 15, paddingRight: 15 }}>
                <Button.Group>
                    <Button
                        icon={<FilePdfOutlined />}
                        disabled={meta.pending && !meta.lastSucceedAt}
                        onClick={() => generateCriteriaPdf(data || {})}>
                        Скачать
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        disabled={meta.pending && !meta.lastSucceedAt}
                        onClick={() => openCriteriaModal(data)}>
                        Редактировать
                    </Button>
                </Button.Group>
                <a href='link' ref={node => this.pdfLink = node} download style={{ display: 'none' }}>#</a>
            </div>
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                this.renderData()
            }
        </div>;
    }
}

export default asyncConnect({
    getProfilePath: getProfilePath
        .withPayload(() => 'criteria')
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_PROFILE_PATH)], () => {
                getProps().getProfilePath.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openCriteriaModal })(Criteria);
