import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { CoverLetterComponent } from '@meconsultant/common';

import { postLetter, putLetter, getLetter } from '../actions/asyncActions';

class CoverLetter extends Component {
    render() {
        const { postLetter, putLetter, input: { value }} = this.props;

        return <CoverLetterComponent
            {...this.props}
            formAction={value ? putLetter : postLetter}
            getLetter={getLetter} />;
    }
}

export default withAsyncActions({
    putLetter: putLetter
        .withOptions({ resetOnUnmount: true }),
    postLetter: postLetter
        .withOptions({ resetOnUnmount: true })
})(CoverLetter);
