import React from 'react';
import { Route, Switch } from 'react-router';

import EventFeedback from '../../EventFeedback';
import FeedbackSuccess from '../../FeedbackSuccess';

const UserFeedbackLayout = () => {
    return <Switch>
        <Route path='/feedback/success' component={FeedbackSuccess} />
        <Route path='/feedback/event/:id' render={props => <EventFeedback {...props} type='event' />} />
        <Route path='/feedback/consultation/:id' render={props => <EventFeedback {...props} type='consultation' />} />
        <Route path='/feedback/promotion/:id' render={props => <EventFeedback {...props} type='promotion' />} />
    </Switch>;
}

export default UserFeedbackLayout;
