import React, { Component } from 'react';
import { toSuccess, asyncConnect } from 'react-async-client';
import { Button, Spin, Empty } from 'antd';
import { pathOr, contains, filter, addIndex } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { generatePersonalityAnalysisPdf, PERSONALITY_ANALYSIS_DATA, PersonalityAnalisysSvg, sortPersonalityAnalysisData } from '@meconsultant/common';
import { FilePdfOutlined, EditOutlined } from '@ant-design/icons';

import { getProfilePath, getUser } from '../../actions/asyncActions';
import { openPersonalityModal } from '../../actions/modalActions';
import { PUT_PROFILE_PATH } from '../../constants/actionTypes';

class Personality extends Component {
    renderData = () => {
        const options = pathOr([], ['data', 'options'], this.props.getProfilePath);

        if (options.length) {
            const data = sortPersonalityAnalysisData(addIndex(filter)((name, index) => contains(index, options), PERSONALITY_ANALYSIS_DATA[this.props.gender || 'male']));

            return <div dangerouslySetInnerHTML={{ __html: PersonalityAnalisysSvg(data, {
                viewBox: '30 0 700 450'
            })}} /> ;
        } else {
            return <Empty description='Нет данных' image={Empty.PRESENTED_IMAGE_SIMPLE} />;
        }
    }

    render() {
        const { getProfilePath: { meta, data }, openPersonalityModal, gender } = this.props;

        return <div>
            <div style={{ textAlign: 'right', marginBottom: 15, paddingRight: 15 }}>
                <Button.Group>
                    <Button
                        icon={<FilePdfOutlined />}
                        disabled={!pathOr([], ['options'], data).length}
                        onClick={() => generatePersonalityAnalysisPdf(pathOr([], ['options'], data), gender)}>
                        Скачать
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        disabled={meta.pending && !meta.lastSucceedAt}
                        onClick={() => openPersonalityModal(data)}>
                        Редактировать
                    </Button>
                </Button.Group>
                <a href='link' ref={node => this.pdfLink = node} download style={{ display: 'none' }}>#</a>
            </div>
            { meta.pending && !meta.lastSucceedAt ?
                <Spin /> :
                this.renderData()
            }
        </div>;
    }
}

const stateToProps = state => ({
    gender: getUser.selectData(state).gender
});

export default asyncConnect({
    getProfilePath: getProfilePath
        .withPayload(() => 'personality')
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_PROFILE_PATH)], () => {
                getProps().getProfilePath.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, stateToProps, { openPersonalityModal })(Personality);
