import React, { Component } from 'react';
import qs from 'qs';
import { Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postHHToken } from '../actions/asyncActions';
import { hhChannel } from '../utils/hh';

class HHAuthorization extends Component {
    componentDidMount() {
        const data = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        if (data.error) {
            hhChannel.postMessage({
                type: 'hh_auth',
                error: true
            }, window.location.origin);

        } else {
            this.props.postHHToken.dispatch(data.code);
        }
    }

    render() {
        return <Spin />;
    }
}

export default withAsyncActions({
    postHHToken: postHHToken
        .withSuccessHandler(({ postHHToken, location }) => {
            const { state } = qs.parse(location.search, { ignoreQueryPrefix: true });
            const data = state ? JSON.parse(state) : {};

            hhChannel.postMessage({
                type: 'hh_auth',
                token: postHHToken.data.access_token,
                item: data.item,
                id: data.id
            }, window.location.origin);
        })
        .withErrorHandler(() => {
            hhChannel.postMessage({
                type: 'hh_auth',
                error: true
            }, window.location.origin);
        })
        .withOptions({ resetOnUnmount: true })
})(HHAuthorization);
