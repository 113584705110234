import { all } from 'redux-saga/effects';
import { getAsyncSagas } from 'react-async-client';
import { setLocale } from 'yup';

import validationLocale from '../constants/validationLocale';
import appRun from './appRun';
import login from './login';
import error from './error';
import profile from './profile';

setLocale(validationLocale);

export default function* () {
    yield all([
        ...getAsyncSagas(),
        login(),
        appRun(),
        error(),
        profile()
    ]);
}
