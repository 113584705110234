import React, { Component, Fragment } from 'react';
import { Modal } from 'antd';
import { pathOr } from 'ramda';
import moment from 'moment';
import styled from 'styled-components';

const ModalResume = styled(Modal)`
    .ant-modal-content {
        .ant-modal-body {
            padding: 0 0 10px;
            h3 {
                background: #fafafa;
                border-bottom: 1px solid #f0f0f0;
                font-size: 15px;
                padding: 16px 24px;
                @media (max-width: 767px) {
                    padding: 10px 16px;
                }
            }
        }
    }
`;

const Block = styled.div`
    padding: 0 24px;
    @media (max-width: 767px) {
        padding: 0 16px;
    }
`;

const Item = styled.div`
    display: flex;
    margin: 0 -24px
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    strong {
        flex: 0 0 35%;
        min-width: 35%;
        font-weight: 300;
    }
    @media (max-width: 767px) {
        margin: 0 -16px
        padding: 10px 16px;
        strong {
            flex: 0 0 40%;
            min-width: 40%;
        }
    }
`;

export default class ResumeViewModal extends Component {
    renderField = (label, valuePath, value) => {
        return <Item>
            { label && <strong>{label}: </strong> }{ value || pathOr(['-'], (valuePath || '').split('.'), this.props.params.content) }
        </Item>;
    }

    render() {
        const { modal, params: { content }} = this.props;

        return <ModalResume
            {...modal}
            title='Резюме'
            footer={null}>
            <h3>Персональные данные</h3>
            <Block>
                { this.renderField('Фамилия', 'lastName') }
                { this.renderField('Имя', 'firstName') }
                { this.renderField('Отчество', 'middleName') }
                { this.renderField('Дата рождения', null, content.birthDate ? moment(content.birthDate).format('DD.MM.YYYY') : '-') }
                { this.renderField('Семейное положение', 'maritalStatus') }
            </Block>
            <h3>Контакты</h3>
            <Block>
                { this.renderField('Телефон', 'phone') }
                { this.renderField('Email', null, content.email ? <a href={`mailto:${content.email}`}>{ content.email }</a> : '-') }
                { this.renderField('Skype', 'skype') }
                { this.renderField('Адрес', 'address') }
            </Block>
            <h3>Цель</h3>
            <Block>
                { this.renderField('Позиция', 'position') }
                { this.renderField('Профиль (краткое описание успешного профессионального опыта)', 'profile') }
                { this.renderField('Желаемая заработная плата', 'salary') }
            </Block>
            { content.type === 'functional' &&
                <Fragment>
                    <h3>Профессиональный опыт</h3>
                    <Block>
                        { !!(content.professionalExperience || []).length ? content.professionalExperience.map((_, index) =>
                            <div key={`professional-experience-${index}`} style={{ marginTop: !index ? 0 : 15 }}>
                                { this.renderField(null, `professionalExperience.${index}.responsibilities`) }
                            </div>
                        ) : '-'}
                    </Block>
                </Fragment>
            }
            <h3>Опыт работы</h3>
            <Block>
                { !!(content.experience || []).length ? content.experience.map((item, index) => {
                    const date = item.date || {};

                    return <div key={`experience-${index}`} style={{ marginTop: !index ? 0 : 15 }}>
                        { this.renderField('Дата', null, date.withPresent && date.dateFrom ?
                            `${moment(date.dateFrom).format('DD.MM.YYYY')} - по настоящее время` : date.dateFrom && date.dateTo ?
                            `${moment(date.dateFrom).format('DD.MM.YYYY')} - ${moment(date.dateTo).format('DD.MM.YYYY')}` : '')
                        }
                        { this.renderField('Название компании', `experience.${index}.company`) }
                        { this.renderField('Название позиции', `experience.${index}.position`) }
                        { this.renderField('Обязанности', `experience.${index}.responsibilities`) }
                    </div>;
                }) : '-'}
            </Block>
            <h3>Достижения</h3>
            <Block>
                { this.renderField(null, 'achievements') }
            </Block>
            <h3>Основное образование</h3>
            <Block>
                { !!(content.education || []).length ? content.education.map((item, index) => {
                    const date = item.date || {};

                    return <div key={`education-${index}`} style={{ marginTop: !index ? 0 : 15 }}>
                        { this.renderField('Дата', null, date.dateFrom && date.dateTo ?
                            `${moment(date.dateFrom).format('DD.MM.YYYY')} - ${moment(date.dateTo).format('DD.MM.YYYY')}` : '')
                        }
                        { this.renderField('Название учебного заведения', `education.${index}.name`) }
                        { this.renderField('Факультет', `education.${index}.faculty`) }
                        { this.renderField('Специальность по диплому', `education.${index}.specialty`) }
                    </div>;
                }) : '-'}
            </Block>
            <h3>Дополнительное образование</h3>
            <Block>
                { !!(content.additionalEducation || []).length ? content.additionalEducation.map((item, index) =>
                    <div key={`additional-education-${index}`} style={{ marginTop: !index ? 0 : 15 }}>
                        { this.renderField('Год', null, item.year ? moment(item.year).format('YYYY') : '')}
                        { this.renderField('Название курса', `additionalEducation.${index}.name`) }
                        { this.renderField('Название компании', `additionalEducation.${index}.company`) }
                        { this.renderField('Сертификат', `additionalEducation.${index}.sertificate`) }
                    </div>
                ) : '-'}
            </Block>
            <h3>Личностные качества и компетенции</h3>
            <Block>
                { this.renderField(null, 'qualities') }
            </Block>
            <h3>Рекомендации</h3>
            <Block>
                { this.renderField(null, 'recomendations') }
            </Block>
        </ModalResume>;
    }
}
