import React, { Component } from 'react';
import ga from 'react-ga';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { withRouter } from 'react-router-dom';

import { GA_METRICS, YANDEX_METRICS } from '../constants/metrics';

const sendPageView = page => {
    ga.set({ page });
    ga.pageview(page);
    ym('hit', page);
};

const ymOptions = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
};

class TrackPageViewComponent extends Component {
    componentDidMount() {
        ga.initialize(GA_METRICS);
        sendPageView(this.props.location.pathname);
    }

    componentDidUpdate() {
        sendPageView(this.props.location.pathname);
    }

    render() {
        return <YMInitializer
            accounts={[YANDEX_METRICS]}
            options={ymOptions}
            version="2"
        />;
    }
}

const TrackPageView = withRouter(TrackPageViewComponent);

export default props =>
    <div>
        { process.env.NODE_ENV === 'production' && <TrackPageView /> }
        { props.children }
    </div>;
