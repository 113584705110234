import { Avatar, Spin } from 'antd';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { getPromotionRequest, getPromotionRequestsThemes } from '../actions/asyncActions';
import { Status, STATUSES } from './Promotions';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { pathOr , path} from 'ramda';
import { PaperClipOutlined, UserOutlined } from '@ant-design/icons';
import { FILE_URL, getFileView } from '../constants/urls';
import { getToken } from '../utils/token';

const Wrapper = styled.div`
    background: #F7F8FC;
    border-radius: 16px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px;
    border-bottom: 1px solid #A7B9CF;
    @media (max-width: 767px) {
        padding: 12px;
    }
`;

const FileWrapper = styled.a`
    color: #F54D2E;
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;
    display: inline-block;
`;

const ItemHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .ant-avatar.ant-avatar-icon {
        margin-right: 15px;
    }
`;

const Item = styled.div`
    margin: 23px;
    @media (max-width: 767px) {
        margin: 12px;
    }
`;

const TitleStyled = styled.span`
    font-weight: bold;
    font-size: 20px;
    color: #2B3D4F;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

const Date = styled.div`
    color: #2B3D4F;
    font-size: 36px;
    font-weight: bold;
    margin-right: 20px;
    @media (max-width: 767px) {
        font-size: 16px;
        margin-right: 8px;
        font-size: 14px;
        font-weight: normal;
    }
`;

const Day = styled.div`
    margin-right: 40px;
    @media (max-width: 767px) {
        margin-right: 20px;
        flex-direction: row;
        display: flex;
    }
`;

const DateWrap = styled.div`
    display: flex;
    margin-left: 15px;
    @media (max-width: 767px) {
        flex-direction: row;
        margin-left: 0;
        order: 2;
        width: 100%;
    }
`;

const Month = styled.div`
    color: #2B3D4F;
    font-size: 16px;
    font-weight: bold;
    @media (max-width: 767px) {
        font-size: 14px;
        font-weight: normal;
    }
`;

const Time = styled.div`
    color: #A7B9CF;
    @media (max-width: 767px) {
        margin-left: 5px;
    }
`;

const HeadWrap = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        margin-left: 10px;
    }
`;

class Promotion extends Component {
    render() {
        const { getPromotionRequest: { meta, data }, getPromotionRequestsThemes } = this.props;
        const participant = pathOr({}, ['_embedded', 'participant'], data);
        const consultant = pathOr({}, ['_embedded', 'consultant'], data);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            ( meta.lastSucceedAt && <Wrapper>
                <Header>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to='/promotion'>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle r="22" transform="matrix(-1 0 0 1 22 26)" fill="#ACB4C9" fill-opacity="0.4"/>
                                <path d="M18.0989 16.5345C19.6193 15.3907 21.4626 16.175 21.5825 18.0093C21.6524 19.0789 21.7146 20.4454 21.7526 22.1782C26.3482 22.239 30.6929 22.41 32.8837 22.5369C34.2918 22.6184 34.827 23.4444 34.951 24.774C34.9814 25.0989 35 25.4633 35 25.8681C35 26.317 34.9771 26.7162 34.9407 27.0671C34.809 28.3384 34.3444 29.1457 33 29.2638C30.8238 29.4549 26.4306 29.719 21.7528 29.8119C21.7149 31.5492 21.6526 32.9187 21.5825 33.9904C21.4626 35.825 19.6188 36.6094 18.0981 35.4654C17.0725 34.6939 15.7736 33.6605 14.1772 32.2925C11.7284 30.1941 10.3296 28.6205 9.54318 27.5718C8.81886 26.6059 8.81896 25.395 9.54334 24.4291C10.3298 23.3805 11.7285 21.8069 14.1772 19.7083C15.774 18.3397 17.0731 17.3061 18.0989 16.5345Z" fill="#2B3D4F"/>
                            </svg>
                        </Link>
                        <HeadWrap>
                            <DateWrap>
                                <Date>
                                    { moment(data.createdAt).format('DD') }
                                </Date>
                                <Day>
                                    <Month>{ moment(data.createdAt).format('MMMM') }, { moment(data.createdAt).format('dd') }</Month>
                                    <Time>{ moment(data.createdAt).format('HH:mm') }</Time>
                                </Day>
                            </DateWrap>
                            <div>
                                <TitleStyled>{ getPromotionRequestsThemes.data[data.theme] }</TitleStyled>
                            </div>
                        </HeadWrap>
                    </div>
                    <Status color={STATUSES[data.status].color} background={STATUSES[data.status].background}>
                        { STATUSES[data.status].text }
                    </Status>
                </Header>
                <Item>
                    <ItemHeader>
                        <Avatar size={44} icon={<UserOutlined />} src={participant.photo ? getFileView(participant.photo) : null} />
                        <div>
                            <div><strong style={{ color: '#2B3D4F' }}>{ participant.lastName } { participant.firstName }</strong></div>
                            <div style={{ color: '#A7B9CF' }}>{ moment(data.createdAt).format('DD.MM.YYYY') } в { moment(data.createdAt).format('HH:mm') }</div>
                        </div>
                    </ItemHeader>
                    <div>{ data.text }</div>
                    { pathOr([], ['_embedded', 'files'], data).map(file =>
                        <div style={{ marginTop: 10, wordBreak: 'break-all' }} key={file.id}>
                            <FileWrapper
                                download
                                href={`${FILE_URL}/${file.id}/download?token=${getToken()}`}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <PaperClipOutlined /> { file.name }
                            </FileWrapper>
                        </div>
                    )}
                </Item>
                { data.comment &&
                    <Item style={{ borderTop: '1px dashed #A7B9CF', paddingTop: 23 }}>
                        <ItemHeader>
                            { consultant.photo ?
                                <Avatar size={44} icon={<UserOutlined />} src={getFileView(consultant.photo)} /> :
                                <svg style={{ marginRight: 15 }} width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.500644" y="0.499136" width="43.0001" height="43.0001" rx="21.5001" transform="matrix(0.999999 -0.00172662 0.00128992 0.999999 -0.000643102 0.585902)" fill="white" stroke="#A7B9CF"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.0028 10.4781C27.2636 10.4755 31.6931 13.9789 33.0084 18.7444C34.2387 18.8453 35.2053 19.8594 35.2056 21.0959L35.2062 23.4569C35.2065 24.6669 34.2814 25.6649 33.0888 25.8028C32.7889 30.5882 28.4494 34.6749 23.5091 34.6774C23.0121 34.6777 22.609 34.2815 22.6089 33.7925C22.6088 33.3035 23.0116 32.9069 23.5087 32.9067C27.4488 32.9047 30.9785 29.6188 31.2832 25.8198L31.0068 25.82L29.8068 25.8206L29.8059 22.2791C29.8048 18.0413 26.3117 14.6077 22.0038 14.6098C17.696 14.612 14.2047 18.0492 14.2058 22.2869L14.2068 25.8284L13.0068 25.829L11.4054 25.8298L11.2068 25.8299C9.88129 25.8306 8.80649 24.7741 8.80615 23.4702L8.80553 21.1092C8.8052 19.8727 9.77122 18.8577 11.0015 18.7555C12.3143 13.9886 16.7419 10.4808 22.0028 10.4781Z" fill="#8C4484"/>
                                    <path d="M30.8044 22.0261C30.8059 27.1899 26.621 31.3781 21.4571 31.3806C16.2932 31.3831 12.1058 27.199 12.1043 22.0351C12.1028 16.8712 16.2877 12.683 21.4516 12.6806C26.6155 12.6781 30.8028 16.8622 30.8044 22.0261Z" fill="#F3E7FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.5628 24.1094C20.0691 24.1092 20.4796 24.4903 20.4798 24.9606C20.48 25.7445 21.1642 26.3796 22.008 26.3792C22.8517 26.3788 23.5356 25.743 23.5353 24.9591C23.5352 24.4888 23.9455 24.1073 24.4517 24.1071C24.958 24.1068 25.3685 24.4879 25.3687 24.9582C25.3692 26.6828 23.8648 28.0816 22.0085 28.0825C20.1522 28.0833 18.6469 26.686 18.6464 24.9615C18.6463 24.4911 19.0566 24.1097 19.5628 24.1094Z" fill="#8C4484"/>
                                    <path d="M18.9506 20.4194C18.9508 21.0465 18.4038 21.5551 17.7288 21.5555C17.0537 21.5558 16.5064 21.0477 16.5062 20.4206C16.506 19.7935 17.0531 19.2848 17.7281 19.2845C18.4031 19.2842 18.9505 19.7923 18.9506 20.4194Z" fill="#8C4484"/>
                                    <path d="M27.5062 20.4153C27.5064 21.0424 26.9593 21.551 26.2843 21.5513C25.6093 21.5517 25.062 21.0436 25.0618 20.4164C25.0616 19.7893 25.6086 19.2807 26.2837 19.2804C26.9587 19.28 27.506 19.7882 27.5062 20.4153Z" fill="#8C4484"/>
                                </svg>

                            }
                            <div>
                                <div><strong style={{ color: '#2B3D4F' }}>{ consultant.lastName } { consultant.firstName }</strong></div>
                                <div style={{ color: '#A7B9CF' }}>{ moment(data.updatedAt).format('DD.MM.YYYY') } в { moment(data.updatedAt).format('HH:mm') }</div>
                            </div>
                        </ItemHeader>
                        <div>{ data.comment }</div>
                        { pathOr([], ['_embedded', 'consultantFiles'], data).map(file =>
                            <div style={{ marginTop: 10, wordBreak: 'break-all' }} key={file.id}>
                                <FileWrapper
                                    download
                                    href={`${FILE_URL}/${file.id}/download?token=${getToken()}`}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <PaperClipOutlined /> { file.name }
                                </FileWrapper>
                            </div>
                        )}
                    </Item>
                }
            </Wrapper>);
    }
}

export default withAsyncActions({
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getPromotionRequest: getPromotionRequest
        .withPayload(({ match }) => match.params.id)
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true })
})(Promotion);
