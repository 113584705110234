import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { CoverLetterForm } from '@meconsultant/common';
import { withStateHandlers } from 'recompose';

import { putLetter, postLetter } from '../../actions/asyncActions';

class CoverLetterModal extends Component {
    hidePreview = () => this.props.setShowPreview(false);

    render() {
        const { modal, putLetter, postLetter, values, showPreview } = this.props;

        return <Modal
            {...modal}
            title='Редактировать сопроводительное письмо'
            footer={null}>
            <CoverLetterForm
                formAction={values ? putLetter : postLetter}
                data={values}
                showPreview={showPreview}
                hidePreview={this.hidePreview} />
        </Modal>;
    }
}

export default withStateHandlers(props => ({ showPreview: false, values: props.params }), {
    setShowPreview: () => showPreview => ({ showPreview }),
    setValues: () => values => ({ values })
})(
    withAsyncActions({
        putLetter: putLetter
            .withSuccessHandler(({ setShowPreview, putLetter, setValues }) => {
                message.success('Сопроводительное письмо успешно изменено');
                setValues(putLetter.data);
                setShowPreview(true);
            })
            .withOptions({ resetOnUnmount: true }),
        postLetter: postLetter
            .withSuccessHandler(({ setShowPreview, postLetter, setValues }) => {
                message.success('Сопроводительное письмо успешно добавлено');
                setValues(postLetter.data);
                setShowPreview(true);
            })
            .withOptions({ resetOnUnmount: true })
    })(CoverLetterModal)
);
