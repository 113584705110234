import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, pick } from 'ramda';

import { closeModal, hideModal, showModal } from '../actions/modalActions';
import { mapIndexed } from '../utils/ramdaAdditions';

import {
    OPEN_VIDEO_MODAL,
    OPEN_PASSWORD_MODAL,
    OPEN_COVER_LETTER_MODAL,
    OPEN_RESUME_MODAL,
    OPEN_RESUME_VIEW_MODAL,
    OPEN_SEND_RESUME_MODAL,
    OPEN_HH_RESUMES_MODAL,
    OPEN_MOTIVATION_MODAL,
    OPEN_PERSONALITY_MODAL,
    OPEN_CRITERIA_MODAL,
    OPEN_PRESENTATION_MODAL,
    OPEN_PRESENTATION_VIEW_MODAL,
    OPEN_COVER_LETTER_VIEW_MODAL,
    OPEN_EVENT_MODAL,
    OPEN_CONSULTATION_MODAL,
    OPEN_CONSULTATION_VIEW_MODAL,
    OPEN_CANCEL_CONSULTATION_MODAL,
    OPEN_PROMOTION_REQUEST_MODAL,
    OPEN_TEST_REQUEST_MODAL,
    OPEN_DOCUMENT_MODAL,
} from '../constants/actionTypes';

import VideoModal from './modals/VideoModal';
import PasswordModal from './modals/PasswordModal';
import CoverLetterModal from './modals/CoverLetterModal';
import ResumeModal from './modals/ResumeModal';
import ResumeViewModal from './modals/ResumeViewModal';
import SendResumeModal from './modals/SendResumeModal';
import HHResumesModal from './modals/HHResumesModal';
import MotivationModal from './modals/MotivationModal';
import PersonalityModal from './modals/PersonalityModal';
import CriteriaModal from './modals/CriteriaModal';
import PresentationModal from './modals/PresentationModal';
import PresentationViewModal from './modals/PresentationViewModal';
import CoverLetterViewModal from './modals/CoverLetterViewModal';
import EventModal from './modals/EventModal';
import ConsultationModal from './modals/ConsultationModal';
import ConsultationViewModal from './modals/ConsultationViewModal';
import CancelConsultationModal from './modals/CancelConsultationModal';
import PromotionRequestModal from './modals/PromotionRequestModal';
import TestRequestModal from './modals/TestRequestModal';
import DocumentModal from './modals/DocumentModal';

const modalComponents = {
    [OPEN_VIDEO_MODAL]: VideoModal,
    [OPEN_PASSWORD_MODAL]: PasswordModal,
    [OPEN_COVER_LETTER_MODAL]: CoverLetterModal,
    [OPEN_RESUME_MODAL]: ResumeModal,
    [OPEN_RESUME_VIEW_MODAL]: ResumeViewModal,
    [OPEN_SEND_RESUME_MODAL]: SendResumeModal,
    [OPEN_HH_RESUMES_MODAL]: HHResumesModal,
    [OPEN_MOTIVATION_MODAL]: MotivationModal,
    [OPEN_PERSONALITY_MODAL]: PersonalityModal,
    [OPEN_CRITERIA_MODAL]: CriteriaModal,
    [OPEN_PRESENTATION_MODAL]: PresentationModal,
    [OPEN_PRESENTATION_VIEW_MODAL]: PresentationViewModal,
    [OPEN_COVER_LETTER_VIEW_MODAL]: CoverLetterViewModal,
    [OPEN_EVENT_MODAL]: EventModal,
    [OPEN_CONSULTATION_MODAL]: ConsultationModal,
    [OPEN_CONSULTATION_VIEW_MODAL]: ConsultationViewModal,
    [OPEN_CANCEL_CONSULTATION_MODAL]: CancelConsultationModal,
    [OPEN_PROMOTION_REQUEST_MODAL]: PromotionRequestModal,
    [OPEN_TEST_REQUEST_MODAL]: TestRequestModal,
    [OPEN_DOCUMENT_MODAL]: DocumentModal,
};

class Modals extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
        show: PropTypes.func.isRequired,
        modals: PropTypes.array.isRequired
    };

    getModalComponent = ({ type, params, visible }, index, length) => {
        const actions = map(action => action.bind(null, index), pick(['close', 'hide', 'show'], this.props));
        const ModalComponent = modalComponents[type];

        const modalProps = {
            visible: index + 1 === length && visible,
            width: 768,
            onCancel: actions.close
        };

        return <ModalComponent {...actions} key={index} index={index} modal={modalProps} params={params} />;
    }

    render() {
        const { modals } = this.props;

        return (
            <div>
                {mapIndexed((modal, index) => this.getModalComponent(modal, index, modals.length), modals)}
            </div>
        );
    }
}

const stateToProps = pick(['modals']);

export default connect(stateToProps, { close: closeModal, hide: hideModal, show: showModal })(Modals);
