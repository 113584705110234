import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { isEmpty, includes } from 'ramda';
import { withAsyncActions } from 'react-async-client';
import { Spin } from 'antd';

import UserLayout from './user/UserLayout';
import UserWelcomeLayout from './user/UserWelcomeLayout';
import GuestLayout from './guest/GuestLayout';
import { getUser } from '../../actions/asyncActions';
import Modals from '../Modals';
import UserFeedbackLayout from './user/UserFeedbackLayout';

const checkUser = (user, meta) => {
    if (meta.pending && !meta.lastSucceedAt) {
        return null;
    }

    return !isEmpty(user);
}

const SecureLayout = ({ getUser: { data, meta }, ...props }) => {
    const isAuthenticated = checkUser(data, meta);

    if (isAuthenticated === null) {
        return <Spin />;
    }

    const isFeedback = includes('/feedback', props.location.pathname)

    return isAuthenticated ?
        <Fragment>
            { data.welcomeTextViewed ? (isFeedback ? <UserFeedbackLayout {...props} /> : <UserLayout {...props} />) : <UserWelcomeLayout /> }
            <Modals />
        </Fragment> :
        <GuestLayout {...props} />;
}

export default withRouter(
    withAsyncActions({ getUser })(SecureLayout)
);
