import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class RadioButtons extends Component {
    static propTypes = {
        input: PropTypes.object,
        onChange: PropTypes.func,
        options: PropTypes.array,
        size: PropTypes.string
    };

    static defaultProps = {
        options: []
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { input: { value }, options, size, simple } = this.props;

        return <Radio.Group
            value={value}
            onChange={this.onChange}
            size={size}>
            { simple ?
                <Space>
                    { options.map(option =>
                        <Radio
                            key={option.id}
                            value={option.id}>
                            { option.name }
                        </Radio>
                    )}
                </Space> :
                options.map(option =>
                    <Radio.Button
                        key={option.id}
                        value={option.id}>
                        { option.name }
                    </Radio.Button>
                )
            }
        </Radio.Group>
    }
}

export default withFieldWrapper(RadioButtons);
