import { Textarea, withFormWrapper } from '@meconsultant/common';
import { Button } from 'antd';
import { toPairs } from 'ramda';
import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import * as yup from 'yup';

import { getPromotionRequestsThemes } from '../../actions/asyncActions';
import File from './formComponents/File';
import Select from './formComponents/Select';

const Wrapper = styled.div`
    .ant-btn-primary {
        margin-right: 20px;
    }
    .ant-select {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .ant-select-selector {
            border: none !important;
            color: #2B3D4F;
        }
    }
    .ant-form-item-label {
        font-weight: bold;
        color: #2B3D4F;
    }
    textarea.ant-input {
        border: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .ant-form-item-label {
        padding: 0;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
    }
`;

class PromotionRequestForm extends Component {
    render() {
        const themes = toPairs(this.props.getPromotionRequestsThemes.data).map(([id, name]) => ({ id, name }));

        return <Wrapper>
            <Field
                name='theme'
                component={Select}
                label='Тема'
                placeholder='Выберите тему'
                options={themes} />
            <Field
                name='text'
                component={Textarea}
                label='Текст'
                placeholder='Введите текст запроса' />
            <Field
                name='files'
                component={File}
                label='Файл'
                accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
                multi />
            <div>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={this.props.isSubmitting}>
                    отправить
                </Button>
                <Button className='cancel-btn' onClick={this.props.close}>
                    отменить
                </Button>
            </div>
        </Wrapper>;
    }
}

const validationSchema = yup.object().shape({
    theme: yup.string().required(),
    text: yup.string().required()
});

export default withAsyncActions({
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withOptions({ dispatchOnMount: true, resetOnMount: true })
})(withFormWrapper(PromotionRequestForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
}));
