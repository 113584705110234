import React, { Component, Fragment } from 'react';
import { Button } from 'antd';
import { withFormWrapper, Textarea } from '@meconsultant/common';
import { Field } from 'react-final-form';
import { trim, toPairs, forEach } from 'ramda';
import * as yup from 'yup';

import Input from './formComponents/Input';
import Checkbox from './formComponents/Checkbox';

class SendResumeForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='person_full_name'
                component={Input}
                label='Ваше имя' />
            <Field
                name='person_email'
                component={Input}
                label='Email' />
            <Field
                name='person_phone'
                component={Input}
                label='Телефон' />
            <Field
                name='message'
                component={Textarea}
                label='Сообщение' />
            <Field
                name='user_agreement'
                component={Checkbox}
                text={<span>
                    Соглашаюсь с <a href='https://ancor.ru/articles/politika_v_oblasti_pers_dannyh' rel='noopener noreferrer' target='_blank'>Политикой обработки персональных данных</a> и принимаю условия <a href='https://ancor.ru/media/content/files/Dogovor_oferty_ANCOR.doc' rel='noopener noreferrer' target='_blank'>Договора-оферты</a>
                </span>} />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Отправить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    person_full_name: yup.string().required(),
    person_email: yup.string().email().required(),
    person_phone: yup.string().required(),
    user_agreement: yup.string().nullable().required()
});

export default withFormWrapper(SendResumeForm, {
    validationSchema,
    mapPropsToValues: ({ data, file }) => ({
        service_name: 'Резюме, поиск работы',
        person_full_name: trim(`${data.lastName || ''} ${data.firstName || ''} ${data.middleName || ''}`),
        person_email: data.email,
        person_phone: data.phone,
        'attachment[]': file
    }),
    mapBeforeSubmit: values => {
        const vals = {
            ...values,
            user_agreement: 1
        };
        const fd = new FormData();

        forEach(([ name, value ]) => fd.append(name, value), toPairs(vals));

        return fd;
    }
});
