import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { ResumeForm, getResumeWithHHData } from '@meconsultant/common';

import { putResume, postResume } from '../../actions/asyncActions';
import HHResume from '../HHResume';

class ResumeModal extends Component {
    render() {
        const { modal, putResume, postResume, params } = this.props;
        const { item, hhResume } = params || {};

        return <Modal
            {...modal}
            title={item ? 'Редактировать резюме' : 'Добавить резюме'}
            footer={null}>
            { hhResume ?
                <HHResume id={hhResume}>
                    { hhData =>
                        <ResumeForm
                            formAction={item ? putResume : postResume}
                            data={getResumeWithHHData(item || {}, hhData)} />
                    }
                </HHResume> :
                <ResumeForm
                    formAction={item ? putResume : postResume}
                    data={item} />
            }
        </Modal>;
    }
}

export default withAsyncActions({
    postResume: postResume
        .withSuccessHandler(({ close }) => {
            message.success('Резюме успешно добавлено');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putResume: putResume
        .withSuccessHandler(({ close }) => {
            message.success('Резюме успешно сохранено');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(ResumeModal);
