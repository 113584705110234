import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { withFieldWrapper } from '@meconsultant/common';
import { message, Upload } from 'antd';
import { append, remove } from 'ramda';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FILE_URL } from '../../../constants/urls';
import { getJWTToken } from '../../../utils/token';

const Wrapper = styled.span`
    color: #F54D2E;
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;
`;

const PreviewFile = styled.span`
    color: #2B3D4F;
`;

const DeleteOutlinedStyled = styled(DeleteOutlined)`
    color: #F54D2E;
    font-weight: bold;
    margin-left: 4px;
`;

class File extends Component {
    state = {
        file: null,
        files: []
    };

    onChange = info => {
        const { status, response } = info.file;

        switch(status) {
            case 'done':
                this.props.onChange(this.props.multi ? append(response.id, this.props.input.value || []) : response.id);
                this.props.multi ? this.setState(prev => ({ files: append(info.file, prev.files || []) })) : this.setState({ file: info.file })
                break;
            case 'error':
                message.error('Не удалось загрузить файл');
                break;
            default:
                return;
        }
    }

    deleteFile = () => {
        this.setState({ file: null });
        this.props.onChange(null);
    }

    deletemultiFile = index => {
        this.setState(prev => ({ files: remove(index, 1, prev.files) }));
        this.props.onChange(remove(index, 1, this.props.input.value));
    }

    render() {
        return this.props.multi ? (
            <Fragment>
                { this.state.files.map((file, index) =>
                    <div key={`file-${index}`}><PreviewFile><PaperClipOutlined /> { file.name }</PreviewFile> <DeleteOutlinedStyled onClick={() => this.deletemultiFile(index)} /></div>
                )}
                <Upload
                    onChange={this.onChange}
                    showUploadList={false}
                    action={FILE_URL}
                    accept={this.props.accept}
                    headers={{ Authorization: getJWTToken() }}>
                    <Wrapper><PaperClipOutlined /> загрузить файл</Wrapper>
                </Upload>
            </Fragment>
        ) : (
            this.state.file ?
                <span><Wrapper><PaperClipOutlined /> { this.state.file.name }</Wrapper> <DeleteOutlined style={{ cursor: 'pointer' }} onClick={this.deleteFile} /></span> :
                <Upload
                    onChange={this.onChange}
                    showUploadList={false}
                    action={FILE_URL}
                    accept={this.props.accept}
                    headers={{ Authorization: getJWTToken() }}>
                        <Wrapper><PaperClipOutlined /> загрузить файл</Wrapper>
                </Upload>
        );
    }
}

export default withFieldWrapper(File);
