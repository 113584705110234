import React from 'react';
import { Switch } from 'react-router';
import { any, propEq } from 'ramda';
import { connect } from 'react-redux';

import Stages from './Stages';
import Profile from './profile/Profile';
import Stage from './Stage';
import Material from './Material';
import HHAuthorization from './HHAuthorization';
import Favorites from './Favorites';
import Interactive from './Interactive';
import SearchStages from './SearchStages';
import Route from './Route';
import Main from './Main';
import Webinars from './Webinars';
import { getUser } from '../actions/asyncActions';
import { coursesAllowed } from '../utils/access';
import Promotions from './Promotions';
import Tests from './Tests';
import Promotion from './Promotion';
import Chat from './Chat';

const UserRoutes = ({ user }) => {
    const showCourses = any(propEq('id', 'content'), user.modules || []) && coursesAllowed(user.status);
    const showPromotion = any(propEq('id', 'promotion'), user.modules || []);
    const showChat = any(propEq('id', 'chat'), user.modules || []);
    const showTesting = any(propEq('id', 'testing'), user.modules || []);

    return (
        <Switch>
            <Route path='/' exact component={Main} />
            { showCourses && <Route path='/stages' component={Stages} />}
            <Route path='/search' component={SearchStages} />
            <Route path='/profile/:type?' component={Profile} />
            { showCourses && <Route path='/stage/:stageId/material/:materialId' component={Material} />}
            { showCourses && <Route path='/stage/:id' exact component={Stage} />}
            <Route path='/interactive/:type?' component={Interactive} />
            <Route path='/favorites' component={Favorites} />
            <Route path='/hh' component={HHAuthorization} />
            <Route path='/webinars' component={Webinars} />
            { showPromotion && <Route path='/promotion/:id' component={Promotion} />}
            { showPromotion && <Route path='/promotion' component={Promotions} />}
            { showTesting && <Route path='/tests' component={Tests} />}
            { showChat && <Route path='/chat' component={Chat} />}
        </Switch>
    );
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(UserRoutes);
