import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import { Spin, Card, Button } from 'antd';
import styled from 'styled-components';

import { getSettings, patchProfile } from '../actions/asyncActions';
import Logo from './Logo';

const WizardBox = styled.section`
    background: #f4f6f9;
    padding: 50px 0;
    height: 100vh;
    @media (max-width: 767px) {
        padding: 0;
    }
    @media (max-width: 480px) {
        text-align: center;
        .ant-col-xs-24.ant-form-item-label{
            text-align: center;
        }
    }
`;

const CardBox = styled(Card)`
    max-width: 1020px;
    margin: 0 auto!important;
    .ant-card-body {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 100px);
    }
    small{
        font-weight: 100;
        font-size: 13px;
    }
    .ant-form{
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            flex: auto;
        }
    }
    @media (max-width: 767px) {
        padding: 16px;
        min-height: 100%;
    }
`;

const Header = styled.div`
    text-align: right;
`;

const Content = styled.div`
    flex: 1;
    padding: 15px 0;
    justify-content: center;
    display: flex;
    iframe, img {
        max-width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    text-align: center;
`;

const WelcomeLogo = styled(Logo)`
    @media (max-width: 767px) {
        background-size: 140px auto;
        background-position: center 8px;
        height: 60px;
        top: 0;
        margin: 0;
    }
`;

class Welcome extends Component {
    continue = () => {
        localStorage.setItem('showTour', true);
        localStorage.setItem('showInteractiveMessage', true);
        localStorage.setItem('showSaveResultMessage', true);
        this.props.patchProfile.dispatch([{
            op: 'replace',
            path: '/welcomeTextViewed',
            value: true
        }]);
    }

    render() {
        const { getSettings: { data, meta }} = this.props;
        const welcomeText = path(['settings', 'welcomeText'], data);

        return <WizardBox>
            <CardBox>
                <Header>
                    <WelcomeLogo wizard={1} />
                </Header>
                <Content>
                    { meta.pending ?
                        <Spin /> :
                        <div dangerouslySetInnerHTML={{ __html: welcomeText }} />
                    }
                </Content>
                <ButtonWrapper>
                    <Button type='primary' onClick={this.continue}>
                        Продолжить
                    </Button>
                </ButtonWrapper>
            </CardBox>
        </WizardBox>
    }
}

export default withAsyncActions({
    getSettings: getSettings
        .withPayload(() => 'main')
        .withOptions({ dispatchOnMount: true }),
    patchProfile: patchProfile
        .withOptions({ resetOnUnmount: true })
})(Welcome);
