import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { PresentationForm } from '@meconsultant/common';

import { postPresentation, putPresentation } from '../../actions/asyncActions';

class PresentationModal extends Component {
    render() {
        const { modal, params, putPresentation, postPresentation } = this.props;

        return <Modal
            {...modal}
            title='Самопрезентация'
            footer={null}>
            <PresentationForm
                formAction={params ? putPresentation : postPresentation}
                data={params} />
        </Modal>;
    }
}

export default withAsyncActions({
    postPresentation: postPresentation
        .withSuccessHandler(({ close }) => {
            message.success('Самопрезентация успешно добавлена');
            close();
        })
        .withOptions({ resetOnUnmount: true }),
    putPresentation: putPresentation
        .withSuccessHandler(({ close }) => {
            message.success('Самопрезентация успешно сохранена');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(PresentationModal);
