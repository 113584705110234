import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Button, Row, Col } from 'antd';
import { path, pathOr } from 'ramda';
import styled from 'styled-components';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { getFileIcon, getFileSize } from '@meconsultant/common';

import { FILE_URL } from '../../constants/urls';
import { getUser, deleteDocument } from '../../actions/asyncActions';
import { openDocumentModal } from '../../actions/modalActions';
import { getToken } from '../../utils/token';

const ListForm = styled.section`
    color: #363636;
    .label{
        font-weight: 300;
        font-size: 16px;
    }
    .value {
        font-size: 16px;
    }
    .row-group{
        margin-bottom: 10px;
        b {
            word-break: break-all;
        }
    }
`;

const DeleteLink = styled.div`
    cursor: pointer;
`;

class ProfileDocuments extends Component {
    renderDocumentRow = (file) => {
        return <Row key={file.id} className='row-group' gutter={16}>
            <Col xs={18} md={16}>
                <a
                    download
                    href={`${FILE_URL}/${file.id}/download?token=${getToken()}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='document-link'
                >
                    {getFileIcon(file.mimeType)} <b>{file.name}</b>
                </a>
            </Col>
            <Col xs={6} md={8} align='right'>
                {getFileSize(file.length)}
                { path(['createdBy', 'id'], file) === this.props.user.id ? (
                    <div>
                        <DeleteLink onClick={() => this.props.deleteDocument.dispatch({
                            file: file.id,
                        })}>
                            <DeleteOutlined />
                        </DeleteLink>
                    </div>
                ) : (
                    null
                )}
            </Col>
        </Row>;
    }

    render() {
        const { user } = this.props;
        const documents = pathOr([], ['_embedded', 'documents'], user);

        return <ListForm>
            { documents && documents.length ? documents.map(file => (
                this.renderDocumentRow(file)
            )) : <div>Нет документов</div>}
            <Button
                type='primary'
                className='btn-document'
                icon={<PaperClipOutlined />}
                onClick={() => this.props.openDocumentModal()}
            >
                Загрузить файл
            </Button>
        </ListForm>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    deleteDocument: deleteDocument
        .withParams(() => ({ type: 'list' }))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openDocumentModal })(ProfileDocuments);
