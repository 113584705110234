import React, { Component } from 'react';
import { Tabs, notification } from 'antd';
import { withAsyncActions } from 'react-async-client';
import styled from 'styled-components';

import Resumes from './interactive/Resumes';
import CoverLetters from './interactive/CoverLetters';
import WrapLayout from './layout/WrapLayout';
import Motivation from './interactive/Motivation';
import Personality from './interactive/Personality';
import Criteria from './interactive/Criteria';
import { getSettings } from '../actions/asyncActions';
import Presentations from './interactive/Presentations';
import Route from './Route';

const tabList = [
    { key: '', name: 'Резюме', component: Resumes },
    { key: 'letters', name: 'Сопроводительные письма', component: CoverLetters },
    { key: 'motivation', name: 'Анализ мотивации', component: Motivation },
    { key: 'personality', name: 'Анализ личностных качеств', component: Personality },
    { key: 'criteria', name: 'Критерии для поиска компании-работодателя', component: Criteria },
    { key: 'presentations', name: 'Самопрезентация', component: Presentations }
];

export const StyledTabs = styled(Tabs)`
    &.ant-tabs {
        overflow: visible;
        position: static;
        &.ant-tabs-left > .ant-tabs-content-holder {
            background: #fff;
            padding: 24px 0;
            box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
            border-radius: 12px;
        }
        &.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
            border: 0;
        }
        &.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab, &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
            background: transparent;
        }
        &.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active, &.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
            background: transparent;
        }
        @media (max-width: 767px) {
            margin-top: -16px;
            flex-direction: column;
            &.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
                padding-left: 8px;
            }
            .ant-tabs-nav {
                max-width: 100%;
                margin-bottom: 10px;
                .ant-tabs-nav-list {
                    flex-direction: row;
                    max-width: 100%;
                    overflow-x: auto;
                }
            }
        }
        display: flex;
        .ant-tabs-left-content {
            width: 100%;
            border: 0;
            padding-left: 0;
            overflow: visible;
            @media (max-width: 767px) {
                min-width: calc(100vw - 8px);
                padding: 16px 0 16px 8px;
            }
            .ant-tabs-tabpane {
                background: #fff;
                box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
                border-radius: 12px;
                padding: 24px;
                @media (max-width: 767px) {
                    padding: 24px 8px;
                }
            }
        }
    }
    &.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar {
        width: 200px;
        border: 0;
        margin-right: 24px;
        .ant-tabs-tab {
            border: 0;
            text-align: left;
            white-space: normal;
            height: auto;
            line-height: 1.5em;
            font-weight: 300;
            color: #000;
            padding: 5px 24px 5px 0;
            margin-right: 0;
            @media (max-width: 767px) {
                padding: 5px 8px;
                font-size: 14px;
                background: transparent;
            }
        }
        .ant-tabs-tab-active {
            background: transparent;
            font-weight: 500;
        }
        @media (max-width: 767px) {
            background: #efefef !important;
            min-height: calc(100vh - 50px);
            transition: all 0.2s;
            position: relative;
            z-index: 90;
            border-right: 1px solid #e4e4e4;
            top: 0;
            left: 0 ;
            padding: 16px 0;
            margin-right: 0;
        }
    }
    &.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        @media (max-width: 767px) {
            border-width: 1px;
            font-size: 11px;
            margin-right: 10px;
            padding: 0 16px;
        }
    }
    .ant-tabs-card-bar.ant-tabs-left-bar {
        @media (max-width: 767px) {
            flex: 0 0 0px;
            max-width: 0px;
            min-width: 0px;
            width: 0px;
            left: -1px;
        }
    }
`;

class Interactive extends Component {

    componentDidMount() {
        if (localStorage.getItem('showInteractiveMessage')) {
            notification.open({
                description: 'Рекомендуем выполнять упражнения из раздела «Курсы», последовательно изучая теоретический материал',
                duration: null,
                className: 'interactive-message'
            });
            localStorage.removeItem('showInteractiveMessage');
        }
    }

    onChangeTab = key => {
        const { history } = this.props;

        history.replace(`/interactive${key === 'resume' ? '' : `/${key}`}`);
    }

    render() {
        const { match: { params: { type }}, getSettings } = this.props;

        return <WrapLayout>
            <StyledTabs tabPosition='left' tabBarGutter={0} onChange={this.onChangeTab} animated={false} activeKey={type || 'resume'} type="card">
                { tabList.map(tab =>
                    <Tabs.TabPane key={tab.key || 'resume'} tab={tab.name}>
                        <Route path={`/interactive/${tab.key}`} exact render={props => <tab.component {...props} getSettings={getSettings} />} />
                    </Tabs.TabPane>
                )}
            </StyledTabs>
        </WrapLayout>;
    }
}

export default withAsyncActions({
    getSettings: getSettings
        .withPayload(() => 'editor')
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(Interactive);
