import React, { Component } from 'react';
import { Modal, message, Spin } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { generateResumePdfBlob } from '@meconsultant/common';

import { postSendResume } from '../../actions/asyncActions';
import SendResumeForm from '../forms/SendResumeForm';

class SendResumeModal extends Component {
    state = {
        loading: true,
        file: null
    };

    componentDidMount() {
        generateResumePdfBlob(this.props.params, blob => {
            const file = new File([blob], 'Резюме.pdf', { type: blob.type });
            this.setState({ file, loading: false });
        });
    }

    render() {
        const { modal, params, postSendResume } = this.props;

        return <Modal
            {...modal}
            title='Отправить резюме в кадровый холдинг АНКОР'
            footer={null}>
            { this.state.loading ?
                <Spin /> :
                <SendResumeForm
                    formAction={postSendResume}
                    data={params}
                    file={this.state.file} />
            }
        </Modal>;
    }
}

export default withAsyncActions({
    postSendResume: postSendResume
        .withSuccessHandler(({ close }) => {
            message.success('Резюме успешно отправлено');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(SendResumeModal);
