import { takeEvery, put } from 'redux-saga/effects';
import { message } from 'antd';
import { any, equals, pathOr } from 'ramda';
import { toError } from 'react-async-client';

import { REQUEST_ERROR } from '../constants/regexp';
import { logout } from '../actions/appActions';
import { POST_RECOVERY, POST_LOGIN, POST_REGISTRATION } from '../constants/actionTypes';

const DISABLE_ERRORS = [toError(POST_RECOVERY), toError(POST_LOGIN), toError(POST_REGISTRATION)];

export default function* error() {
    yield takeEvery((action) => REQUEST_ERROR.test(action.type), function* (action) {
        if (action.payload.status === 401) {
            yield put(logout());
        } else if (!any(equals(action.type), DISABLE_ERRORS)) {
            message.error(pathOr('Ошибка соединения с сервером', ['payload', 'data', 'message'], action));
        }
    });
}
