import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { withFieldWrapper } from '@meconsultant/common';

class SelectComponent extends Component {
    static propTypes = {
        input: PropTypes.object,
        required: PropTypes.bool,
        options: PropTypes.array,
        onChange: PropTypes.func,
        mode: PropTypes.string,
        size: PropTypes.string,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        options: [],
        mode: 'default'
    };

    render() {
        const { input: { value }, required, options, onChange, mode, size, placeholder } = this.props;

        return <Select
            mode={mode}
            value={value || undefined}
            allowClear={!required}
            onChange={onChange}
            size={size}
            placeholder={placeholder}
            notFoundContent='Ничего не найдено'
            getPopupContainer={trigger => trigger.parentNode}>
            { options.map(option =>
                <Select.Option key={option.id} value={option.id}>
                    { option.name }
                </Select.Option>
            )}
        </Select>;
    }
}

export default withFieldWrapper(SelectComponent);
