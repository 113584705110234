import axios from 'axios';

import * as urls from '../../constants/urls';

export const postRefreshTokenHandler = ({ payload }) => axios.post(urls.REFRESH_TOKEN.stringify(), {
    refresh_token: payload
});

export const postLoginHandler = ({ payload }) => axios.post(urls.LOGIN.stringify(), payload);
export const postRecoveryHandler = ({ payload }) => axios.post(urls.RECOVERY.stringify(), payload);
export const postResetHandler = ({ payload: { id, password }}) => axios.post(urls.RECOVERY.stringify({ id }), { password });
export const putPasswordHandler = ({ payload }) => axios.put(urls.PASSWORD.stringify(), payload);

export const getRegistrationPassword = ({ payload }) => axios.get(urls.REGISTRATION_PASSWORD.stringify({ token: payload.token }), payload);
export const postRegistrationPassword = ({ payload }) => axios.post(urls.REGISTRATION_PASSWORD.stringify({ token: payload.token }), payload);
export const getAgreementStatusHandler = ({ payload }) => axios.get(urls.AGREEMENT_STATUS.stringify({ id: payload }));

export const getUserHandler = () => axios.get(urls.USER.stringify(), {
    params: {
        relations: ['company', 'modules.themes', 'documents']
    }
});

export const patchProfileHandler = ({ payload }) => axios.patch(urls.PROFILE.stringify(), payload);

export const postDocumentHandler = ({ payload }) => {
    const formData = new FormData();

    formData.append('file', payload.document);

    return axios.post(urls.DOCUMENTS.stringify({ id: payload.id }), formData, {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    });
};
export const deleteDocumentHandler = ({ payload }) => axios.delete(urls.DOCUMENTS.stringify(payload));

export const getStagesHandler = ({ payload }) => axios.get(urls.STAGES.stringify(), {
    params: {
        ...payload,
        relations: ['topics']
    }
});
export const getStageHandler = ({ payload }) => axios.get(urls.STAGES.stringify({ id: payload }), {
    params: {
        relations: ['topics']
    }
});

export const putMaterialViewHandler = ({ payload: { id, ...data }}) => axios.put(urls.MATERIAL_VIEW.stringify({ id }), data);
export const getMaterialHandler = ({ payload }) => axios.get(urls.MATERIALS.stringify({ id: payload }));
export const putMaterialResultHandler = ({ payload }) => axios.put(urls.MATERIAL_RESULT.stringify({ id: payload.id }), payload);
export const putMaterialCheckListResultHandler = ({ payload }) => payload;

export const getFAQHandler = () => axios.get(urls.FAQ.stringify());

export const getResumesHandler = () => axios.get(urls.RESUME.stringify());
export const getResumeHandler = ({ payload }) => axios.get(urls.RESUME.stringify({ id: payload }));
export const postResumeHandler = ({ payload }) => axios.post(urls.RESUME.stringify(), payload);
export const putResumeHandler = ({ payload }) => axios.put(urls.RESUME.stringify({ id: payload.id }), payload);

export const postRegistration = ({ payload }) => axios.post(urls.REGISTRATION.stringify(), payload)
    .then(() => axios.post(urls.LOGIN.stringify(), { username: payload.email, password: payload.password }));
export const postParticipationRequest = ({ payload }) => axios.post(urls.PARTICIPATION_REQUEST.stringify({ company: payload.company }), payload);

export const getLettersHandler = () => axios.get(urls.LETTERS.stringify());
export const getLetterHandler = ({ payload }) => axios.get(urls.LETTERS.stringify({ id: payload }));
export const putLetterHandler = ({ payload }) => axios.put(urls.LETTERS.stringify({ id: payload.id }), payload);
export const postLetterHandler = ({ payload }) => axios.post(urls.LETTERS.stringify(), payload);
export const deleteLetterHandler = ({ payload }) => axios.delete(urls.LETTERS.stringify({ id: payload }));

export const getSettingsHandler = ({ payload }) => axios.get(urls.SETTINGS.stringify({ id: payload }));

export const getProfilePathHandler = ({ payload }) => axios.get(urls.PROFILE.stringify({ id: payload }), {
    params: {
        relations: ['consultant', 'statistic.events.themes.available', 'statistic.content.stages.stage', 'statistic.events.themes.attended', 'company', 'company.consultants', 'statistic.consultations.lastConsultant', 'documents']
    }
});
export const putProfilePathHandler = ({ payload }) => axios.put(urls.PROFILE.stringify({ id: payload.id }), payload);

export const postSendResumeHandler = ({ payload }) => axios.post(urls.SEND_RESUME.stringify(), payload);

export const postHHTokenHandler = ({ payload }) => axios.post(urls.HH_TOKEN.stringify({ id: payload }));

export const getPresentationsHandler = () => axios.get(urls.PRESENTATION.stringify());
export const getPresentationHandler = ({ payload }) => axios.get(urls.PRESENTATION.stringify({ id: payload }));
export const postPresentationHandler = ({ payload }) => axios.post(urls.PRESENTATION.stringify(), payload);
export const putPresentationHandler = ({ payload }) => axios.put(urls.PRESENTATION.stringify({ id: payload.id }), payload);
export const deletePresentationHandler = ({ payload }) => axios.delete(urls.PRESENTATION.stringify({ id: payload }));

export const getRegistrationCheckHandler = ({ payload }) => axios.get(urls.REGISTRATION_CHECK.stringify({ invite: payload }));

export const getTestsHandlers = ({ payload }) => axios.get(urls.TESTS.stringify(), {
    params: {
        relations: ['theme'],
        ...payload
    }
});
export const getTestHandler = ({ payload }) => axios.get(urls.TESTS.stringify({ id: payload }), {
    params: {
        relations: ['theme']
    }
});
export const putTestParticipateHandler = ({ payload }) => axios.post(urls.TEST_PARTICIPATE.stringify({ test: payload.test }));

export const getEventsHandlers = ({ payload }) => axios.get(urls.EVENTS.stringify(), {
    params: {
        relations: ['theme', 'speakers'],
        ...payload
    }
});
export const getEventHandler = ({ payload }) => axios.get(urls.EVENTS.stringify({ id: payload }), {
    params: {
        relations: ['theme', 'speakers']
    }
});
export const getSpeakersHandler = () => axios.get(urls.SPEAKERS.stringify());
export const putEventParticipateHandler = ({ payload }) => axios.post(urls.EVENT_PARTICIPATE.stringify({ event: payload.event }));
export const deleteEventParticipateHandler = ({ payload }) => axios.delete(urls.EVENT_PARTICIPATE.stringify({ event: payload }));

export const getConsultationsHandler = ({ payload }) => axios.get(urls.CONSULTATIONS.stringify(), {
    params: {
        relations: ['consultant'],
        ...payload
    }
});
export const getConsultationHandler = ({ payload }) => axios.get(urls.CONSULTATIONS.stringify({ id: payload }), {
    params: {
        relations: ['consultant']
    }
});

export const postPromotionsHandler = ({ payload }) => axios.post(urls.PROMOTIONS.stringify(), payload);

export const getPromotionRequestsHandler = ({ payload }) => axios.get(urls.PROMOTION_REQUESTS.stringify(), { params: payload });
export const getPromotionRequestsThemesHandler = () => axios.get(urls.PROMOTION_REQUESTS_THEMES.stringify());
export const postPromotionRequestsHandler = ({ payload }) => axios.post(urls.PROMOTION_REQUESTS.stringify(), payload);
export const getPromotionRequestHandler = ({ payload }) => axios.get(urls.PROMOTION_REQUESTS.stringify({ id: payload }), {
    params: {
        relations: ['participant', 'files', 'consultant', 'consultantFiles']
    }
});

export const postFeedbackConsultationHandler = ({ payload }) => axios.post(urls.FEEDBACK_CONSULTATION.stringify({ id: payload.id }), payload);
export const postFeedbackEventHandler = ({ payload }) => axios.post(urls.FEEDBACK_EVENT.stringify({ id: payload.id }), payload);
export const getEventHasFeedbackHandler = ({ payload }) => axios.get(urls.EVENT_HAS_FEEDBACK.stringify({ id: payload }));
export const getConsultationHasFeedbackHandler = ({ payload }) => axios.get(urls.CONSULTATION_HAS_FEEDBACK.stringify({ id: payload }));

export const getEmployeeListHandler = ({ payload: { id, auth_code, ...payload } }) => axios.get(urls.EMPLOYMENT_LIST.stringify({ id }), {
    params: {
        ...payload,
        q: {
            ...(payload.q || {}),
            status: 'in_work',
            additionalStatus: 'active',
        },
        'auth_code': auth_code,
        limit: 0,
    },
});
export const postEmployeeListHandler = ({ payload: { id, auth_code, ...payload } }) => axios.post(urls.EMPLOYMENT_LIST.stringify({ id }), payload, {
    params: {
        'auth_code': auth_code,
    }
});

export const getParticipationRequestRoleHandler = ({ payload: { company, role }}) => axios.get(urls.PARTICIPATION_REQUEST_ROLE.stringify({ company, role }));
export const postParticipationRequestRoleHandler = ({ payload }) => axios.post(urls.PARTICIPATION_REQUEST_ROLE.stringify({ company: payload.company, role: payload.role }), payload);
