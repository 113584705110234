import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { SelectionCriteriaComponent } from '@meconsultant/common';

import { putProfilePath, getProfilePath } from '../actions/asyncActions';

class SelectionCriteria extends Component {
    render() {
        const { putProfilePath } = this.props;

        return <SelectionCriteriaComponent
            {...this.props}
            formAction={putProfilePath}
            getItem={getProfilePath}  />
    }
}

export default withAsyncActions({
    putProfilePath: putProfilePath
        .withOptions({ resetOnUnmount: true })
})(SelectionCriteria);
