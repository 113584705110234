import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { message, Row } from 'antd';
import { Link } from 'react-router-dom';

import { postLogin } from '../actions/asyncActions';
import LoginForm from './forms/LoginForm';
import { DEFAULT_LOGIN_ERROR } from '../constants/errors';

class Login extends Component {
    static propTypes = {
        postLogin: PropTypes.object
    };

    render() {
        return <Fragment>
            <LoginForm formAction={this.props.postLogin} defaultError={DEFAULT_LOGIN_ERROR} />
            <Row style={{ display: 'flex' }} justify="center">
                <Link to='/recovery'>
                    Забыли пароль?
                </Link>
            </Row>
        </Fragment>;
    }
}

export default withAsyncActions({
    postLogin: postLogin
        .withErrorHandler(() => message.error('Не удалось выполнить вход'))
        .withOptions({ resetOnUnmount: true })
})(Login);
