import React, { Component, Fragment } from 'react';
import { Tabs, Avatar, message, Upload, Button, Modal } from 'antd';
import styled from 'styled-components';
import { UploadOutlined, UserOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

import { getUser, patchProfile } from '../../actions/asyncActions';
import { ImageHeader, ImageHeaderTitle } from '../ImageHeader';
import profile from '../../assets/img/user.jpg';
import WrapLayout from '../layout/WrapLayout';
import ProfileInfo from './ProfileInfo';
import ProfileDocuments from './ProfileDocuments';
import ProfileFavorites from './ProfileFavorites';
import { asyncConnect } from 'react-async-client';
import { FILE_URL, getFileView } from '../../constants/urls';
import { getJWTToken } from '../../utils/token';
import { openPasswordModal } from '../../actions/modalActions';

const tabList = [
    { key: 'documents', name: 'Документы', component: ProfileDocuments },
    { key: 'favorites', name: 'Избранное', component: ProfileFavorites }
];

export const StyledTabs = styled(Tabs)`
    .ant-tabs-bar {
        display: none;
        padding: 10px 0 30px;
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
    }
    .ant-tabs-nav {
        margin: 0;
        .ant-tabs-tab {
            border: 0;
        }
        &:before {
            border: 0;
        }
    }
    .ant-tabs-nav-wrap {
        border-bottom: 1px solid #e1e1e1;
        .ant-tabs-tab {
            background: transparent;
            border-bottom: 1px solid transparent;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 4px!important;
            margin-right: 20px!important;
            .ant-tabs-tab-btn {
                color: #2B3D4E;
                font-weight: bold;
                font-size: 16px;
            }
            &.ant-tabs-tab-active {
                border-color: #2B3D4E;
            }
        }
    }
    .ant-tabs-content-holder {
        box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
        border-top-right-radius: 12px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        padding: 16px 0;
        background: #fff;
        .ant-layout {
            background: rgb(247, 248, 252);
            a {
                color: rgb(43, 61, 79);
                font-weight: bold;
            }
        }
    }
    &.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        border: 1px solid #BDBDBD;
        border-radius: 30px;
        background: transparent;
        color: #BDBDBD;
        text-transform: uppercase;
        font-size: 14px;
        margin-right: 18px;
        padding: 0 24px;
        &.ant-tabs-tab-active {
            border-color: #418FDE;
            color: #418FDE;
        }
        @media (max-width: 767px) {
            border-width: 1px;
            font-size: 11px;
            margin-right: 10px;
            padding: 0 16px;
        }
    }
`;

// const ProgressWrap = styled.div`
//     display: flex;
//     margin-top: 15px;
//     i {
//         margin-right: 15px;
//         font-size: 26px;
//     }
// `;

// const ProgressBar = styled(Progress)`
//     .ant-progress-text {
//         color: #fff;
//     }
// `;

const ProfileWrap = styled.div`
    display: flex;
    align-items: center;
`;

const SubTitle = styled.div`
    font-size: 24px;
    line-height: 30px;
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const ProfileContent = styled.div`
    flex: 1 0 auto;
    padding-left: 48px;
    @media (max-width: 767px) {
        font-size: 20px;
        padding-left: 16px;
    }
`;

const AvatarActions = styled.div`
    position: absolute;
    top: -55px;
    left: calc(50% - 35px);
    padding-bottom: 5px;
    display: none;
    .ant-btn {
        margin-right: 5px;
    }
`;

const AvatarWrapper = styled.div`
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    &:hover {
        .anticon-upload,
        ${AvatarActions} {
            display: block;
        }
    }
    .anticon-upload {
        position: absolute;
        color: #000;
        font-size: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        opacity: .5;
        z-index: 1;
        display: none;
    }
`;

const ChangePasswordButton = styled(Button)`
    position: absolute;
    right: 24px;
    bottom: 24px;
    color: #fff;
    span {
        text-decoration: underline;
    }
`;

class Profile extends Component {
    state = {
        avatarFull: false
    };

    onChangeTab = key => {
        const { history } = this.props;

        history.replace(`/profile${key === tabList[0].key ? '' : `/${key}`}`);
    }

    onChangeAvatar = info => {
        const { status, response } = info.file;

        switch (status) {
            case 'done':
                this.props.patchProfile.dispatch([{
                    op: 'replace',
                    path: '/photo',
                    value: response.id
                }]);
                break;
            case 'error':
                message.error('Не удалось загрузить фотографию профиля');
                break;
            default:
                return;
        }
    }

    deleteAvatar = () => {
        this.props.patchProfile.dispatch([{
            op: 'replace',
            path: '/photo',
            value: null
        }]);
    }

    openAvatar = () => this.setState({ avatarFull: true });

    closeAvatar = () => this.setState({ avatarFull: false });

    render() {
        const { user, openPasswordModal, match: { params: { type } } } = this.props;

        return <Fragment>
            <ImageHeader image={profile}>
                <ImageHeaderTitle>
                    <ProfileWrap>
                        <AvatarWrapper>
                            <Upload
                                onChange={this.onChangeAvatar}
                                showUploadList={false}
                                accept='.png, .jpg, .jpeg'
                                action={FILE_URL}
                                headers={{ Authorization: getJWTToken() }}>
                                <Avatar size={200} icon={<UserOutlined />} src={user.photo ? getFileView(user.photo) : null} />
                                <UploadOutlined />
                            </Upload>
                            {!!user.photo &&
                                <AvatarActions>
                                    <Button icon={<EyeOutlined />} shape='circle' onClick={this.openAvatar} />
                                    <Button icon={<DeleteOutlined />} shape='circle' danger onClick={this.deleteAvatar} />
                                </AvatarActions>
                            }
                        </AvatarWrapper>
                        <Modal
                            visible={this.state.avatarFull}
                            onCancel={this.closeAvatar}
                            width={765}
                            destroyOnClose
                            footer={false}
                            title='Фотография профиля'>
                            <img width={715} src={getFileView(user.photo)} alt='avatar-full' />
                        </Modal>
                        <ProfileContent>
                            {user.lastName} {user.firstName} {user.middleName}
                            <SubTitle>{user.position}</SubTitle>
                            <ChangePasswordButton type='link' onClick={openPasswordModal}>
                                изменить пароль
                            </ChangePasswordButton>
                            {/* <ProgressWrap>
                                <TrophyOutlined />
                                <ProgressBar
                                    strokeWidth ={18}
                                    strokeColor={{
                                        from: '#108ee9',
                                        to: '#87d068',
                                    }} percent={66} status="active" />
                            </ProgressWrap> */}
                        </ProfileContent>
                    </ProfileWrap>
                </ImageHeaderTitle>
            </ImageHeader>
            <WrapLayout>
                <ProfileInfo />
                <StyledTabs onChange={this.onChangeTab} animated={false} activeKey={type || tabList[0].key} type="card">
                    {tabList.map(tab =>
                        <Tabs.TabPane key={tab.key} tab={tab.name}>
                            <tab.component {...this.props} />
                        </Tabs.TabPane>
                    )}
                </StyledTabs>
            </WrapLayout>
        </Fragment>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    patchProfile: patchProfile
        .withErrorHandler(() => message.error('Не удалось изменить фотографию профиля'))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openPasswordModal })(Profile);
