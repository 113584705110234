import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { SagaProvider } from 'react-async-client';
import { ConfigProvider } from 'antd';
import { createGlobalStyle } from 'styled-components';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { PersonalAgreement } from '@meconsultant/common';

import { history, sagaMiddleware } from '../store/middlewares';
import SecureLayout from './layout/SecureLayout';
import { pushRollbarError } from '../utils/rollbar';
import TrackPageView from './TrackPageView';
import { Route, Switch } from 'react-router';
import InviteRegistration from './InviteRegistration';
import RegistrationPassword from './RegistrationPassword';
import ParticipationRequest from './ParticipationRequest';
import Employment from './Employment';

const GlobalStyle = createGlobalStyle`
    .interactive-message {
        .ant-notification-notice-message {
            display: none;
        }
        .ant-notification-notice-close {
            right: 11px;
        }
    }
`;

export default class App extends Component {
    static propTypes = {
        store: PropTypes.object
    };

    state = {
        error: false
    };

    componentDidCatch(error) {
        pushRollbarError(error);
        this.setState({ error: true });
    }

    render() {
        const { store } = this.props;

        return this.state.error ?
            <div>Не удалось запустить приложение</div> :
            <Fragment>
                <GlobalStyle />
                <SagaProvider sagaMiddleware={sagaMiddleware}>
                    <ConfigProvider locale={ruRU}>
                        <Provider store={store}>
                            <ConnectedRouter history={history}>
                                <TrackPageView>
                                    <Switch>
                                        <Route path='/employment/:link?' component={Employment} />
                                        <Route path='/invite/:invite' component={InviteRegistration} />
                                        <Route path='/registration/:company/role/:role' component={ParticipationRequest} />
                                        <Route path='/registration/:company' component={ParticipationRequest} />
                                        <Route path='/password/:token' component={RegistrationPassword} />
                                        <Route path='/offer' component={PersonalAgreement} />
                                        <Route>
                                            <SecureLayout />
                                        </Route>
                                    </Switch>
                                </TrackPageView>
                            </ConnectedRouter>
                        </Provider>
                    </ConfigProvider>
                </SagaProvider>
            </Fragment>;
    }
}
