import React, { Component } from 'react';
import { Rate } from 'antd';
import tinygradient from 'tinygradient';
import styled, { css } from 'styled-components';

import { withFieldWrapper } from '@meconsultant/common';

const gradient = tinygradient('#ff4500', 'gold', '#75c464');

const Wrapper = styled.div`
    ${({ colors, color }) => color ? css`
        .ant-rate-star-full .anticon-star svg {
            fill: ${color};
        }
    ` : (colors || []).map((color, index) => css`
        .ant-rate-star-full:nth-child(${index + 1}) {
            .anticon-star svg {
                fill: ${color.toRgbString()};
            }
        }
    `)}
`;

class Starts extends Component {
    render() {
        const { max, input: { value }, onChange, color } = this.props;
        const colors = gradient.rgb(max);

        return <Wrapper colors={colors} color={color}>
            <Rate
                count={max}
                value={value || 0}
                onChange={onChange} />
        </Wrapper>;
    }
}

export default withFieldWrapper(Starts);
