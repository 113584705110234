import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Input, Layout, Empty, Spin } from 'antd';
import debounce from 'debounce';
import qs from 'qs';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getStages } from '../actions/asyncActions';

const WrapBox = styled(Layout)`
    background: #fff;
    box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
    border-radius: 12px;
    padding: 24px;
    flex: initial;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 16px;
    }
    h2 {
        color: #418FDE;
        transition: all .3s;
        &:hover {
            color: #6aaeeb;
        }
    }
`;

class SearchStages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            term: qs.parse(props.location.search, { ignoreQueryPrefix: true }).term
        };
        this.search = debounce(this.search, 500);
    }

    search = term => {
        this.props.history.replace(term.length > 2 ? `/search?term=${term}` : `/search`);
    }

    onChangeSearch = e => {
        const term = e.target.value;

        this.setState({ term });
        this.search(term);
    }

    render() {
        const { getStages: { data, meta }} = this.props;
        const items = data.items || [];

        return <div>
            <Input.Search
                placeholder='Поиск'
                value={this.state.term}
                onChange={this.onChangeSearch}
                style={{ marginBottom: 15 }} />
            { meta.pending ? <Spin /> :
                items.length ? items.map(item =>
                    <WrapBox key={item.id}>
                        <Link to={`/stage/${item.id}`}><h2>{ item.title }</h2></Link>
                        { item.themes.map((theme, index) =>
                            <div key={`theme-${index}`}>
                                <h3 style={{ marginTop: index ? 10 : 0 }}>{ theme.title }</h3>
                                { theme.materials.map(material =>
                                    <div key={material.id}>
                                        <Link to={`/stage/${item.id}/material/${material.id}`}>{ material.title }</Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </WrapBox>
                ) : <WrapBox><Empty description='Ничего не найдено' image={Empty.PRESENTED_IMAGE_SIMPLE} /></WrapBox>
            }
        </div>
    }
}

export default withAsyncActions({
    getStages: getStages
        .withPayload(({ location: { search }}) => ({
            q: {
                term: qs.parse(search, { ignoreQueryPrefix: true }).term
            }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(SearchStages);
