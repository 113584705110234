import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { CheckListComponent } from '@meconsultant/common';

import { putMaterialCheckListResult  } from '../actions/asyncActions';

class CheckList extends Component {
    render() {
        const { putMaterialCheckListResult } = this.props;

        return <CheckListComponent
            {...this.props}
            formAction={putMaterialCheckListResult}  />
    }
}

export default withAsyncActions({
    putMaterialCheckListResult: putMaterialCheckListResult
        .withOptions({ resetOnUnmount: true })
})(CheckList);
