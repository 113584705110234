import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions } from 'react-async-client';
import { propEq, pathOr, path, find, pathEq } from 'ramda';
import { pluralize } from 'numeralize-ru';
import {Card, List, Avatar, Button, Spin, message} from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withState } from 'recompose';
import { StarFilled, StarOutlined, FileOutlined, ClockCircleOutlined, CheckOutlined } from '@ant-design/icons';

import { STAGES } from '../constants/stages';
import { getStage, putMaterialView } from '../actions/asyncActions';
import { ImageHeader, ImageHeaderTitle, ImageHeaderDesc } from './ImageHeader';
import WrapLayout from './layout/WrapLayout';
import { getFileView } from '../constants/urls';
import { getTime, getFullTime } from '../utils/time';

const CardBox = styled(Card)`
    .ant-card-head-title {
        color: #333;
        font-size: 20px;
        line-height: 26px;
        small {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            color: #828282;
        }
    }
    .ant-avatar {
        width: 44px;
        height: 44px;
    }
    &.ant-card {
        background: transparent;
    }
    .ant-card-body,.ant-card-head {
        padding: 0;
    }
    .ant-card-head,.ant-list-split .ant-list-item {
        border: 0;
        position: relative;
    }
    .ant-list-item-meta-avatar {
        .anticon {
            display: none;
            font-size: 16px;
        }
        .ant-avatar-icon {
            line-height: 40px;
            border: 2px solid #BDBDBD;
            background: #fff;
        }
        &:before {
            content: '';
            width: 2px;
            background: #BDBDBD;
            position: absolute;
            left: 22px;
            top: 66px;
            bottom: 0;
        }
    }
    .ant-list-item-meta {
        .ant-list-item-meta-title {
            i {
                cursor: pointer;
                display: none;
                margin-left: 8px;
            }
        }
        &:hover {
            i {
                display: inline-block;
            }
        }
    }
    .ant-list-item-meta-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            & > span {
                display: inline-block;
            }
        }
        a {
            color: #2c3d4e;
        }
    }
    .favourite {
        .ant-list-item-meta {
            .ant-list-item-meta-title {
                i {
                    display: inline-block;
                }
            }
        }
    }
    .passed {
        .ant-list-item-meta-description {
            color: #333333;
            font-size: 15px;
            line-height: 26px;
        }
        .ant-list-item-meta-avatar {
            .anticon {
                display: inline-block;
            }
            .ant-avatar.ant-avatar-icon {
                background: rgb(228, 237, 232);
                color: #549E38;
                border: 2px solid #549E38;
            }
            &:before {
                background: #3AB54A;
            }
        }
        .ant-list-item-meta-title a {
            color: #2c3d4e;
        }
    }
    .ant-list-items {
        .ant-list-item {
            &:last-child {
                .ant-list-item-meta-avatar {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .ant-list-item-meta-content {
        padding-top: 25px;
        width: 100%;
    }
`;

const LineItem = styled.span`
    position: absolute;
    left: 62px;
    top: 15px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #828282;
`;

class FavoriteComponent extends Component {
    setFavorite = () => {
        const { favorite, setFavorite, putFavorite, item } = this.props;

        setFavorite(!favorite);
        putFavorite.dispatch({
            favorite: !favorite,
            id: item.id
        });
    }

    render() {
        const { favorite } = this.props;

        return favorite ?
            <StarFilled
                style={{fontSize: '20px', color: '#F5A22D', display: 'inline-block' }}
                onClick={this.setFavorite} /> :
            <StarOutlined
                style={{fontSize: '20px', color: '#F5A22D' }}
                onClick={this.setFavorite} />;
    }
}

const Favorite = withState('favorite', 'setFavorite', ({ item }) => item.favorite)(
    withAsyncActions({
        putFavorite: putMaterialView
            .withParams(({ item }) => ({ type: 'favorite', id: item.id }))
            .withSuccessHandler(({ putFavorite, setFavorite }) => {
                setFavorite(putFavorite.data.favorite);
            })
            .withErrorHandler(() => message.error('Не удалось добавить материал в избранное'))
            .withOptions({ resetOnUnmount: true }),
    })(FavoriteComponent)
);

class Stage extends Component {
    static propTypes = {
        match: PropTypes.object,
        getStage: PropTypes.object,
        location: PropTypes.object,
    };

    getImage = () => {
        const stage = this.getStage() || {};

        return stage.image ? getFileView(stage.image) : pathOr(null, ['img'], STAGES.find(propEq('id', this.props.match.params.id)));
    }

    getStage = () => {
        const { getStage: { data }, location } = this.props;

        return pathOr(data, ['state', 'stage'], location);
    }

    getFirstUncompleteMaterial = () => {
        const stage = this.getStage();
        const materials = (stage.themes || []).reduce((res, cur) => res.concat(cur.materials), []);
        const currentMaterial = find(propEq('viewed', false), materials);

        return currentMaterial ? currentMaterial.id : null;
    }

    renderTheme = (theme, index) => {
        const firstUncompleteMaterial = this.getFirstUncompleteMaterial();

        return <CardBox
            bordered={false}
            key={`theme-${index}`}
            title={<span>{theme.title}<br/><small>{`${theme.materials.length} ${pluralize(theme.materials.length, 'материал', 'материала', 'материалов')}`}</small></span>}>
            <List
                itemLayout='horizontal'
                dataSource={theme.materials}
                renderItem={(item, i) =>  {
                    const time = getFullTime(item.time);

                    return (
                        <List.Item className={item.viewed ? 'passed' : false}
                                actions={[item.id === firstUncompleteMaterial ?
                                    <Button type={'primary'}>
                                        <Link to={{
                                            pathname: `${this.props.match.url}/material/${item.id}`,
                                            state: {
                                                stage: this.getStage(),
                                                title: item.title
                                            }
                                        }}>
                                            {!i ? 'Начать' : 'Продолжить'}
                                        </Link>
                                    </Button> : null
                                ]}>
                            <List.Item.Meta
                                avatar={<Avatar icon={<CheckOutlined />} />}
                                title={
                                    <Fragment>
                                        <span>
                                            <Link to={{
                                                pathname: `${this.props.match.url}/material/${item.id}`,
                                                state: {
                                                    stage: this.getStage(),
                                                    title: item.title
                                                }
                                            }}>
                                                {item.title}
                                            </Link>&nbsp;
                                            <Favorite item={item} />
                                        </span>
                                    </Fragment>
                                }
                                description={item.lead}
                            />
                            <LineItem>материал { i + 1 } { time ? `- ${time}` : '' }</LineItem>
                        </List.Item>
                    );
                }}
            />
        </CardBox>;
    }

    continue = () => {
        const { history, match } = this.props;
        const firstUncompleteMaterial = this.getFirstUncompleteMaterial();

        history.push(`/stage/${match.params.id}/material/${firstUncompleteMaterial}`);
    }

    renderStage = () => {
        const stage = this.getStage();
        const themes = pathOr([], ['themes'], stage);
        const materialsLength = themes.reduce((res, cur) => res + cur.materials.length, 0);
        const topics = pathOr([], ['_embedded', 'topics'], stage);
        const firstUncompleteMaterial = this.getFirstUncompleteMaterial();

        return stage ?
            <Fragment>
                <ImageHeader image={this.getImage()}>
                    <ImageHeaderTitle>
                        { stage.title }
                        <ImageHeaderDesc>
                            <div>{ stage.subtitle }</div>
                            <div>{ topics.map((topic, index) => <span key={topic.id}>{ `${topic.title}${index < topics.length - 1 ? ', ' : ''}` }</span>) }</div>
                            <div className='box-desc'>
                                <span><FileOutlined />&nbsp;{ `${materialsLength} ${pluralize(materialsLength, 'материал', 'материала', 'материалов')}`}</span>
                                { !!stage.time && <span><ClockCircleOutlined />&nbsp;<span>{ getTime(stage.time) }</span></span> }
                            </div>
                            { !!firstUncompleteMaterial &&
                                <Button type='primary' size='large' onClick={this.continue}>
                                    { pathEq([0, 'materials', 0, 'id'], firstUncompleteMaterial, themes) ? 'Начать' : 'Продолжить' }
                                </Button>
                            }
                        </ImageHeaderDesc>
                    </ImageHeaderTitle>
                </ImageHeader>
                { stage.lead && <div style={{ padding: 24, paddingBottom: 0 }}>{ stage.lead }</div> }
                <WrapLayout>
                    { themes.map(this.renderTheme)}
                </WrapLayout>
            </Fragment> : null;
    }

        render() {
            const { getStage: { meta }} = this.props;

            return meta.pending && !meta.lastSucceedAt ? <Spin /> : this.renderStage();
        }
    }

export default withAsyncActions(({ location }) => ({
    getStage: getStage
        .withPayload(({ match }) => match.params.id)
        .withOptions({ dispatchOnMount: !path(['state', 'stage'], location), resetOnUnmount: true })
}))(Stage);
