import { withFormWrapper } from '@meconsultant/common';
import { Button } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

const Wrapper = styled.div`
    .ant-btn-primary {
        margin-right: 20px;
    }
    .ant-select {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .ant-select-selector {
            border: none !important;
            color: #2B3D4F;
        }
    }
    .ant-form-item-label {
        font-weight: bold;
        color: #2B3D4F;
    }
    textarea.ant-input {
        border: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
    .ant-form-item-label {
        padding: 0;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: '';
    }
`;

class TestRequestForm extends Component {
    render() {
        return <Wrapper>
            <div>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={this.props.isSubmitting}>
                    отправить
                </Button>
                <Button className='cancel-btn' onClick={this.props.close}>
                    отменить
                </Button>
            </div>
        </Wrapper>;
    }
}

const validationSchema = yup.object().shape({
    text: yup.string().nullable(),
});

export default withFormWrapper(TestRequestForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
});
