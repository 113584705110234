import styled, { css } from 'styled-components';
import imgLogo from '../assets/img/logo.svg';
import imgLogoRed from '../assets/img/logo-red.svg';

const Logo = styled.span`
    display: inline-block;
    background: url(${imgLogo}) no-repeat;
    margin: 0;
    height: 40px;
    width: 100%;
    background-size: auto 34px;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 767px) {
        height: 50px;
        width: 100%;
        background-size: 116px auto;
        background-position: center 0;
        margin: -22px 0 0;
        position: relative;
        top: -11px;
    }
    ${props => props.guest && css`
        background-image: url(${imgLogoRed});
        height: 70px;
        width: 200px;
        background-size: contain;
    `}
    ${props => props.wizard && css`
        background-image: url(${imgLogoRed});
        width: 160px;
        height: 70px;
        background-size: 160px auto;
        background-position: 0;
    `}
`;

export default Logo;
