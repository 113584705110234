import { includes } from 'ramda';

import teamsIcon from '../assets/img/teams.svg';
import zoomIcon from '../assets/img/zoom.svg';

export const getOnlineIcon = link => {
    const options = {
        mt: { icon: teamsIcon, text: 'Microsoft teams' },
        zoom: { icon: zoomIcon, text: 'Zoom' }
    };

    return includes('teams.microsoft.com', link) ? options.mt : includes('zoom.us', link) ? options.zoom : null;
}
