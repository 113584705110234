import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { withFieldWrapper } from '@meconsultant/common';

class InputComponent extends Component {
    static propTypes = {
        htmlType: PropTypes.string,
        placeholder: PropTypes.string,
        input: PropTypes.object,
        icon: PropTypes.object,
        onChange: PropTypes.func
    };

    onChange = e => this.props.onChange(e.target.value);

    render() {
        const { placeholder, htmlType, input: { value }, icon } = this.props;

        return <Input
            placeholder={placeholder}
            type={htmlType}
            value={value}
            prefix={icon}
            onChange={this.onChange} />
    }
}

export default withFieldWrapper(InputComponent);
