import * as yup from 'yup';
import * as validator from 'class-validator';

yup.addMethod(yup.mixed, 'match', function (matchField, message = 'Поля должны совпадать') {
    return this.test('match', message, function (value) {
        const matchFieldValue = this.parent[matchField];
        return value === matchFieldValue;
    });
});

yup.addMethod(yup.string, 'phone', function phone(ref, msg) {
    return this.test({
        name: 'phone',
        exclusive: false,
        message: msg || 'Введите телефон в формате: +79123456789',
        test: (value) => value ? validator.isMobilePhone(value, 'ru-RU') : true
    });
});
