import { message, Spin } from 'antd';
import React, { Component, Fragment } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { pathOr } from 'ramda';

import { getConsultationHasFeedback, getEventHasFeedback, postFeedbackConsultation, postFeedbackEvent, getEvent, getConsultation } from '../actions/asyncActions';
import EventFeedbackForm from './forms/EventFeedbackForm';

export const Header = styled.div`
    width: 100%;
    background: #2B3D4F;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ContentWrapper = styled.div`
    max-width: 968px;
    width: 100%;
    padding: 0 10px;
    form {
        .ant-form-item-required::before {
            content: '' !important;
            margin-right: 0 !important;
        }
    }
`;

export const Body = styled.div`
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    textarea {
        background: #EDEDED;
        border: none;
        border-radius: 0 !important;
    }
    small {
        color: #9C9FA3;
    }
    .ant-form-item-label > label {
        height: auto;
    }
    .ant-form-item-label {
        text-align: left;
    }
    .ant-form-item-control {
        min-height: auto;
    }
    form {
        margin-top: 25px;
    }
`;

const TITLE = {
    event: 'вебинара',
    consultation: 'консультации',
    promotion: 'продвижения'
};

class EventFeedback extends Component {
    getHasFeedbackParams = () => {
        const { getConsultationHasFeedback, getEventHasFeedback, type } = this.props;
        const actions = {
            event: getEventHasFeedback,
            consultation: getConsultationHasFeedback,
            promotion: getConsultationHasFeedback
        };

        return actions[type];
    }

    getData = () => {
        const { getEvent, getConsultation, type } = this.props;
        const actions = {
            event: () => pathOr('', ['_embedded', 'theme', 'title'], getEvent.data),
            consultation: () => {
                const consultant = pathOr(null, ['_embedded', 'consultant'], getConsultation.data);
                return consultant ? `${consultant.lastName} ${consultant.firstName} ${consultant.middleName || ''}` : null;
            },
            promotion: () => null
        };

        return actions[type]();
    }

    render() {
        const { postFeedbackConsultation, postFeedbackEvent } = this.props;
        const hasFeedbackParams = this.getHasFeedbackParams();
        const data = this.getData();

        return <div>
            <Header>
                <ContentWrapper>
                    <Link to='/'>
                        <svg width="162" height="56" viewBox="0 0 162 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.197388 0.199493H55.3814V19.669H51.7762V3.80052H3.80261V51.7784H51.7762V33.7372H55.3814V55.3795H0.197388V0.199493Z" fill="#F54D2E"/>
                            <path d="M11.3491 18.0369H14.4257L20.3623 26.1392L26.0225 18.0607H28.8945V35.3218H25.8663V22.8262L20.3862 30.5084H19.9296L14.4015 22.9102V35.3218H11.3491V18.0369Z" fill="white"/>
                            <path d="M42.4626 34.2175C41.2432 35.1566 39.7356 35.6444 38.1963 35.5979C37.4074 35.6581 36.6145 35.5505 35.8701 35.2816C35.1257 35.013 34.4473 34.5893 33.8791 34.0388C33.3111 33.4887 32.8665 32.8239 32.5749 32.0891C32.2833 31.3543 32.1515 30.5658 32.1878 29.7761C32.1549 28.9963 32.2856 28.218 32.5712 27.4913C32.8568 26.7647 33.2914 26.1057 33.847 25.5565C34.4023 25.0073 35.0665 24.5799 35.797 24.3021C36.5274 24.0242 37.3079 23.9017 38.0883 23.9425C38.78 23.9147 39.4701 24.0282 40.1162 24.276C40.7626 24.524 41.3512 24.9012 41.8463 25.3845C42.3414 25.8679 42.7325 26.4469 42.9955 27.0865C43.2584 27.7262 43.3881 28.4125 43.3759 29.1039C43.394 29.6222 43.3333 30.1404 43.1957 30.6403H35.1439C35.2688 31.4172 35.6845 32.1172 36.3072 32.5988C36.93 33.0804 37.7129 33.307 38.4968 33.2332C39.6293 33.2436 40.7347 32.8855 41.6455 32.2129L42.4626 34.2175ZM35.0479 28.7318H40.5277C40.5394 28.4011 40.4805 28.0713 40.3546 27.7652C40.2287 27.459 40.0388 27.1829 39.7978 26.9558C39.5567 26.7287 39.2698 26.5555 38.9564 26.4476C38.6429 26.34 38.31 26.3003 37.9801 26.3311C37.277 26.2826 36.5819 26.504 36.0371 26.9501C35.492 27.3964 35.1382 28.0338 35.0479 28.7318Z" fill="white"/>
                            <path d="M59.7321 34.6613C58.2202 36.0412 56.2266 36.7738 54.1801 36.702C48.8684 36.702 45.2271 33.0048 45.2271 27.3753C45.2271 22.1779 48.3636 18.0366 54.096 18.0366C56.0263 17.967 57.9127 18.6223 59.3835 19.873L58.0376 22.2738C56.8936 21.4567 55.5138 21.0353 54.1079 21.0735C50.8873 21.0735 48.4477 23.4741 48.4477 27.4354C48.4477 31.3965 50.9112 33.797 54.4565 33.797C55.9637 33.7985 57.4263 33.2861 58.6023 32.3448L59.7321 34.6613Z" fill="white"/>
                            <path d="M67.6513 23.9425C68.4378 23.8972 69.2254 24.0158 69.9636 24.2906C70.702 24.5655 71.375 24.9909 71.94 25.5393C72.505 26.0878 72.9496 26.7478 73.2459 27.4771C73.5419 28.2065 73.683 28.9893 73.6599 29.776C73.6813 30.5618 73.539 31.3433 73.2421 32.0712C72.9453 32.799 72.5003 33.4576 71.9355 34.0048C71.3707 34.552 70.6983 34.9762 69.9609 35.2505C69.2232 35.5246 68.4368 35.6429 67.6513 35.5977C66.8597 35.6533 66.0655 35.5427 65.3194 35.2734C64.5733 35.0037 63.8919 34.5813 63.3192 34.0329C62.7464 33.4847 62.2949 32.8227 61.9936 32.0893C61.6926 31.3562 61.5483 30.5683 61.5704 29.776C61.5465 28.9828 61.6896 28.1936 61.9899 27.459C62.2904 26.7244 62.7417 26.0609 63.3147 25.5113C63.8876 24.9616 64.5696 24.5379 65.3164 24.2678C66.0633 23.9974 66.8587 23.8866 67.6513 23.9425ZM67.6513 32.9571C68.0663 32.968 68.4793 32.8922 68.8634 32.7344C69.2476 32.5766 69.5946 32.3406 69.882 32.0411C70.1693 31.7417 70.391 31.3853 70.5326 30.9952C70.6739 30.6053 70.7324 30.19 70.7037 29.776C70.7274 29.3677 70.6662 28.959 70.5234 28.5758C70.3786 28.1886 70.1554 27.8352 69.8681 27.538C69.5807 27.2406 69.2351 27.0055 68.853 26.8472C68.4708 26.6944 68.0628 26.6168 67.6513 26.6191C67.2396 26.6134 66.8313 26.6926 66.4517 26.8517C66.072 27.011 65.7294 27.2465 65.4453 27.544C65.1609 27.8414 64.9413 28.1943 64.7997 28.5805C64.6581 28.9664 64.5979 29.3777 64.6228 29.788C64.5997 30.198 64.6614 30.6085 64.8034 30.9937C64.9455 31.3791 65.1654 31.7312 65.4493 32.0282C65.7332 32.3254 66.075 32.561 66.4539 32.7208C66.8326 32.8806 67.2401 32.9608 67.6513 32.9571Z" fill="white"/>
                            <path d="M76.2077 24.2066H79.0559V25.2988C79.479 24.8771 79.9814 24.5426 80.5339 24.3145C81.0832 24.0921 81.6719 23.982 82.2645 23.9904C84.9925 23.9904 86.8552 25.2988 86.8552 28.792V35.3218H83.9951V29.32C84.0506 28.9736 84.0254 28.6193 83.9217 28.284C83.8177 27.9491 83.6378 27.6424 83.396 27.3882C83.1542 27.1337 82.8569 26.9387 82.5272 26.8176C82.1976 26.6969 81.8445 26.6536 81.4955 26.6911C81.0365 26.7016 80.5837 26.7992 80.1615 26.9794C79.7438 27.1618 79.3676 27.4275 79.0559 27.7595V35.3338H76.2077V24.2066Z" fill="white"/>
                            <path d="M89.3787 27.3273C89.3787 25.1547 91.1692 23.9422 93.4765 23.9422C95.0235 23.8878 96.5356 24.4096 97.7186 25.4066L96.6491 27.4354C95.7738 26.7764 94.7162 26.4031 93.6208 26.3671C92.7555 26.3671 92.2388 26.8353 92.2388 27.3392C92.2388 29.1878 98.2476 28.1675 98.2476 32.1408C98.2476 34.3373 96.4689 35.7418 93.741 35.7418C92.8749 35.7227 92.0213 35.5325 91.2294 35.1824C90.4373 34.832 89.7225 34.3286 89.1262 33.7011L90.5204 31.8406C91.4737 32.6666 92.6734 33.1561 93.9333 33.2329C94.7625 33.2329 95.3994 32.885 95.3994 32.1887C95.4113 30.3881 89.3787 31.4084 89.3787 27.3273Z" fill="white"/>
                            <path d="M110.866 35.3216H108.018V33.9653C107.584 34.4819 107.037 34.8924 106.42 35.1655C105.8 35.4555 105.121 35.5952 104.437 35.5736C102.262 35.5736 100.519 34.1693 100.519 30.9763V24.2064H103.379V30.4122C103.379 31.9005 103.944 32.9569 105.506 32.9569C105.995 32.9442 106.475 32.8254 106.912 32.6087C107.349 32.3716 107.727 32.0394 108.018 31.6366V24.2064H110.866V35.3216Z" fill="white"/>
                            <path d="M114.039 18.0369H116.899V31.1085C116.899 32.309 117.187 32.9091 117.86 32.9091C118.298 32.8952 118.72 32.7394 119.062 32.4651L119.663 34.6375C118.813 35.2784 117.77 35.6089 116.706 35.5738C115 35.5738 114.075 34.5415 114.075 32.2608L114.039 18.0369Z" fill="white"/>
                            <path d="M121.213 21.1215H124.073V24.2064H127.678V26.8711H124.073V30.9283C124.073 32.1286 124.482 32.9327 125.407 32.9327C126.034 32.9201 126.636 32.6909 127.113 32.2847L127.991 34.4814C127.035 35.1993 125.869 35.5833 124.674 35.5736C122.138 35.5736 121.201 33.9412 121.201 31.1082L121.213 21.1215Z" fill="white"/>
                            <path d="M130.538 24.9989C131.745 24.3289 133.099 23.9661 134.48 23.9425C137.797 23.9425 138.95 25.6231 138.95 28.5638V35.3218H136.463V34.0495C136.063 34.5296 135.555 34.9091 134.981 35.1573C134.407 35.4053 133.782 35.5152 133.158 35.4779C132.696 35.5164 132.231 35.4553 131.794 35.299C131.357 35.1427 130.959 34.8944 130.626 34.5714C130.294 34.2483 130.034 33.8579 129.866 33.426C129.697 32.9944 129.623 32.5317 129.649 32.069C129.649 29.6682 131.463 28.4679 133.723 28.4679C134.619 28.462 135.499 28.712 136.258 29.1881C136.331 27.3395 135.838 26.3191 134.191 26.3191C133.167 26.3422 132.163 26.6054 131.259 27.0875L130.538 24.9989ZM136.27 32.069V30.9286C135.693 30.5429 135.006 30.3535 134.312 30.3883C133.374 30.3883 132.521 30.8327 132.521 31.7688C132.521 32.7051 133.218 33.0771 134.119 33.0771C134.534 33.1027 134.948 33.0239 135.324 32.8478C135.7 32.6716 136.025 32.4037 136.27 32.069Z" fill="white"/>
                            <path d="M141.883 24.2066H144.731V25.299C145.152 24.8798 145.649 24.5458 146.197 24.3147C146.747 24.0943 147.335 23.9842 147.927 23.9907C150.667 23.9907 152.518 25.299 152.518 28.792V35.3218H149.67V29.3203C149.723 28.9731 149.696 28.6185 149.591 28.2835C149.486 27.9486 149.304 27.6424 149.062 27.3884C148.819 27.1342 148.521 26.9394 148.191 26.8186C147.861 26.6979 147.508 26.6541 147.158 26.6914C146.703 26.7033 146.255 26.801 145.836 26.9794C145.417 27.1591 145.04 27.425 144.731 27.7597V35.334H141.883V24.2066Z" fill="white"/>
                            <path d="M155.222 21.1215H158.082V24.2064H161.688V26.8711H158.082V30.9283C158.082 32.1286 158.491 32.9327 159.416 32.9327C160.047 32.9208 160.654 32.6917 161.135 32.2846L162 34.4814C161.052 35.2135 159.881 35.5992 158.683 35.5736C156.148 35.5736 155.21 33.9412 155.21 31.1082L155.222 21.1215Z" fill="white"/>
                        </svg>
                    </Link>
                </ContentWrapper>
            </Header>
            <Body>
                <ContentWrapper>
                    { hasFeedbackParams.meta.pending ? <Spin /> :
                        hasFeedbackParams.meta.error ? <h1 style={{ textAlign: 'center' }}>Ошибка получения данных!</h1> :
                        hasFeedbackParams.data ? <h1 style={{ textAlign: 'center' }}>Вы уже оставляли отзыв</h1> :
                        <Fragment>
                            <div>
                                <div style={{ marginLeft: 43 }}>Оценка { TITLE[this.props.type] }</div>
                                { !!data &&
                                    <h1><svg style={{ transform: 'scale(0.7) translate(7px, 2px)' }} width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.2137 0H34.4828L20.2691 20H0L14.2137 0Z" fill="#F54D2E"/>
                                    </svg> { data }</h1>
                                }
                            </div>
                            <EventFeedbackForm
                                formAction={this.props.type === 'event' ? postFeedbackEvent : postFeedbackConsultation}
                                item={{
                                    id: this.props.match.params.id,
                                    moduleType: this.props.type === 'event' ? null : this.props.type === 'consultation' ? 'consultations' : 'promotion'
                                }} />
                        </Fragment>
                    }
                </ContentWrapper>
            </Body>
        </div>;
    }
}

export default withAsyncActions(props => ({
    postFeedbackEvent: postFeedbackEvent
        .withSuccessHandler(({ history }) => {
            history.push('/feedback/success');
        })
        .withErrorHandler(() => message.error('Не удалось отправить отзыв'))
        .withOptions({ resetOnUnmount: true }),
    postFeedbackConsultation: postFeedbackConsultation
        .withSuccessHandler(({ history }) => {
            history.push('/feedback/success');
        })
        .withErrorHandler(() => message.error('Не удалось отправить отзыв'))
        .withOptions({ resetOnUnmount: true }),
    getEventHasFeedback: getEventHasFeedback
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: props.type === 'event', resetOnUnmount: true }),
    getConsultationHasFeedback: getConsultationHasFeedback
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: props.type === 'consultation' || props.type === 'promotion', resetOnUnmount: true }),
    getEvent: getEvent
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: props.type === 'event', resetOnUnmount: true }),
    getConsultation: getConsultation
        .withPayload(({ match: { params: { id }}}) => id)
        .withOptions({ dispatchOnMount: props.type === 'consultation' || props.type === 'promotion', resetOnUnmount: true }),
}))(EventFeedback);
