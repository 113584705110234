import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { getUser } from '../actions/asyncActions';
import { openChat } from '../utils/carrotChat';

const Wrapper = styled.div`
    background: #F7F8FC;
    border-radius: 16px;
`;

class Chat extends Component {
    componentDidMount() {
        if (window.carrotquest) {
            window.carrotquest.onReady(this.openChat);
        }
    }

    componentWillUnmount() {
        this.removeChat();
    }

    openChat = () => {
        const { user } = this.props;
        if (window.carrotquest) {
            openChat(user);

            window.carrotquest.addCallback('messenger_closed', this.chatClose);
        }
    }

    removeChat = () => {
        window.carrotquest.removeCallback('messenger_closed', this.chatClose);
        window.carrotquest && window.carrotquest.removeChat();
    }

    chatClose = () => {
        this.props.history.goBack();
        this.removeChat();

        setTimeout(() => {
            if (window.location.pathname === '/chat') {
                this.props.history.replace('/');
            }
        }, 1000);
    }

    render() {
        return (
            <Wrapper />
        );
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state),
});

export default connect(stateToProps)(Chat);
