import UrlPattern from 'url-pattern';

import { getToken } from '../utils/token';

const construct = url => new UrlPattern('(*)' + url);

export const BASE_URL = '/api';

export const FILE_URL = `${BASE_URL}/files`;
export const getFileView = id => `${FILE_URL}/${id}/view?token=${getToken()}`;

export const LOGIN = construct('/participant/login_check');
export const RECOVERY = construct('/password_recovery(/:id)');
export const PASSWORD = construct('sessions/current/password');
export const REGISTRATION_PASSWORD = construct('/registration/password/:token');
export const AGREEMENT_STATUS = construct('/registration/agreement-status/:id');
export const EMPLOYMENT_LIST = construct('/employment/:id');

export const USER = construct('/sessions/current');
export const STAGES = construct('/participant/stages(/:id)');
export const MATERIALS = construct('/participant/materials/:id');
export const MATERIAL_VIEW = construct('/participant/materials/:id/view');
export const MATERIAL_RESULT = construct('/participant/materials/:id/result');
export const FAQ = construct('/participant/faqs');
export const RESUME = construct('/participant/resume(/:id)');
export const REFRESH_TOKEN = construct('/refresh_token');
export const REGISTRATION = construct('/registration');
export const PARTICIPATION_REQUEST = construct('/participation_request/:company');
export const LETTERS = construct('/participant/letters(/:id)');
export const SETTINGS = construct('/settings(/:id)');
export const PROFILE = construct('/participant/profile(/:id)');
export const DOCUMENTS = construct('/participant/profile/documents(/:file)');
export const SEND_RESUME = construct('/participant/resume/export');
export const PRESENTATION = construct('/participant/presentations(/:id)');

export const HH_TOKEN = construct('/external/hh/token/:id');
export const HH_RESUMES = construct('/resumes/mine');
export const HH_RESUME = construct('/resumes/:id');
export const REGISTRATION_CHECK = construct('/registration/check(/:invite)');
export const TESTS = construct('/participant/testing(/:id)');
export const EVENTS = construct('/participant/events(/:id)');
export const SPEAKERS = construct('/participant/events/speakers');
export const EVENT_PARTICIPATE = construct('/participant/events/:event/participate');
export const TEST_PARTICIPATE = construct('/participant/testing/:test/participate');
export const CONSULTATIONS = construct('/participant/consultations(/:id)');
export const PROMOTIONS = construct('/participant/promotions(/:id)');

export const PROMOTION_REQUESTS = construct('/participant/promotion_requests(/:id)');
export const PROMOTION_REQUESTS_THEMES = construct('/participant/promotion_requests/themes');

export const FEEDBACK_CONSULTATION = construct('/participant/feedback/consultation/:id');
export const FEEDBACK_EVENT = construct('/participant/feedback/event/:id');
export const EVENT_HAS_FEEDBACK = construct('/participant/feedback/event/:id/has_feedback');
export const CONSULTATION_HAS_FEEDBACK = construct('/participant/feedback/consultation/:id/has_feedback');

export const PARTICIPATION_REQUEST_ROLE = construct('/participation_request/:company/role/:role');
