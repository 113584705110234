import React, { Component } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import styled from 'styled-components';
import { path } from 'ramda';
import moment from 'moment';
import { message, Button, Spin } from 'antd';

import { getProfilePath, getPromotionRequests, getPromotionRequestsThemes, postPromotions } from '../actions/asyncActions';
import { openPromotionRequestModal } from '../actions/modalActions';
import { POST_PROMOTIONS, POST_PROMOTION_REQUESTS } from '../constants/actionTypes';
import { Link } from 'react-router-dom';
import { PROMOTION_TYPES } from './Main';

const Wrapper = styled.div`
    background: #F7F8FC;
    border-radius: 16px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px;
    h3 {
        font-weight: bold;
        font-size: 24px;
        color: #8C4484;
        margin-bottom: 0;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    small {
        font-size: 14px;
        color: #828282;
        @media (max-width: 767px) {
            display: block;
            margin-bottom: 5px;
            font-size: 13px;
        }
    }
    button {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        border: none;
        color: #8C4484;
        font-weight: bold;
    }
    @media (max-width: 767px) {
        padding: 12px;
        flex-direction: column;
        align-items: start;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px;
    border-top: 1px solid #A7B9CF;
    @media (max-width: 767px) {
        flex-direction: column;
        padding: 16px;
    }
`;

const Date = styled.div`
    color: #2B3D4F;
    font-size: 36px;
    font-weight: bold;
    margin-right: 20px;
    @media (max-width: 767px) {
        font-size: 16px;
        margin-right: 8px;
        font-size: 14px;
        font-weight: normal;
    }
`;

const Day = styled.div`
    margin-right: 40px;
    @media (max-width: 767px) {
        margin-right: 20px;
        flex-direction: row;
        display: flex;
    }
`;

const DateWrap = styled.div`
    display: flex;
    @media (max-width: 767px) {
        flex-direction: row;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const LinkStyled = styled(Link)`
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline; 
    color: #2B3D4F;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

const LinkWrap = styled.div`
    @media (max-width: 767px) {
        order: 1;
    }
`;

const Month = styled.div`
    color: #2B3D4F;
    font-size: 16px;
    font-weight: bold;
    @media (max-width: 767px) {
        font-size: 14px;
        font-weight: normal;
    }
`;

const Time = styled.div`
    color: #A7B9CF;
    @media (max-width: 767px) {
        margin-left: 5px;
    }
`;

const DateStatus = styled.div`
    @media (max-width: 767px) {
        display: flex;
        order: 2;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 8px;
    }
`;

export const Status = styled.div`
    color: ${({ color }) => color};
    background: ${({ background }) => background};
    border-radius: 8px;
    padding: 9px 20px;
    font-weight: bold;
    @media (max-width: 767px) {
        display: none;
    }
`;

export const StatusMobile = styled.div`
    display: none;
    @media (max-width: 767px) {
        display: inline-block;
        color: ${({ color }) => color};
        background: ${({ background }) => background};
        border-radius: 8px;
        font-weight: bold;
        padding: 4px 10px;
        font-size: 12px;
    }
`;

export const STATUSES = {
    pending: {
        text: 'на рассмотрении',
        color: '#47AAC4',
        background: 'rgba(71, 170, 196, 0.2)'
    },
    processing: {
        text: 'обрабатывается',
        color: '#BA9B66',
        background: 'rgba(186, 155, 102, 0.1)'
    },
    completed: {
        text: 'выполнен',
        color: '#549E38',
        background: 'rgba(84, 158, 56, 0.1)'
    }
};

class Promotions extends Component {
    activatePromotions = () => {
        this.props.postPromotions.dispatch({
            type: 'promotion'
        });
    }

    render() {
        const { getProfilePath, getPromotionRequests, openPromotionRequestModal, getPromotionRequestsThemes } = this.props;
        const activated = path(['statistic', 'promotion'], getProfilePath.data);

        return getProfilePath.meta.pending && !getProfilePath.meta.lastSucceedAt ?
            <Spin /> :
            <Wrapper>
                <Header>
                    <div>
                        <h3>{ activated ? PROMOTION_TYPES[getProfilePath.data.statistic.promotion.type] : 'Профессиональная поддержка' }</h3>
                        { activated && <small>Дата окончания сервиса: <strong>{ moment(getProfilePath.data.statistic.promotion.end).format('DD.MM.YYYY') }</strong></small>}
                    </div>
                    { activated ?
                        (getProfilePath.data.statistic.promotion.availableDays > 0 && <Button onClick={() => openPromotionRequestModal()}>Отправить запрос</Button>) :
                        <Button onClick={this.activatePromotions}>Активировать</Button>
                    }
                </Header>
                { getPromotionRequests.meta.pending && !getPromotionRequests.meta.lastSucceedAt ?
                    <Spin /> : (getPromotionRequests.data.items || []).map(item =>
                    <Item>
                        <Title>
                            <DateStatus>
                                <DateWrap>
                                    <Date>
                                        { moment(item.createdAt).format('DD') }
                                    </Date>
                                    <Day>
                                        <Month>{ moment(item.createdAt).format('MMMM') }, { moment(item.createdAt).format('dd') }</Month>
                                        <Time>{ moment(item.createdAt).format('HH:mm') }</Time>
                                    </Day>
                                </DateWrap>
                                <StatusMobile color={STATUSES[item.status].color} background={STATUSES[item.status].background}>
                                    { STATUSES[item.status].text }
                                </StatusMobile>
                            </DateStatus>
                            <LinkWrap>
                                <LinkStyled to={`/promotion/${item.id}`}>{ getPromotionRequestsThemes.data[item.theme] }</LinkStyled>
                            </LinkWrap>
                        </Title>
                        <Status color={STATUSES[item.status].color} background={STATUSES[item.status].background}>
                            { STATUSES[item.status].text }
                        </Status>
                    </Item>
                )}
            </Wrapper>;
    }
}

export default asyncConnect({
    getProfilePath: getProfilePath
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_PROMOTIONS)], () => {
                getProps().getProfilePath.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    postPromotions: postPromotions
        .withSuccessHandler(() => message.success('Сервис "Персональный менеджер и продвижение" успешно активирован'))
        .withErrorHandler(() => message.error('Не удалось активировать сервис "Персональный менеджер и продвижение"'))
        .withOptions({ resetOnUnmount: true }),
    getPromotionRequests: getPromotionRequests
        .withPayload(() => ({
            limit: 0
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_PROMOTION_REQUESTS)], () => {
                getProps().getPromotionRequests.refresh();
            });
        })
        .withOptions({ resetOnUnmount: true, dispatchOnMount: true }),
    getPromotionRequestsThemes: getPromotionRequestsThemes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openPromotionRequestModal })(Promotions);
