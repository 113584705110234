import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Row, Col } from 'antd';
import { pathOr, propEq } from 'ramda';
import styled from 'styled-components';
import { GENDER } from '@meconsultant/common';

import { getUser, deleteDocument } from '../../actions/asyncActions';
import { openDocumentModal } from '../../actions/modalActions';

const ListForm = styled.section`
    color: #363636;
    .label{
        font-weight: 300;
        font-size: 16px;
    }
    .value {
        font-size: 16px;
    }
    .row-group{
        margin-bottom: 10px;
        b {
            word-break: break-all;
        }
    }
`;

const Group = styled.div`
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 24px;
`;

class ProfileInfo extends Component {
    getDictName = (dict, value) => {
        return pathOr(null, ['name'], dict.find(propEq('id', value)));
    }

    renderRow = (title, value) => {
        return <Row className='row-group' gutter={16}>
            <Col xs={24} md={12}>
                <span className='label'>{ title }</span>
            </Col>
            <Col xs={24} md={12}>
                <span className='value'>{ value || '-' }</span>
            </Col>
        </Row>;
    }

    render() {
        const { user } = this.props;
        return <ListForm>
            <Group>Контактная информация</Group>
            { this.renderRow('Пол', this.getDictName(GENDER, user.gender)) }
            { this.renderRow('Email', user.email) }
            { this.renderRow('Телефон', user.phone) }
            { this.renderRow('Город', user.location) }
        </ListForm>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    deleteDocument: deleteDocument
        .withParams(() => ({ type: 'list' }))
        .withOptions({ resetOnUnmount: true })
}, stateToProps, { openDocumentModal })(ProfileInfo);
