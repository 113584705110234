import React, { Component } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import styled from 'styled-components';
import { Button, Spin } from 'antd';

import { getProfilePath, getTests } from '../actions/asyncActions';
import { openTestRequestModal } from '../actions/modalActions';
import { PUT_TEST_PARTICIPATE } from '../constants/actionTypes';

const Wrapper = styled.div`
    background: #F7F8FC;
    border-radius: 16px;
`;

const ItemButton = styled(Button)`
    background: #2B3D4E;
    box-shadow: 1px 1px 5px rgba(62, 61, 61, 0.25);
    border-radius: 3px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    border: none;
    padding: 11px 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    text-align: center;
    &:hover {
        background: #2B3D4E;
        color: #fff;
        opacity: 0.9;
    }
    @media (max-width: 767px) {
        margin-top: 12px;
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px;
    h3 {
        font-weight: bold;
        font-size: 24px;
        color: #2B3D4F;
        margin-bottom: 0;
        @media (max-width: 767px) {
            font-size: 16px;
        }
    }
    small {
        font-size: 14px;
        color: #828282;
        @media (max-width: 767px) {
            display: block;
            margin-bottom: 5px;
            font-size: 13px;
        }
    }
    button {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        border: none;
        color: #8C4484;
        font-weight: bold;
    }
    @media (max-width: 767px) {
        padding: 12px;
        flex-direction: column;
        align-items: start;
    }
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px;
    border-top: 1px solid #A7B9CF;
    @media (max-width: 767px) {
        padding: 16px;
        flex-direction: column;
        align-items: start;
    }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const LinkStyled = styled.span`
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    color: #2B3D4F;
    @media (max-width: 767px) {
        font-size: 16px;
    }
`;

const LinkWrap = styled.div`
    @media (max-width: 767px) {
        order: 1;
    }
`;

export const Status = styled.div`
    color: ${({ color }) => color};
    background: ${({ background }) => background};
    border-radius: 8px;
    padding: 9px 20px;
    font-weight: bold;
    min-width: 145px;
    text-align: center;
    @media (max-width: 767px) {
        display: inline-block;
        border-radius: 8px;
        font-weight: bold;
        padding: 4px 10px;
        font-size: 12px;
    }
`;

export const STATUSES = {
    attended: {
        text: 'отправлено',
        color: '#549E38',
        background: 'rgba(84, 158, 56, 0.1)'
    }
};

class Tests extends Component {
    render() {
        const { getTests } = this.props;

        return getTests.meta.pending && !getTests.meta.lastSucceedAt ?
            <Spin /> :
            <Wrapper>
                <Header>
                    <div>
                        <h3>Тестирование</h3>
                        {/* <small>Доступно: <strong>1/3</strong></small> */}
                    </div>
                </Header>
                { !(getTests.data.items || []).length ? (
                    <Item>
                        <Title>
                            <LinkWrap>
                                <LinkStyled>Нет доступных тестирований</LinkStyled>
                            </LinkWrap>
                        </Title>
                    </Item>
                ) : (getTests.data.items || []).map(theme => (
                    <Item key={theme.id}>
                        <Title>
                            <LinkWrap>
                                <LinkStyled>{theme.title}</LinkStyled>
                            </LinkWrap>
                        </Title>
                        { theme.attended ? (
                            <Status color={STATUSES.attended.color} background={STATUSES.attended.background}>
                                { STATUSES.attended.text }
                            </Status>
                        ) : (
                            <ItemButton onClick={() => this.props.openTestRequestModal({ test: theme.id })}>Запросить</ItemButton>
                        )}
                    </Item>
                ))}
            </Wrapper>
    }
}

export default asyncConnect({
    getProfilePath: getProfilePath
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getTests: getTests
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_TEST_PARTICIPATE)], () => {
                getProps().getTests.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
}, null, { openTestRequestModal })(Tests);
