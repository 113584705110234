import React from 'react';
import { Switch } from 'react-router';

import Login from './Login';
import Recovery from './Recovery';
import Reset from './Reset';
import Route from './Route';

const GuestRoutes = () => (
    <Switch>
        <Route path='/recovery' component={Recovery} />
        <Route path='/reset/:id' component={Reset} />
        <Route path='/' component={Login} />
    </Switch>
);

export default GuestRoutes;
