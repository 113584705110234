import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import styled, { css } from 'styled-components';
import { Row, Col, Button, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { pluralize } from 'numeralize-ru';
import { pathOr, isNil, find, propEq, path, values, all } from 'ramda';
import { ClockCircleOutlined, FileOutlined } from '@ant-design/icons';

import { getStages } from '../actions/asyncActions';
import WrapLayout from './layout/WrapLayout';
import { ImageHeader, ProgressHeader } from './ImageHeader';
import { STAGES } from '../constants/stages';
import { getFileView } from '../constants/urls';
import { getTime } from '../utils/time';

const WrapCourse = styled.div`
    position: relative;
    z-index: 20;
    @media (max-width: 767px) {
        padding: 0;
    }
`;

const CourseRow = styled(Row)`
    display: flex!important;
    @media (max-width: 991px) {
        flex-direction: column;
    }
    @media (max-width: 767px) {
        margin-left: 0!important;
        margin-right: 0!important;
    }
`;

const ColBox = styled(Col)`
    @media (max-width: 991px) {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 0!important;
    }
`;

const CourseLink = styled(Link)`
    display: flex;
    height: 100%;
    ${({ disabled }) => disabled && css`
        pointer-events: none;
    `}
`;

const CourseBox = styled.section`
    display: flex;
    flex-direction: column;
    background: #F7F8FC;
    border: 1px solid #ececec;
    border-radius: 12px;
    width: 100%;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    ${ImageHeader} {
        height: 200px;
        margin: 0;
        border-radius: 12px 12px 0 0;
        &:before{
            border-radius: 12px 12px 0 0;
            background-position: center;
        }
        &:after {
            border-radius: 12px 12px 0 0;
            height: 200px;
        }
    }
`;

const TitleCourse = styled.h1`
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    color: #000;
`;

const DescCourse = styled.small`
    display: block;
    margin-top: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #333333;
    .anticon {
        color: #418FDE;
        font-size: 22px;
        margin-right: 8px;
    }
    .box-desc {
        display: flex;
        & > span {
            display: inline-flex;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

const BodyCourse = styled.section`
    padding: 50px 24px 24px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-between;
    @media (max-width: 991px) {
        padding: 40px 16px 16px;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
    margin-top: 16px;
    visibility: ${({visible}) => visible};
`;

const Stub = styled.div`
    width: ${({ small }) => small ? 70 : 100}%;
    height: ${({ small }) => small ? 11 : 15}px;
    background: #${({ small }) => small ? 'eee' : 'ccc'};
    border-radius: 20px;
    margin-top: ${({ small }) => small ? 14 : 10}px;
    margin-bottom: ${({ small }) => small ? 15 : 0}px;
`;

class Stages extends Component {
    goToMaterial = (e, type, themes, stage) => {
        e.preventDefault();

        const materials = themes.reduce((res, cur) => res.concat(cur.materials), []);
        const currentMaterial = find(propEq('viewed', false), materials);

        this.props.history.push(
            currentMaterial ? `/stage/${type}/material/${currentMaterial.id}` : `/stage/${type}`,
            { title: path(['title'], currentMaterial), stage }
        );
    }

    renderStage = (stage) => {
        const { data: { items: stages = [] }, meta: { success }} = this.props.getStages;
        const data = find(propEq('id', stage.type), stages) || {};
        const themes = pathOr([], ['themes'], data);
        const materialsLength = themes.reduce((res, cur) => res + cur.materials.length, 0);
        const { progress } = data;
        const inProgress = progress > 0 && progress < 100;
        const topics = pathOr([], ['_embedded', 'topics'], data);

        return <ColBox md={24} lg={8} key={stage.type} style={{ marginBottom: 15 }}>
            <CourseLink to={{ pathname: `/stage/${stage.type}`, state: { stage: data }}} disabled={!success}>
                <CourseBox>
                    <ImageHeader image={data.image ? getFileView(data.image) : stage.img} done={progress === 100}>
                            <ProgressHeader>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <div style={{ position: 'absolute', background: '#fff', top: 2, left: 2, right: 2, bottom: 2, borderRadius: '50%' }} />
                                    <Progress type="circle" width={54} strokeWidth={8} strokeColor='#3AB54A' percent={progress}/>
                                </div>
                            </ProgressHeader>
                    </ImageHeader>
                    <BodyCourse>
                        <TitleCourse>
                            { success ? data.title : <Stub /> }
                        </TitleCourse>
                        <div>
                            <DescCourse>
                                { success ?
                                <div>
                                <div>{ topics.map((topic, index) => <span key={topic.id}>{ `${topic.title}${index < topics.length - 1 ? ', ' : ''}` }</span>) }</div>
                                <div className='box-desc'>
                                    <span><FileOutlined />{ `${materialsLength} ${pluralize(materialsLength, 'материал', 'материала', 'материалов')}`}</span>
                                    { !!data.time && <span><ClockCircleOutlined />{ getTime(data.time) }</span> }
                                </div>
                                </div> :
                                <Stub small />
                            }
                            </DescCourse>
                            <StyledButton
                                visible={success ? 'visible' : 'hidden'}
                                type={inProgress ? 'primary' : progress === 100 ? 'default' : 'ghost'}
                                size='large'
                                onClick={e => this.goToMaterial(e, stage.type, themes, data)}>
                                {inProgress ? 'Продолжить' : progress === 100 ? 'Посмотреть' : 'Начать'}
                            </StyledButton>
                        </div>
                    </BodyCourse>
                </CourseBox>
            </CourseLink>
        </ColBox>;
    }

    render() {
        const { data } = this.props.getStages;
        const stages = (data.items || STAGES).map(({ id }) => ({ type: id, img: path(['img'], find(propEq('type', id), STAGES)) }));
        const currentCourse = find(stage => {
            const item = data[stage.type];
            return item && !isNil(item.progress) && item.progress !== 100;
        }, stages);
        const type = currentCourse ? currentCourse.type : 'finish';
        const primaryType = all(propEq('progress', 100))(values(data)) ? false : type;

        return <WrapLayout>
            <WrapCourse className='box-padding'>
                <CourseRow gutter={24} id='reactour__materials'>
                    { !!(this.props.term || '').length && !stages.length &&
                        <div style={{ textAlign: 'center', width: '100%' }}>Ничего не найдено</div>
                    }
                    { stages.map(stage => this.renderStage(stage, primaryType)) }
                </CourseRow>
            </WrapCourse>
        </WrapLayout>;
    }
}

export default withAsyncActions({
    getStages: getStages
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
})(Stages);
