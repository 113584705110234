import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import PasswordForm from '../forms/PasswordForm';
import { putPassword } from '../../actions/asyncActions';

class PasswordModal extends Component {
    static propTypes = {
        modal: PropTypes.object,
        putPassword: PropTypes.object
    };

    render() {
        const { modal } = this.props;

        return <Modal
            {...modal}
            title='Изменить пароль'
            footer={null}>
            <PasswordForm formAction={this.props.putPassword} />
        </Modal>;
    }
}

export default withAsyncActions({
    putPassword: putPassword
        .withSuccessHandler(({ close }) => {
            message.success('Пароль был успешно изменен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(PasswordModal);
