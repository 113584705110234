import React, { Component } from 'react';
import { Modal, Table, Button } from 'antd';
import { withAsyncActions } from 'react-async-client';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

import { getHHResumes } from '../../actions/asyncActions';

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
    }
`;

class HHResumesModal extends Component {
    getColumns = () => {
        return [
            {
                dataIndex: 'title',
                key: 'title',
                render: (title, { alternate_url }) => <a href={alternate_url} target='_blank' rel="noopener noreferrer">{ title }</a>
            },
            {
                key: 'actions',
                align: 'right',
                render: item => <Button shape='circle' icon={<PlusOutlined />} onClick={() => this.add(item.id)} />
            }
        ];
    }

    add = id => {
        this.props.close();
        this.props.params.onOpenResume(this.props.params.item, id);
    }

    render() {
        const { modal, getHHResumes } = this.props;

        return <StyledModal
            {...modal}
            title='Резюме HeadHunter'
            footer={null}>
            <Table
                dataSource={pathOr([], ['data', 'data', 'items'], getHHResumes)}
                rowKey='id'
                showHeader={false}
                pagination={false}
                columns={this.getColumns()}
                loading={getHHResumes.meta.pending} />
        </StyledModal>;
    }
}

export default withAsyncActions({
    getHHResumes: getHHResumes
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
})(HHResumesModal);
