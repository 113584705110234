import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncActions, toSuccess } from 'react-async-client';
import { path, pathOr, findIndex, propEq, is, last } from 'ramda';
import { Card, Button, message, Spin, notification } from 'antd';
import { FormGenerator } from '@experium/react-editor';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { takeEvery } from 'redux-saga/effects';
import {
    CheckList,
    CoverLetter,
    Feedback,
    FeedbackComponent,
    EditorSettingsContext,
    MotivationAnalysis,
    PersonalityAnalysis,
    SelectionCriteria,
    Presentation,
    OnSubmitContext,
    TextImage,
    TextImageComponent
} from '@meconsultant/common';
import { LeftOutlined, RightOutlined, ClockCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';

import { putMaterialView, getMaterial, getStage, putMaterialResult, getSettings } from '../actions/asyncActions';
import WrapLayout from './layout/WrapLayout';
import { PUT_MATERIAL_RESULT } from '../constants/actionTypes';
import Resume from './Resume';
import { getFileView } from '../constants/urls';
import CheckListComponent from './CheckList';
import CoverLetterComponent from './CoverLetter';
import MotivationAnalysisComponent from './MotivationAnalysis';
import PersonalityAnalysisComponent from './PersonalityAnalysis';
import SelectionCriteriaComponent from './SelectionCriteria';
import { withState } from 'recompose';
import PresentationComponent from './Presentation';
import { getFullTime } from '../utils/time';

const Buttons = styled.div`
    margin: 30px 0 10px;
    &:before,&:after {
        content: '';
        display: table;
        clear: both;
    }
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
    }
`;

const ButtonRightMargin = styled(Button)`
    margin-right: 10px;
    padding: 0;
    .anticon {
        margin-right: 8px;
        font-size: 13px;
        @media (max-width: 767px) {
            margin: 0;
        }
    }
`;

const ButtonNext = styled(Button)`
    float: right;
    .anticon {
        margin-left: 8px;
        font-size: 13px;
        @media (max-width: 767px) {
            margin: 0;
        }
    }
`;

const FormGeneratorMaxWidth = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    img {
        max-width: 100%;
        height: auto;
    }
`;

const CardBox = styled(Card)`
    background: #fff;
    &.ant-card {
        box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
        border-radius: 12px;
        padding: 16px 24px;
        margin-left: 24px;
        @media (max-width: 767px) {
            margin-left: 0;
            padding: 0;
        }
    }
    .ant-card-head {
        border: 0;
        padding: 0 0 0 24px;
        @media (max-width: 767px) {
            padding: 0;
        }
    }
    .ant-card-body {
        padding: 24px 0 24px 24px;
        @media (max-width: 767px) {
            padding: 24px 0;
        }
    }
    .ant-card-head-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        padding: 15px 0 5px;
        float: left;
        white-space: normal;
        small {
            color: #585858;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            margin-top: 10px;
            display: inline-flex;
            align-items: center;
            i {
                margin-right: 8px;
            }
        }
    }
    .experium-player-builder {
        padding: 0!important;
        color: #000;
        .ant-form {
            color: inherit;
            font-weight: 300;
            font-size: 15px;
        }
    }
    .ant-card-head-wrapper {
        .ant-card-extra {
            margin: 4px 10px;
            float: left;
            display: inline-block;
        }
       .ant-col-24 {
            overflow-x: auto;
            max-width: 100%;
       }
        @media (max-width: 767px) {
            display: flex;
            align-items: baseline;
            .ant-card-extra {
                margin: 0 0 0 5px;
            }
        }
    }
`;

const formComponents = [
    {
        type: 'Resume',
        component: Resume,
        formComponent: Resume
    },
    {
        type: 'Feedback',
        component: Feedback,
        formComponent: FeedbackComponent
    },
    {
        type: 'CoverLetter',
        component: CoverLetter,
        formComponent: CoverLetterComponent
    },
    {
        type: 'MotivationAnalysis',
        component: MotivationAnalysis,
        formComponent: MotivationAnalysisComponent
    },
    {
        type: 'PersonalityAnalysis',
        component: PersonalityAnalysis,
        formComponent: PersonalityAnalysisComponent
    },
    {
        type: 'SelectionCriteria',
        component: SelectionCriteria,
        formComponent: SelectionCriteriaComponent
    },
    {
        type: 'Presentation',
        component: Presentation,
        formComponent: PresentationComponent
    },
    {
        type: 'TextImage',
        component: TextImage,
        formComponent: TextImageComponent
    },
    {
        type: 'CheckList',
        component: CheckList,
        formComponent: CheckListComponent
    },
];

class Materials extends Component {
    static propTypes = {
        location: PropTypes.object,
        getMaterial: PropTypes.object,
        getStage: PropTypes.object,
        match: PropTypes.object,
        putMaterialView: PropTypes.object,
        history: PropTypes.object,
        putMaterialResult: PropTypes.object,
    };

    componentDidMount() {
        const { putMaterialView, match: { params: { materialId }}} = this.props;

        putMaterialView.dispatch({ id: materialId });
    }

    componentDidUpdate(prev) {
        const { putMaterialView, match: { params: { materialId }}} = this.props;

        if (prev.match.params.materialId !== materialId) {
            putMaterialView.dispatch({ id: materialId });
        }
    }

    getStage = () => {
        const { location, getStage: { data }} = this.props;

        return pathOr(data, ['state', 'stage'], location);
    }

    getMaterials = () => {
        const stage = this.getStage();

        return stage.themes ? stage.themes.reduce((res, cur) => res.concat(cur.materials), []) : [];
    }

    next = (nextMaterial = {}, isLast) => {
        const { match: { params: { stageId, materialId }}, history, putMaterialViewFinish } = this.props;

        if (isLast) {
            putMaterialViewFinish.dispatch({ id: materialId });
        } else {
            history.push(`/stage/${stageId}/material/${nextMaterial.id}`, {
                stage: this.getStage(),
                title: nextMaterial.title
            });
        }
    }

    renderButtons = () => {
        const { match: { params: { materialId, stageId }}} = this.props;
        const materials = this.getMaterials();
        const index = findIndex(propEq('id', materialId), materials);

        return index > -1 && <Buttons>
            { !!index &&
                <ButtonRightMargin type='link'>
                    <Link to={{
                        pathname: `/stage/${stageId}/material/${materials[index - 1].id}`,
                        state: {
                            stage: this.getStage(),
                            title: materials[index - 1].title
                        }
                    }}>
                        <LeftOutlined /><span className="no-phone">Вернуться</span>
                    </Link>
                </ButtonRightMargin>
            }
            <ButtonNext
                ghost={true}
                type='primary'
                onClick={() => this.next(materials[index + 1], index >= materials.length - 1)}>
                { index >= materials.length - 1 ? 'Завершить' : <span>Далее<RightOutlined /></span>}
            </ButtonNext>
        </Buttons>;
    }

    onSubmit = values => {
        const { putMaterialResult, match: { params: { materialId }}} = this.props;

        putMaterialResult.dispatch({
            id: materialId,
            result: values
        });
    }

    setFavorite = () => {
        const { favorite, setFavorite, putFavorite, match } = this.props;

        setFavorite(!favorite);
        putFavorite.dispatch({
            favorite: !favorite,
            id: match.params.materialId
        });
    }

    render() {
        const { location, getMaterial: { data, meta }, getSettings, favorite } = this.props;
        const title = path(['state', 'title'], location) || data.title;
        const time = getFullTime(data.time);

        return meta.pending && !meta.lastSucceedAt ?
            <Spin /> :
            <OnSubmitContext.Provider value={this.onSubmit}>
                <WrapLayout>
                    <CardBox
                        bordered={false}
                        title={ <span>{title} <br/>{ !!time && <small><ClockCircleOutlined style={{ fontSize: '16px', color: '#1771E6' }} />&nbsp;{ time }</small>}</span>}
                        extra={favorite ?
                            <StarFilled
                                style={{ fontSize: '20px', color: '#F5A22D' }}
                                onClick={this.setFavorite} /> :
                            <StarOutlined
                                style={{ fontSize: '20px', color: '#F5A22D' }}
                                onClick={this.setFavorite} />
                        }>
                        <FormGeneratorMaxWidth>
                            <EditorSettingsContext.Provider value={getSettings.data || {}}>
                                <FormGenerator
                                    data={data.content}
                                    onSubmit={this.onSubmit}
                                    values={is(Array, data.result) ? {} : data.result}
                                    components={formComponents}
                                    downloadUrl={getFileView} />
                            </EditorSettingsContext.Provider>
                        </FormGeneratorMaxWidth>
                        { this.renderButtons() }
                    </CardBox>
                </WrapLayout>
            </OnSubmitContext.Provider>;
    }
}

export default withState('favorite', 'setFavorite', false)(
    withAsyncActions(({ location }) => ({
        getMaterial: getMaterial
            .withPayload(({ match }) => match.params.materialId)
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true, resetOnUpdate: true })
            .withSuccessHandler(({ setFavorite, getMaterial }) => setFavorite(getMaterial.data.favorite))
            .withSaga(function* (getProps) {
                yield takeEvery([toSuccess(PUT_MATERIAL_RESULT)], () => {
                    getProps().getMaterial.refresh();
                });
            }),
        putMaterialResult: putMaterialResult
            .withSuccessHandler(() => {
                if (localStorage.getItem('showSaveResultMessage')) {
                    notification.open({
                        description: 'Ваши результаты были сохранены в разделе «Выполненные упражнения»',
                        className: 'interactive-message'
                    });
                    localStorage.removeItem('showSaveResultMessage');
                } else {
                    message.success('Результат был успешно сохранен');
                }
            })
            .withOptions({ resetOnUnmount: true }),
        getStage: getStage
            .withPayload(({ match }) => match.params.stageId)
            .withOptions({ dispatchOnMount: !path(['state', 'stage'], location), resetOnUnmount: true }),
        putFavorite: putMaterialView
            .withParams(() => ({ type: 'favorite' }))
            .withSuccessHandler(({ putFavorite, setFavorite }) => {
                setFavorite(putFavorite.data.favorite);
            })
            .withErrorHandler(() => message.error('Не удалось добавить материал в избранное'))
            .withOptions({ resetOnUnmount: true }),
        putMaterialView: putMaterialView
            .withOptions({ resetOnUnmount: true }),
        putMaterialViewFinish: putMaterialView
            .withParams(() => ({ type: 'finish' }))
            .withSuccessHandler(({ match: { params: { materialId }}, getStage: { data }, location, history }) => {
                const { themes = [] } = pathOr(data, ['state', 'stage'], location);
                const materials = themes.reduce((res, cur) => res.concat(cur.materials), []);

                if (last(materials).id === materialId) {
                    history.push('/');
                }
            })
            .withOptions({ resetOnUnmount: true }),
        getSettings: getSettings
            .withPayload(() => 'editor')
            .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
    }))(Materials)
);
