import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import Checkbox from './formComponents/Checkbox';
import RadioButtons from './formComponents/RadioButtons';

class PersonalAgreementFields extends Component {
    render() {
        return <Fragment>
            <Field
                {...this.props}
                name='personalAgreement'
                component={Checkbox}
                text={
                    <Fragment>
                        <p>Настоящим во исполнение требований Закона «О персональных данных» № 152-ФЗ от 27.07.2006 г. подтверждаю, что ознакомлен и принимаю условия <Link target='_blank' to='/offer'>Публичной оферты</Link>, регламентирующей цели, порядок и сроки обработки моих персональных данных ООО «АНКОР Консалтинг» (юридический адрес: 109544, г Москва, б-р Энтузиастов, 2, помещение 2 этаж 13, ИНН 7701872919), именуемым в дальнейшем "Оператор".</p>
                        <p>Также даю согласие на получение электронных рассылок, осуществляемых в целях приглашения меня на вебинары, семинары и консультации, а также осуществления Оператором деятельности, связанной с аналитикой рынка труда:</p>
                        <Field
                            name='newsletterAgreement'
                            component={RadioButtons}
                            simple
                            options={[
                                { id: true, name: 'Да' },
                                { id: false, name: 'Нет' }
                            ]} />
                    </Fragment>
                } />
        </Fragment>;
    }
}

export default PersonalAgreementFields;
