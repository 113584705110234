import { path } from 'ramda';

export const connectChat = (history) => {
    window.carrotquest.connect(process.env.REACT_APP_CARROTQUEST_ID, {
        settings: {
            messenger_mode: 'hidden'
        }
    });

    if (history.location.pathname !== '/chat') {
        window.carrotquest.removeChat();
    }
}

export const authUser = (user) => {
    window.carrotquest.auth(user.id, user.carrotQuestHash);
    window.carrotquest.identify([
        { op: 'update_or_create', key: '$name', value: `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`},
        { op: 'update_or_create', key: '$email', value: user.email },
        { op: 'update_or_create', key: 'Город', value: user.location },
        { op: 'update_or_create', key: 'Компания', value: path(['_embedded', 'company', 'name'], user) },
        { op: 'update_or_create', key: 'Отрасль', value: user.industryName },
        { op: 'update_or_create', key: 'Должность', value: user.position },
        { op: 'update_or_create', key: 'Функция', value: user.functionName },
    ].filter(item => !!item.value));
}

export const openChat = (user) => {
    authUser(user);

    setTimeout(() => {
        window.carrotquest.open();
    });
}

export const addRedirectHandle = (history) => {
    window.carrotquest.addCallback('messenger_opened', () => {
        setTimeout(() => {
            if (history.location.pathname !== '/chat') {
                history.push('/chat');
            }
        });
    });
}
