import { Component } from 'react';
import { pathEq } from 'ramda';
import { connect } from 'react-redux';
import { message } from 'antd';

import { hhChannel } from '../utils/hh';
import { openHHResumesModal } from '../actions/modalActions';
import { hhAxios } from '../actions/handlers/hhHandlers';

class HHResumes extends Component {
    componentDidMount() {
        hhChannel.onmessage = this.hhChanneHandler;
    }

    componentWillUnmount() {
        hhChannel.close();
    }

    hhChanneHandler = event => {
        if (pathEq(['type'], 'hh_auth', event)) {
            if (!this.props.id || this.props.id === event.id) {
                if (event.error) {
                    message.error('Не удалось авторизоваться');
                } else {
                    hhAxios.defaults.headers.common['Authorization'] = `Bearer ${event.token}`;
                    this.props.openHHResumesModal({
                        item: event.item,
                        onOpenResume: this.props.onOpenResume
                    });
                }

                window.hhLocation && window.hhLocation.close();
            }
        }
    }

    render() {
        return this.props.children;
    }
}

export default connect(null, { openHHResumesModal })(HHResumes);
