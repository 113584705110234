import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Button } from 'antd';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';

class PasswordForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='currentPassword'
                component={Input}
                label='Текущий пароль'
                htmlType='password' />
            <Field
                name='password'
                component={Input}
                label='Новый пароль'
                htmlType='password' />
            <Button
                type='primary'
                htmlType='submit'
                disabled={isSubmitting}>
                Сохранить
            </Button>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    currentPassword: yup.string().required(),
    password: yup.string().min(6, 'Пароль должен содержать минимум 6 символов').required()
});

export default withFormWrapper(PasswordForm, {
    validationSchema
});
