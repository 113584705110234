import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { SelectionCriteriaForm } from '@meconsultant/common';
import { pathOr } from 'ramda';

import { putProfilePath, getSettings } from '../../actions/asyncActions';

class CriteriaModal extends Component {
    render() {
        const { modal, params, putProfilePath, criteriaSettings } = this.props;

        return <Modal
            {...modal}
            title='Выбор критериев и категорий для поиска компании-работодателя'
            footer={null}>
            <SelectionCriteriaForm
                formAction={putProfilePath}
                data={params || {}}
                criteriaSettings={criteriaSettings} />
        </Modal>;
    }
}

const stateToProps = state => ({
    criteriaSettings: pathOr([], ['criteria'], getSettings.selectData(state))
});

export default asyncConnect({
    putProfilePath: putProfilePath
        .withSuccessHandler(({ close }) => {
            message.success('Критерии и категории для поиска компании-работодателя успешно сохранены');
            close();
        })
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(CriteriaModal);
