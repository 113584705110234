import { Textarea, withFormWrapper } from '@meconsultant/common';
import { Button, Col, Row } from 'antd';
import React, { Component, Fragment } from 'react';
import { Field } from 'react-final-form';
import * as yup from 'yup';

import Stars from './formComponents/Stars';

const formItemLayout = {
    labelCol: { sm: 8, xs: 24 },
    wrapperCol: { sm: 16, xs: 24 }
};

class EventFeedbackForm extends Component {
    render() {
        const { isSubmitting } = this.props;

        return <Fragment>
            <Field
                name='rating'
                component={Stars}
                label='Оценка'
                max={5}
                {...formItemLayout}
                color='#FADB14' />
            <Field
                name='comment'
                component={Textarea}
                label='Комментарий'
                autosize={{ minRows: 8 }}
                placeholder='Введите комментарий...'
                small='Нам очень важно получить Ваше мнение! Все полученные данные помогут нам улучшить качество предлагаемой продукции и обслуживания.'
                {...formItemLayout} />
            <Row>
                <Col sm={8} xs={24} />
                <Col sm={16} xs={24}>
                    <Button
                        type='primary'
                        htmlType='submit'
                        size='large'
                        disabled={isSubmitting}>
                        ОТПРАВИТЬ
                    </Button>
                </Col>
            </Row>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    rating: yup.number().nullable().required()
});

export default withFormWrapper(EventFeedbackForm, {
    mapPropsToValues: ({ item }) => item,
    validationSchema
});
