import styled from 'styled-components';

export const ImageHeader = styled.section`
    position: relative;
    height: 430px;
    background: #fff;
    margin-bottom: 24px;
    border-radius: 12px;
    zoom: 1;
    min-height: 48px;
    &:before{
		content: '';
		border-radius: 12px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${props => `url("${props.image}")`} center -50px no-repeat;
		background-size: cover;
		@media (max-width: 1000px) {
		    background-position: center;
		}
	}
	&:after {
	    content: '';
	    border-radius: 12px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        height: 430px;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
    }
`;

export const ProgressHeader = styled.section`
    color: #fff;
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    padding: 2px 24px;
    z-index: 9;
    .ant-progress-circle-trail {
        stroke: #F2F2F2!important;
    }
    .ant-progress-circle {
        .ant-progress-text {
            font-weight: 500;
            font-size: 16px;
            color: #000;
        }
        &.ant-progress-status-success .ant-progress-text{
            color: #3AB54A;
        }
    }
`;

export const ImageHeaderTitle = styled.h1`
    color: #fff;
    position: relative;
    font-size: 40px;
    padding: 60px 50px;
    height: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .box-desc {
        margin: 10px 0 20px;
        & > span {
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            margin-right: 20px;
            i {
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }
    @media (max-width: 767px) {
        font-size: 28px;
        padding: 16px 16px 40px 16px;
        .box-desc > span {
            font-size: 14px;
            margin-right: 15px;
             i {
                margin-right: 5px;
            }
        }
        button {
            width: 100%;
        }
        .ant-avatar.ant-avatar-icon {
            width: 50px!important;
            height: 50px!important;
            font-size: 25px!important;
            line-height: 50px!important;
        }
    }
`;

export const ImageHeaderDesc = styled.small`
    font-size: 18px;
    color: #ececec;
    display: block;
`;
