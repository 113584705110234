import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { withAsyncActions } from 'react-async-client';

import { postDocument } from '../../actions/asyncActions';
import DocumentForm from '../forms/DocumentForm';

class DocomentModal extends Component {
    render() {
        const { modal, postDocument, params } = this.props;

        return <Modal
            {...modal}
            wrapClassName='modal-document'
            title='Загрузить файл'
            footer={null}>
            <DocumentForm formAction={postDocument} item={params} cancel={() => modal.onCancel()} />
        </Modal>;
    }
}

export default withAsyncActions({
    postDocument: postDocument
        .withSuccessHandler(({ close }) => {
            message.success('Файл успешно добавлен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
})(DocomentModal);
