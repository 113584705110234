import { pluralize } from 'numeralize-ru';

export const getTime = minutes => {
    if (minutes < 60) {
        return `${minutes} ${pluralize(minutes, 'минута', 'минуты', 'минут')}`;
    } else if (minutes < 1440) {
        const hours = Math.ceil(minutes / 60);
        return `~${hours} ${pluralize(hours, 'час', 'часа', 'часов')}`;
    } else if (minutes < 10080) {
        const days = Math.ceil(minutes / 1440);
        return `~${days} ${pluralize(days, 'день', 'дня', 'дней')}`;
    } else {
        const weeks = Math.ceil(minutes / 10080);
        return `~${weeks} ${pluralize(weeks, 'неделя', 'недели', 'недель')}`;
    }
}

export const getFullTime = time => {
    const weeks = Math.floor(time / 10080);
    const days = Math.floor((time - weeks * 10080) / 1440);
    const hours = Math.floor((time - weeks * 10080 - days * 1440) / 60);
    const minutes = time - weeks * 10080 - days * 1440 - hours * 60;

    return (weeks ? `${weeks} ${pluralize(weeks, 'неделя', 'недели', 'недель')} ` : '') +
        (days ? `${days} ${pluralize(days, 'день', 'дня', 'дней')} ` : '') +
        (hours ? `${hours} ${pluralize(hours, 'час', 'часа', 'часов')} ` : '') +
        (minutes ? `${minutes} ${pluralize(minutes, 'минута', 'минуты', 'минут')}` : '');
}
