import React from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';

const Layout = ({ ...props }) => <AntLayout {...props} />;

const WrapLayout = styled(Layout)`
    .ant-card{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
`;

export default WrapLayout;
