import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Button, Col, Row } from 'antd';
import * as yup from 'yup';

import { withFormWrapper, GENDER } from '@meconsultant/common';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import RadioButtons from "./formComponents/RadioButtons";
import PersonalAgreementFields from './PersonalAgreementFields';

const labelCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10 }
};
const wrapperCol = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 14 }
};

class InviteRegistrationForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, values } = this.props;

        return <Fragment>
            <Field
                name='lastName'
                component={Input}
                label='Фамилия'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='firstName'
                component={Input}
                label='Имя'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='middleName'
                component={Input}
                label='Отчество'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='location'
                component={Input}
                label='Город'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='position'
                component={Input}
                label='Должность'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='email'
                component={Input}
                label='Ваш личный email'
                htmlType='email'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='phone'
                component={PhoneInput}
                label='Телефон'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='gender'
                component={RadioButtons}
                options={GENDER}
                label='Пол'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='password'
                component={Input}
                htmlType='password'
                label='Пароль'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <Field
                name='passwordRepeat'
                component={Input}
                htmlType='password'
                label='Пароль повторно'
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                labelError
            />
            <PersonalAgreementFields
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                label={<span />} />
            <Row>
                <Col span={{ xs: 24, sm: 24, md: 10 }} />
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14, offset: 10 }}>
                    <Button
                        className='btn-control'
                        type='primary'
                        htmlType='submit'
                        size='large'
                        disabled={isSubmitting || !values.personalAgreement}
                    >
                        ЗАРЕГИСТРИРОВАТЬСЯ
                    </Button>
                </Col>
            </Row>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    middleName: yup.string().required(),
    position: yup.string().required(),
    location: yup.string().required(),
    phone: yup.string().phone().required(),
    gender: yup.string().required(),
    personalAgreement: yup.string().required(),
    password: yup.string().match('passwordRepeat').min(6).required(),
    passwordRepeat: yup.string().match('password').min(6).required()
});

export default withFormWrapper(InviteRegistrationForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        ...(item || {}),
        newsletterAgreement: true
    }),
    subscriptions: { values: true }
});
