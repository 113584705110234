import { all, takeEvery, put } from 'redux-saga/effects';
import { toSuccess } from 'react-async-client';

import { getUser } from '../actions/asyncActions'
import { PATCH_PROFILE, POST_DOCUMENT, DELETE_DOCUMENT } from '../constants/actionTypes';

export default function* profile() {
    yield all([
        takeEvery([toSuccess(PATCH_PROFILE), toSuccess(POST_DOCUMENT), toSuccess(DELETE_DOCUMENT)], function* (action) {
            yield put(getUser());
        }),
    ]);
}
