import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { PresentationComponent } from '@meconsultant/common';

import { putPresentation, postPresentation, getPresentation } from '../actions/asyncActions';

class Presentation extends Component {
    render() {
        const { putPresentation, postPresentation } = this.props;

        return <PresentationComponent
            {...this.props}
            putAction={putPresentation}
            postAction={postPresentation}
            getPresentation={getPresentation} />;
    }
}

export default withAsyncActions({
    putPresentation: putPresentation
        .withOptions({ resetOnUnmount: true }),
    postPresentation: postPresentation
        .withOptions({ resetOnUnmount: true })
 })(Presentation);
