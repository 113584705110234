import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { ResumeComponent, getHHAuthLink } from '@meconsultant/common';
import { Button, Tooltip } from 'antd';

import { putResume, getResume, postResume } from '../actions/asyncActions';
import HHResumes from './HHResumes';
import HHResume from './HHResume';

class Resume extends Component {
    openHH = id => {
        window.hhLocation = window.open(getHHAuthLink({ id }), '_blank');
    }

    renderButtons = () => {
        return this.props.input.value ? null :
            <HHResumes id={this.props.id} onOpenResume={this.onOpenResume}>
                <Tooltip title='Импортировать данные с резюме HeadHunter'>
                    <Button onClick={() => this.openHH(this.props.id)}>
                        <img src='/hh.svg' alt='hh' width={18} style={{ marginRight: 7 }} />
                        <span className="hide-xs">Импортировать</span>
                    </Button>
                </Tooltip>
            </HHResumes>;
    }

    onOpenResume = (_, hhResume) => {
        this.resume.open(hhResume);
    }

    render() {
        const { putResume, postResume, input: { value }} = this.props;

        return <ResumeComponent
            {...this.props}
            getRef={node => this.resume = node}
            formAction={value ? putResume : postResume}
            getResume={getResume}
            renderButtons={this.renderButtons}
            HHWrapper={HHResume} />;
    }
}

export default withAsyncActions({
    putResume: putResume
        .withOptions({ resetOnUnmount: true }),
    postResume: postResume
        .withOptions({ resetOnUnmount: true })
 })(Resume);
