import React, { Component, Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { pathOr, path, sortBy, filter, find, propEq, isNil, any, includes } from 'ramda';
import { message, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { asyncConnect, toSuccess } from 'react-async-client';
import { getConsultations, getEvents, getProfilePath, getUser, postPromotions } from '../actions/asyncActions';
import moment from 'moment';
import { pluralize } from 'numeralize-ru';

import { openEventModal, openConsultationViewModal, openPromotionRequestModal } from '../actions/modalActions';
import { takeEvery } from 'redux-saga/effects';
import { DELETE_EVENT_PARTICIPATE, POST_PROMOTIONS, PUT_EVENT_PARTICIPATE } from '../constants/actionTypes';
import { connect } from 'react-redux';
import { coursesAllowed } from '../utils/access';
import { getOnlineIcon } from '../utils/events';
import locationLink from '../assets/img/location.svg';
import modules1 from '../assets/img/modules/1.webp';
import modules2 from '../assets/img/modules/2.webp';
import modules3 from '../assets/img/modules/3.webp';
import modules4 from '../assets/img/modules/4.webp';
import modules5 from '../assets/img/modules/5.webp';

require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/solid-gauge")(Highcharts);

export const PROMOTION_TYPES = {
    hotline: 'Горячая линия',
    promotion: 'Сопровождение на рынке труда',
    support: 'Профессиональная поддержка',
};

const ItemButton = styled(Link)`
    background: #2B3D4E;
    box-shadow: 1px 1px 5px rgba(62, 61, 61, 0.25);
    border-radius: 3px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    border: none;
    padding: 11px 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    min-width: 220px;
    text-align: center;
    &:hover {
        color: #fff;
        opacity: 0.9;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
`;

const ItemButtonWithoutLink = styled.button`
    background: #2B3D4E;
    box-shadow: 1px 1px 5px rgba(62, 61, 61, 0.25);
    border-radius: 3px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    border: none;
    padding: 11px 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    display: inline-block;
    color: #fff;
    min-width: 220px;
    text-align: center;
    &:hover {
        color: #fff;
        opacity: 0.9;
    }
    @media (max-width: 767px) {
        width: 100%;
    }
`;

const ItemWrapper = styled.div`
    background: #FFFFFF;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 16px;
    margin-bottom: 24px;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

const ColItem = styled.div`
    margin-right: 32px;
    @media (max-width: 767px) {
        margin: 0 0 12px;
    }
`;

const ItemTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #2B3D4E;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding-right: 20px;
    @media (max-width: 767px) {
        padding-right: 0;
        font-size: 20px;
        line-height: 24px;
    }
`;

const InfoIcon = styled(InfoCircleOutlined)`
    color: #2B3D4E;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.3;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 767px) {
        top: 24px;
        right: 24px;
        opacity: 0.7;
    }
`;

const ModuleItem = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 8px;
    small {
        margin-left: 12px;
        display: block;
    }
`;

const ModuleItemTitle = styled.span`
    color: #2B3D4F;
    font-weight: 700;
`;

const ModuleItemPoint = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ color }) => color};
    display: inline-block;
    margin-right: 5px;
`;

const Divider = styled.div`
    background: #A7B9CF;
    height: 1px;
    width: 100%;
    margin: 16px 0;
`;

const ManagerWrap = styled.div`
    color: #2B3D4F;
    strong {
        font-size: 16px;
        line-height: 20px;
    }
    div {
        margin: 0 0 4px;
    }
`;

const EventWrapper = styled.div`
    display: flex;
    margin-bottom: 17px;
    align-items: flex-start;
    img {
        margin-right: 5px;
    }
`;

const InfoWrapper = styled.div`
    margin-top: 24px;
    padding: 16px;
    background: #F9F8FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    font-size: 16px;
    line-height: 20px;
    strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #2B3D4F;
    }
`;

const ContactsWrap = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #828282;
`;

const TagWrap = styled.div`
    margin-top: 4px;
    display: inline-flex;
    padding: 6px 24px;
    font-weight: 300;
    font-size: 13px;
    line-height: 11px;
    color: #2B3D4E;
    border-radius: 10px;
    border: 1px solid #2B3D4E;
    border-radius: 4px;
`;

const BoxWrap = styled.div`
    padding: 30px;
    background: #F3F3F7;
    border-radius: 16px;
    width: 100%;
    @media (max-width: 1180px) {
        padding: 16px;
    }
    @media (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
    }
`;

const ImgWrap = styled.div`
    img {
        max-width: 300px;
        min-height: 180px;
        object-fit: cover;
        @media (max-width: 1100px) {
            max-width: 240px;
        }
        object-fit: cover;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
`;

const Controls = styled.div`
    display: flex;
    justify-content: end;
`;

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

const ProgressCount = styled.div`
    position: relative;
    z-index: 10;
    color: ${({ color }) => color};
`;

const WrapBody = styled.div`
    display: flex;
    @media (max-width: 1100px) {
        flex-direction: column;
    }
`;

const Side = styled.div`
    width: 30%;
    max-width: 360px;
    padding: 24px;
    @media (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
        padding: 24px 0;
    }
`;

const List = styled.div`
    position: relative;
    margin-bottom: 4px;
    @media (max-width: 767px) {
        margin-left: 12px;
    }
`;

const ProgressWrap = styled.div`
    position: relative;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 2px;
    margin-top: 10px;
    overflow: hidden;
    padding: 1px 4px;
    &:after {
        content: '';
        background: ${({ background }) => background};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: ${({ width }) => width};
        border-radius: 2px;
    }
    &:before {
        content: '';
        background: ${({ background }) => background};
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.3;
        border-radius: 2px;
    }
`;

const colors = ['#549E38', '#FFD34F', '#F54D2E'];
const moduleColors = {
    content: '#F54D2E',
    events: '#FFD34F',
    consultations: '#2B3D4E',
    promotion: '#516D88',
    tests: '#47AAC4'
};

const ORDER = ['consultations', 'events', 'content', 'promotion', 'testing'];
const CHART_ORDER = ['consultations', 'events', 'content', 'promotion'];

class Main extends Component {
    getCourseChartOptions = () => {
        const { getProfilePath } = this.props;
        const radius = [115, 90, 65];
        const innerRadius = [102, 77, 52];
        const data = sortBy(path(['_embedded', 'stage', 'order']), pathOr([], ['statistic', 'content', 'stages'], getProfilePath.data));

        return {
            chart: {
                type: 'solidgauge',
                backgroundColor: '#D4E8F1',
                marginLeft: 0,
                marginRight: 0,
                spacingLeft: 0,
                spacingRight: 0,
                width: 235,
                height: 235
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            colors,
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: data.map((_, index) => ({
                    outerRadius: `${radius[index]}%`,
                    innerRadius: `${innerRadius[index]}%`,
                    borderWidth: 0,
                    backgroundColor: '#fff'
                }))
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: data.map((item, index) => ({
                name: path(['_embedded', 'stage', 'title'], item),
                data: [{
                    color: colors[index],
                    radius: `${radius[index]}%`,
                    innerRadius: `${innerRadius[index]}%`,
                    y: item.countViewedMaterials * 100 / item.countMaterials
                }]
            }))
        };
    }

    getStagesProgress = () => {
        const { getProfilePath: { data }} = this.props;
        const stages = sortBy(path(['_embedded', 'stage', 'order']), pathOr([], ['statistic', 'content', 'stages'], data));

        return stages.length ? Math.round(stages.reduce((res, cur) => res + (cur.countViewedMaterials * 100 / cur.countMaterials), 0) / stages.length) : 0;
    }

    activatePromotions = () => {
        this.props.postPromotions.dispatch({
            type: 'promotion'
        });
    }

    getItems = () => {
        const { getProfilePath: { data, meta }, getEvents, getConsultations, user, openPromotionRequestModal } = this.props;
        const stages = sortBy(path(['_embedded', 'stage', 'order']), pathOr([], ['statistic', 'content', 'stages'], data));
        const consultationsAvailable = pathOr(0, ['statistic', 'consultations', 'availableConsultations'], data) - pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], data);
        const availableThemes = pathOr([], ['statistic', 'events', 'themes', 'available'], data).length;
        const attendedThemes = pathOr([], ['statistic', 'events', 'themes', 'attended'], data).length;
        const availableConsultations = pathOr(0, ['statistic', 'consultations', 'availableConsultations'], data);
        const attendedConsultations = pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], data);
        const promotionPastDays = pathOr(0, ['statistic', 'promotion', 'duration'], data) - pathOr(0, ['statistic', 'promotion', 'availableDays'], data);

        return {
            content: <ItemWrapper>
                <ColItem>
                    <ImgWrap>
                        <img src={modules1} alt='' />
                    </ImgWrap>
                    <ProgressWrap width={`${!meta.lastSucceedAt ? 0 : this.getStagesProgress()}%`} background="#F54D2E">
                        <ProgressCount color="#2B3D4E">{ this.getStagesProgress() }%</ProgressCount>
                    </ProgressWrap>
                </ColItem>
                <ContentWrap>
                    <div>
                        <ItemTitle>
                            <strong>Курсы</strong>
                        </ItemTitle>
                        { stages.map((stage, index) => {
                            return <List key={`stage-${stage.id}`}>
                                <div><div style={{ width: 7, height: 7, borderRadius: '50%', position: 'absolute', left: '-12px', top: 8, background: colors[index], display: 'inline-block' }} /> { coursesAllowed(user.status) ?
                                    <Link style={{ fontWeight: 700, color: '#3A3A3A', textDecoration: 'underline', fontSize: 12 }} to={`/stage/${stage.stage}`}>{ path(['_embedded', 'stage', 'title'], stage) }</Link> :
                                    <span style={{ fontWeight: 700, color: '#3A3A3A', fontSize: 12 }}>{ path(['_embedded', 'stage', 'title'], stage) }</span>
                                }
                                </div>
                                <div style={{ color: '#828282', fontSize: 10 }}>Материалы: { stage.countViewedMaterials }/{ stage.countMaterials }</div>
                            </List>;
                        })}
                    </div>
                    { coursesAllowed(user.status) &&
                        <Controls>
                            <ItemButton to='/stages'>
                                Продолжить курс
                            </ItemButton>
                        </Controls>
                    }
                </ContentWrap>
            </ItemWrapper>,
            testing: <ItemWrapper>
                <ColItem>
                    <ImgWrap>
                        <img src={modules5} alt='' />
                    </ImgWrap>
                </ColItem>
                <ContentWrap>
                    <div>
                        <ItemTitle>
                            <strong>Тестирование</strong>
                        </ItemTitle>
                    </div>
                    <Controls>
                        <ItemButton color='#BA9B66' to='/tests'>
                            Запросить
                        </ItemButton>
                    </Controls>
                </ContentWrap>
            </ItemWrapper>,
            events: <ItemWrapper>
                <ColItem>
                    <ImgWrap>
                        <img src={modules2} alt='' />
                    </ImgWrap>
                    <ProgressWrap width={`${!meta.lastSucceedAt ? 0 : attendedThemes * 100 / (availableThemes + attendedThemes)}%`} background="#FFD34F"><ProgressCount color="#2B3D4E">{ attendedThemes }/{ availableThemes + attendedThemes }</ProgressCount></ProgressWrap>
                </ColItem>
                <ContentWrap>
                    <ItemTitle>
                        <strong>Вебинары и семинары</strong>
                    </ItemTitle>
                    <div>
                        { (getEvents.data.items || []).map(item => {
                            const hours = Math.floor(item.duration / 60);
                            const minutes = item.duration - hours * 60;

                            return <EventWrapper key={item.id}>
                                { item.link ? (getOnlineIcon(item.link) ? <img src={getOnlineIcon(item.link).icon} alt='link' /> : null) : <img src={locationLink} alt='location' /> }
                                <div>
                                    <div style={{ fontWeight: 600, color: '#3A3A3A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.openEventModal(item)}>{ path(['_embedded', 'theme', 'title'], item) }</div>
                                    <div>
                                        <span style={{ color: '#828282', fontSize: 12 }}>{ moment(item.date).format('DD.MM.YYYY') } в { moment(item.date).format('HH:mm') }</span>
                                        <div style={{ fontSize: 11, color: '#fff', background: '#549E38', borderRadius: 4, padding: '0 16px', display: 'inline-block', marginLeft: 10 }}>
                                            { `${hours ? `${hours}ч ` : ''}${minutes ? `${minutes}м` : ''}` }
                                        </div>
                                    </div>
                                </div>
                            </EventWrapper>;
                        })}
                    </div>
                    <Controls>
                        <ItemButton color='#549E38' to='/webinars'>
                            Открыть календарь
                        </ItemButton>
                    </Controls>
                </ContentWrap>
                <Popover placement="bottomRight" content="Мероприятия помогут разобраться в том, что из себя представляет современный рынок труда, с чего начать поиск работы, как эффективно взаимодействовать с компаниями-работодателями и управлять карьерой">
                    <InfoIcon color='#549E38' />
                </Popover>
            </ItemWrapper>,
            consultations: <ItemWrapper>
                <ColItem>
                    <ImgWrap>
                        <img src={modules3} alt='' />
                    </ImgWrap>
                    <ProgressWrap width={`${!meta.lastSucceedAt ? 0 : attendedConsultations * 100 / availableConsultations}%`} background="#2B3D4E"><ProgressCount color="#fff">{ attendedConsultations }/{ availableConsultations }</ProgressCount></ProgressWrap>
                </ColItem>
                <ContentWrap>
                    <ItemTitle>
                        <strong>Консультации</strong>
                    </ItemTitle>
                    <div>
                        { (getConsultations.data.items || []).map(item => {
                            const duration = moment(item.end).diff(moment(item.start), 'minutes');
                            const hours = Math.floor(duration / 60);
                            const minutes = duration - hours * 60;
                            const consultant = path(['_embedded', 'consultant'], item);

                            return <div key={item.id} style={{ marginBottom: 17 }}>
                                <div style={{ fontWeight: 600, color: '#3A3A3A', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.props.openConsultationViewModal({ item })}>
                                    { `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})` }
                                </div>
                                <div>
                                    <span style={{ color: '#828282', fontSize: 12 }}>{ moment(item.start).format('DD.MM.YYYY') } в { moment(item.start).format('HH:mm') }</span>
                                    <div style={{ fontSize: 11, color: '#fff', background: '#BA9B66', borderRadius: 4, padding: '0 16px', display: 'inline-block', marginLeft: 10 }}>
                                        { `${hours ? `${hours}ч ` : ''}${minutes ? `${minutes}м` : ''}` }
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                    { consultationsAvailable > 0 && !includes(user.status, ['employed_before_program', 'employed_in_program', 'finished']) &&
                    <Controls>
                        <ItemButton color='#BA9B66' to='/webinars'>
                            Записаться
                        </ItemButton>
                    </Controls>
                    }
                </ContentWrap>
                <Popover placement="bottomRight" content="На консультациях можно получить ценные рекомендации о том, как планировать карьеру, составлять резюме и сопроводительные письма, определять приоритетные инструменты поиска работы, готовиться к собеседованиям и успешно проходить все этапы отбора">
                    <InfoIcon color='#BA9B66' />
                </Popover>
            </ItemWrapper>,
            promotion: meta.lastSucceedAt && <ItemWrapper>
                <ColItem>
                    <ImgWrap>
                        <img src={modules4} alt='' />
                    </ImgWrap>
                    <ProgressWrap width={`${(!pathOr(0, ['statistic', 'promotion', 'duration'], data) || !meta.lastSucceedAt) ? 0 : promotionPastDays * 100 / pathOr(0, ['statistic', 'promotion', 'duration'], data)}%`} background="#516D88"><ProgressCount color="#fff">{ promotionPastDays }/{ pathOr(0, ['statistic', 'promotion', 'duration'], data) }</ProgressCount></ProgressWrap>
                </ColItem>
                <ContentWrap>
                    <div>
                        <ItemTitle>
                            <strong>Профессиональная поддержка</strong>
                        </ItemTitle>
                        { path(['statistic', 'promotion'], data) && <div style={{ color: '#2B3D4F', fontWeight: 'bold', marginBottom: 10 }}>{ PROMOTION_TYPES[data.statistic.promotion.type] }</div>}
                    </div>
                    { path(['statistic', 'promotion'], data) ?
                        <div>
                            <div style={{ marginBottom: 10 }}><span style={{ color: '#828282' }}>Дата окончания сервиса:</span> { moment(data.statistic.promotion.end).format('DD.MM.YYYY') }</div>
                            { data.statistic.promotion.availableDays > 0 &&
                                <Controls>
                                    <ItemButtonWithoutLink color='#8C4484' onClick={() => openPromotionRequestModal()}>
                                        Отправить запрос
                                    </ItemButtonWithoutLink>
                                </Controls>
                            }
                        </div> :
                        <Controls>
                            <ItemButtonWithoutLink color='#8C4484' onClick={this.activatePromotions}>
                                Активировать
                            </ItemButtonWithoutLink>
                        </Controls>
                    }
                </ContentWrap>
                <Popover placement="bottomRight" content="Инструменты помогут оценить навыки, компетенции и личностные качества, выявить потенциальные зоны роста и составить план дальнейшего профессионального развития.">
                    <InfoIcon color='#8C4484' />
                </Popover>
            </ItemWrapper>
        }
    }

    getModuleProgress = () => {
        const { getProfilePath: { data }} = this.props;
        const availableThemes = pathOr([], ['statistic', 'events', 'themes', 'available'], data).length;
        const attendedThemes = pathOr([], ['statistic', 'events', 'themes', 'attended'], data).length;
        const availableConsultations = pathOr(0, ['statistic', 'consultations', 'availableConsultations'], data);
        const attendedConsultations = pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], data);
        const promotionPastDays = pathOr(0, ['statistic', 'promotion', 'duration'], data) - pathOr(0, ['statistic', 'promotion', 'availableDays'], data);

        return {
            content: <ModuleItem>
                <div><ModuleItemPoint color={moduleColors.content} /><ModuleItemTitle>Курсы</ModuleItemTitle></div>
                <span style={{ color: moduleColors.content }}>{ this.getStagesProgress() }%</span>
            </ModuleItem>,
            events: <ModuleItem>
                <div><ModuleItemPoint color={moduleColors.events} /><ModuleItemTitle>Вебинары и семинары</ModuleItemTitle></div>
                <span style={{ color: moduleColors.events }}>{ attendedThemes }/{ availableThemes + attendedThemes }</span>
            </ModuleItem>,
            consultations: <ModuleItem>
                <div><ModuleItemPoint color={moduleColors.consultations} /><ModuleItemTitle>Консультации</ModuleItemTitle></div>
                <span style={{ color: moduleColors.consultations }}>{ attendedConsultations }/{ availableConsultations }</span>
            </ModuleItem>,
            promotion: <ModuleItem>
                <div><ModuleItemPoint color={moduleColors.promotion} /><ModuleItemTitle>Профессиональная поддержка</ModuleItemTitle><small>(в днях)</small></div>
                <span style={{ color: moduleColors.promotion }}>
                    { promotionPastDays }/{ pathOr(0, ['statistic', 'promotion', 'duration'], data) }
                </span>
            </ModuleItem>,
            // testing: <ModuleItem>
            //     <div><ModuleItemPoint color={moduleColors.tests} /><ModuleItemTitle>Тестирование</ModuleItemTitle></div>
            //     <span style={{ color: moduleColors.tests }}>1/3</span>
            // </ModuleItem>
        };
    }

    getRemainingDays = () => {
        const { user: { expiredAt }} = this.props;
        const daysDiff = moment(expiredAt).diff(moment(), 'days');
        const hoursDiff = moment(expiredAt).diff(moment(), 'hours');
        const minutesDiff = moment(expiredAt).diff(moment(), 'minutes');

        if (daysDiff) {
            return `${daysDiff} ${pluralize(daysDiff, 'день', 'дня', 'дней')}`;
        } else if (hoursDiff) {
            return `${hoursDiff} ${pluralize(hoursDiff, 'час', 'часа', 'часов')}`;
        } else {
            return `${minutesDiff} ${pluralize(minutesDiff, 'минута', 'минуты', 'минут')}`;
        }
    }

    getCommonProgress = () => {
        const { getProfilePath: { data }, user } = this.props;
        const availableThemes = pathOr([], ['statistic', 'events', 'themes', 'available'], data).length;
        const attendedThemes = pathOr([], ['statistic', 'events', 'themes', 'attended'], data).length;
        const availableConsultations = pathOr(0, ['statistic', 'consultations', 'availableConsultations'], data);
        const attendedConsultations = pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], data);
        const stagesProgress = this.getStagesProgress();
        const themesProgress = availableThemes + attendedThemes === 0 ? 0 : attendedThemes * 100 / (availableThemes + attendedThemes);
        const consultationsProgress = attendedConsultations + availableConsultations === 0 ? 0 : attendedConsultations * 100 / (attendedConsultations + availableConsultations);
        const allPromotionDays = pathOr(0, ['statistic', 'promotion', 'duration'], data);
        const promotionPastDays = allPromotionDays - pathOr(0, ['statistic', 'promotion', 'availableDays'], data);
        const promotionProgress = promotionPastDays === 0 ? 0 : promotionPastDays * 100 / allPromotionDays;

        const progresses = {
            content: stagesProgress,
            events: themesProgress,
            consultations: consultationsProgress > 100 ? 100 : consultationsProgress,
            promotion: promotionProgress
        };

        const modules = filter(i => !isNil(i), (user.modules || []).map(({ id }) => progresses[id]));

        return Math.round(modules.reduce((cur, res) => cur + res, 0) / modules.length);
    }

    getCommonProgressChartOptions = () => {
        const { getProfilePath: { data }, user } = this.props;
        const availableThemes = pathOr([], ['statistic', 'events', 'themes', 'available'], data).length;
        const attendedThemes = pathOr([], ['statistic', 'events', 'themes', 'attended'], data).length;
        const availableConsultations = pathOr(0, ['statistic', 'consultations', 'availableConsultations'], data);
        const attendedConsultations = pathOr(0, ['statistic', 'consultations', 'attendedConsultations'], data);
        const stagesProgress = this.getStagesProgress();
        const themesProgress = availableThemes + attendedThemes === 0 ? 0 : attendedThemes * 100 / (availableThemes + attendedThemes);
        let consultationsProgress = attendedConsultations + availableConsultations === 0 ? 0 : attendedConsultations * 100 / (attendedConsultations + availableConsultations);
        consultationsProgress = consultationsProgress > 100 ? 100 : consultationsProgress;
        const allPromotionDays = pathOr(0, ['statistic', 'promotion', 'duration'], data)
        const promotionPastDays = allPromotionDays - pathOr(0, ['statistic', 'promotion', 'availableDays'], data);
        const promotionProgress = promotionPastDays === 0 ? 0 : promotionPastDays * 100 / allPromotionDays;
        const progresses = {
            content: stagesProgress,
            events: themesProgress,
            consultations: consultationsProgress > 100 ? 100 : consultationsProgress,
            promotion: promotionProgress
        };
        let modules = (user.modules || []).map(({ id }) => ({
            id,
            y: progresses[id],
            color: moduleColors[id]
        }));
        modules = filter(id => any(propEq('id', id), modules), CHART_ORDER).map(id => find(propEq('id', id), modules));

        const paneRadius = [
            { outerRadius: '116%', innerRadius: '105%' },
            { outerRadius: '98%', innerRadius: '88%' },
            { outerRadius: '80%', innerRadius: '71%' },
            { outerRadius: '63%', innerRadius: '54%' },
            { outerRadius: '44%', innerRadius: '36%' },
        ]

        return modules && modules.length ? {
            chart: {
                type: 'solidgauge',
                width: 310,
                height: 310
            },
            title: null,
            tooltip: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            pane: {
                startAngle: 0,
                endAngle: 360,
                background: modules.map((item, index) => ({
                    outerRadius: paneRadius[index].outerRadius,
                    innerRadius: paneRadius[index].innerRadius,
                    backgroundColor: Highcharts.color(item.color)
                        .setOpacity(0.1)
                        .get(),
                    borderWidth: 0
                })),
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: modules.map((item, index) => ({
                data: [{
                    ...item,
                    radius: paneRadius[index].outerRadius,
                    innerRadius: paneRadius[index].innerRadius,
                }]
            })),
        } : null;
    }

    render() {
        const { user, getProfilePath } = this.props;
        const modules = filter(id => any(propEq('id', id), user.modules || []), ORDER).map(id => find(propEq('id', id), user.modules || []));
        const consultant = path(['_embedded', 'consultant'], getProfilePath.data);
        const progress = this.getCommonProgress();
        const options = this.getCommonProgressChartOptions();

        return <WrapBody>
            <Side>
                <div style={{ fontSize: 32, color: '#2B3D4F' }}>Привет, <strong>{ user.firstName }</strong>!</div>
                <div>
                    {options && (
                        <div style={{ position: 'relative', height: 300, overflow: 'hidden', marginBottom: 30, marginTop: 25, display: 'flex', justifyContent: 'center' }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                            <div style={{ textAlign: 'center', lineHeight: 1.2, position: 'absolute', top: 'calc(50% - 16px)', left: 0, right: 0, color: '#2B3D4F' }}>
                                <div style={{ fontWeight: 700, fontSize: 28 }}>{ progress ? `${progress}%`: '' }</div>
                            </div>
                        </div>
                    )}
                    { modules.map(item => <Fragment key={item.id}>{this.getModuleProgress()[item.id]}</Fragment>) }
                </div>
                { (!!user.expiredAt || consultant) &&
                    <InfoWrapper>
                        { !!user.expiredAt &&
                            <Fragment>
                                <strong>Осталось { this.getRemainingDays() }</strong>
                                <div>Дата окончания доступа: { moment(user.expiredAt).format('DD.MM.YYYY') }</div>
                                <Divider />
                            </Fragment>
                        }
                        { consultant &&
                            <Fragment>
                                <ManagerWrap>
                                    <div><strong>{ consultant.lastName || '' } { consultant.firstName || '' } { consultant.middleName || '' }</strong></div>
                                    <div><TagWrap>персональный консультант</TagWrap></div>
                                    <ContactsWrap>
                                        { consultant.phone && <div>{ consultant.phone }</div>}
                                        <div>{ consultant.email }</div>
                                    </ContactsWrap>
                                </ManagerWrap>
                            </Fragment>
                        }
                    </InfoWrapper>
                }
            </Side>
            <BoxWrap>
                { modules.map(item => <Fragment key={item.id}>{this.getItems()[item.id]}</Fragment>) }
            </BoxWrap>
        </WrapBody>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default connect(stateToProps)(asyncConnect(({ user }) => ({
    getProfilePath: getProfilePath
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(POST_PROMOTIONS)], () => {
                getProps().getProfilePath.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    getConsultations: getConsultations
        .withPayload(() => ({
            q: {
                minDate: new Date(),
            },
            limit: 0,
            sort_by: { start: 'asc' }
        }))
        .withOptions({ dispatchOnMount: !!find(propEq('id', 'consultations'), user.modules || []), resetOnUnmount: true }),
    getEvents: getEvents
        .withPayload(() => ({
            limit: 0,
            q: {
                minDate: new Date(),
                attended: true
            },
            sort_by: { date: 'asc' }
        }))
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_EVENT_PARTICIPATE), toSuccess(DELETE_EVENT_PARTICIPATE)], () => {
                getProps().getEvents.refresh();
            });
        })
        .withOptions({ dispatchOnMount: !!find(propEq('id', 'events'), user.modules || []), resetOnUnmount: true }),
    postPromotions: postPromotions
        .withSuccessHandler(() => message.success('Сервис "Профессиональная поддержка" успешно активирован'))
        .withErrorHandler(() => message.error('Не удалось активировать сервис "Профессиональная поддержка"'))
        .withOptions({ resetOnUnmount: true })
}), null, { openEventModal, openConsultationViewModal, openPromotionRequestModal })(Main));
