import React, { Component, Fragment } from 'react';
import { asyncConnect, toSuccess } from 'react-async-client';
import { Table, message, Button, Tooltip } from 'antd';
import { pathOr } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getCoverLetterText } from '@meconsultant/common';
import { PlusOutlined, EyeOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';

import { getLetters } from '../../actions/asyncActions';
import { openCoverLetterModal, openCoverLetterViewModal } from '../../actions/modalActions';
import { PUT_LETTER, POST_LETTER } from '../../constants/actionTypes';

class CoverLetters extends Component {
    render() {
        const { getLetters, openCoverLetterModal, openCoverLetterViewModal } = this.props;

        return <Fragment>
            <div style={{ textAlign: 'right', padding: 15, paddingTop: 0, borderBottom: '1px solid #e8e8e8' }}>
                <Button icon={<PlusOutlined />} type='primary' onClick={() => openCoverLetterModal()}>
                    Добавить
                </Button>
            </div>
            <Table
                dataSource={pathOr([], ['data', 'items'], getLetters)}
                pagination={false}
                rowKey='id'
                columns={[{
                    title: 'Название',
                    key: 'name',
                    dataIndex: 'name'
                }, {
                    key: 'actions',
                    align: 'right',
                    render: item =>
                        <Button.Group>
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => openCoverLetterViewModal(item)} />
                            <CopyToClipboard text={getCoverLetterText(item)} onCopy={() => message.success('Сопроводительное письмо успешно скопировано')}>
                                <Tooltip title='Скопировать'>
                                    <Button icon={<CopyOutlined />} />
                                </Tooltip>
                            </CopyToClipboard>
                            <Button
                                type='primary'
                                icon={<EditOutlined />}
                                onClick={() => openCoverLetterModal(item)} />
                        </Button.Group>
                }]} />
        </Fragment>;
    }
}

export default asyncConnect({
    getLetters: getLetters
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_LETTER), toSuccess(POST_LETTER)], function() {
                getProps().getLetters.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openCoverLetterModal, openCoverLetterViewModal })(CoverLetters);
