import React, { Component } from 'react';
import { Modal, message } from 'antd';
import { asyncConnect } from 'react-async-client';
import { PersonalityAnalysisForm } from '@meconsultant/common';

import { putProfilePath, getUser } from '../../actions/asyncActions';

class PersonalityModal extends Component {
    render() {
        const { modal, params, putProfilePath, gender } = this.props;

        return <Modal
            {...modal}
            title='Анализ личностных качеств'
            footer={null}>
            <PersonalityAnalysisForm
                formAction={putProfilePath}
                data={params || {}}
                gender={gender} />
        </Modal>;
    }
}

const stateToProps = state => ({
    gender: getUser.selectData(state).gender
});

export default asyncConnect({
    putProfilePath: putProfilePath
        .withSuccessHandler(({ close }) => {
            message.success('Анализ личностных качеств успешно сохранен');
            close();
        })
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(PersonalityModal);
