import createAsyncAction from '../utils/createAsyncAction';
import * as actions from '../constants/actionTypes';
import * as handlers from './handlers';
import * as hhHandlers from './handlers/hhHandlers';

import { takeEvery } from 'redux-saga/effects';

export const postLogin = createAsyncAction(actions.POST_LOGIN, handlers.postLoginHandler);
export const postRecovery = createAsyncAction(actions.POST_RECOVERY, handlers.postRecoveryHandler);
export const postReset = createAsyncAction(actions.POST_RESET, handlers.postResetHandler);
export const putPassword = createAsyncAction(actions.PUT_PASSWORD, handlers.putPasswordHandler);
export const getRegistrationPassword = createAsyncAction(actions.GET_REGISTRATION_PASSWORD, handlers.getRegistrationPassword);
export const postRegistrationPassword = createAsyncAction(actions.POST_REGISTRATION_PASSWORD, handlers.postRegistrationPassword);
export const getAgreementStatus = createAsyncAction(actions.GET_AGREEMENT_STATUS, handlers.getAgreementStatusHandler);

export const getUser = createAsyncAction(actions.GET_USER, handlers.getUserHandler);

export const patchProfile = createAsyncAction(actions.PATCH_PROFILE, handlers.patchProfileHandler);

export const postDocument = createAsyncAction(actions.POST_DOCUMENT, handlers.postDocumentHandler);
export const deleteDocument = createAsyncAction(actions.DELETE_DOCUMENT, handlers.deleteDocumentHandler);

export const getStages = createAsyncAction(actions.GET_STAGES, handlers.getStagesHandler);
export const getStage = createAsyncAction(actions.GET_STAGE, handlers.getStageHandler);

export const putMaterialView = createAsyncAction(actions.PUT_MATERIAL_VIEW, handlers.putMaterialViewHandler, {}, takeEvery);
export const getMaterial = createAsyncAction(actions.GET_MATERIAL, handlers.getMaterialHandler);
export const putMaterialResult = createAsyncAction(actions.PUT_MATERIAL_RESULT, handlers.putMaterialResultHandler);

export const putMaterialCheckListResult = createAsyncAction(actions.PUT_CHECK_LIST, handlers.putMaterialCheckListResultHandler);

export const getFAQ = createAsyncAction(actions.GET_FAQ, handlers.getFAQHandler);

export const getResumes = createAsyncAction(actions.GET_RESUMES, handlers.getResumesHandler);
export const getResume = createAsyncAction(actions.GET_RESUME, handlers.getResumeHandler, {}, takeEvery);
export const putResume = createAsyncAction(actions.PUT_RESUME, handlers.putResumeHandler);
export const postResume = createAsyncAction(actions.POST_RESUME, handlers.postResumeHandler);

export const postRegistration = createAsyncAction(actions.POST_REGISTRATION, handlers.postRegistration);
export const postParticipationRequest = createAsyncAction(actions.POST_PARTICIPATION_REQUEST, handlers.postParticipationRequest);

export const getLetters = createAsyncAction(actions.GET_LETTERS, handlers.getLettersHandler);
export const getLetter = createAsyncAction(actions.GET_LETTER, handlers.getLetterHandler, {}, takeEvery);
export const postLetter = createAsyncAction(actions.POST_LETTER, handlers.postLetterHandler);
export const putLetter = createAsyncAction(actions.PUT_LETTER, handlers.putLetterHandler);
export const deleteLetter = createAsyncAction(actions.DELETE_LETTER, handlers.deleteLetterHandler);

export const getSettings = createAsyncAction(actions.GET_SETTINGS, handlers.getSettingsHandler);

export const getProfilePath = createAsyncAction(actions.GET_PROFILE_PATH, handlers.getProfilePathHandler, {}, takeEvery);
export const putProfilePath = createAsyncAction(actions.PUT_PROFILE_PATH, handlers.putProfilePathHandler);

export const postSendResume = createAsyncAction(actions.POST_SEND_RESUME, handlers.postSendResumeHandler);

export const postHHToken = createAsyncAction(actions.POST_HH_TOKEN, handlers.postHHTokenHandler);

export const getHHResumes = createAsyncAction(actions.GET_HH_RESUMES, hhHandlers.getHHResumesHandler);
export const getHHResume = createAsyncAction(actions.GET_HH_RESUME, hhHandlers.getHHResumeHandler);

export const getPresentations = createAsyncAction(actions.GET_PRESENTATIONS, handlers.getPresentationsHandler);
export const getPresentation = createAsyncAction(actions.GET_PRESENTATION, handlers.getPresentationHandler, {}, takeEvery);
export const postPresentation = createAsyncAction(actions.POST_PRESENTATION, handlers.postPresentationHandler);
export const putPresentation = createAsyncAction(actions.PUT_PRESENTATION, handlers.putPresentationHandler);
export const deletePresentation = createAsyncAction(actions.DELETE_PRESENTATION, handlers.deletePresentationHandler);

export const getRegistrationCheck = createAsyncAction(actions.GET_REGISTRATION_CHECK, handlers.getRegistrationCheckHandler);

export const getTests = createAsyncAction(actions.GET_TESTS, handlers.getTestsHandlers, {}, takeEvery);
export const getTest = createAsyncAction(actions.GET_TEST, handlers.getTestHandler);
export const putTestParticipate = createAsyncAction(actions.PUT_TEST_PARTICIPATE, handlers.putTestParticipateHandler);

export const getEvents = createAsyncAction(actions.GET_EVENTS, handlers.getEventsHandlers, {}, takeEvery);
export const getEvent = createAsyncAction(actions.GET_EVENT, handlers.getEventHandler);
export const getSpeakers = createAsyncAction(actions.GET_SPEAKERS, handlers.getSpeakersHandler);
export const putEventParticipate = createAsyncAction(actions.PUT_EVENT_PARTICIPATE, handlers.putEventParticipateHandler);
export const deleteEventParticipate = createAsyncAction(actions.DELETE_EVENT_PARTICIPATE, handlers.deleteEventParticipateHandler);

export const getConsultations = createAsyncAction(actions.GET_CONSULTATIONS, handlers.getConsultationsHandler, {}, takeEvery);
export const getConsultation = createAsyncAction(actions.GET_CONSULTATION, handlers.getConsultationHandler);
export const postPromotions = createAsyncAction(actions.POST_PROMOTIONS, handlers.postPromotionsHandler);

export const getPromotionRequests = createAsyncAction(actions.GET_PROMOTION_REQUESTS, handlers.getPromotionRequestsHandler);
export const getPromotionRequest = createAsyncAction(actions.GET_PROMOTION_REQUEST, handlers.getPromotionRequestHandler);
export const getPromotionRequestsThemes = createAsyncAction(actions.GET_PROMOTION_REQUESTS_THEMES, handlers.getPromotionRequestsThemesHandler, []);
export const postPromotionRequests = createAsyncAction(actions.POST_PROMOTION_REQUESTS, handlers.postPromotionRequestsHandler);

export const postFeedbackEvent = createAsyncAction(actions.POST_FEEDBACK_EVENT, handlers.postFeedbackEventHandler);
export const postFeedbackConsultation = createAsyncAction(actions.POST_FEEDBACK_CONSULTATION, handlers.postFeedbackConsultationHandler);
export const getEventHasFeedback = createAsyncAction(actions.GET_EVENT_HAS_FEEDBACK, handlers.getEventHasFeedbackHandler);
export const getConsultationHasFeedback = createAsyncAction(actions.GET_CONSULTATION_HAS_FEEDBACK, handlers.getConsultationHasFeedbackHandler);

export const getEmployeeList = createAsyncAction(actions.GET_EMPLOYEE_LIST, handlers.getEmployeeListHandler);
export const postEmployeeList = createAsyncAction(actions.POST_EMPLOYEE_LIST, handlers.postEmployeeListHandler);

export const getParticipationRequestRole = createAsyncAction(actions.GET_PARTICIPATION_REQUEST_ROLE, handlers.getParticipationRequestRoleHandler);
export const postParticipationRequestRole = createAsyncAction(actions.POST_PARTICIPATION_REQUEST_ROLE, handlers.postParticipationRequestRoleHandler);
