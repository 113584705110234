import React from 'react';
import { Switch } from 'react-router';

import NoMatch from './NoMatch';
import Welcome from './Welcome';
import Route from './Route';

const UserWelcomeRoutes = () => (
    <Switch>
        <Route path='/' exact component={Welcome} />

        <Route component={NoMatch} />
    </Switch>
);

export default UserWelcomeRoutes;
