import { append, remove, assocPath } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
    CLOSE_MODAL,
    HIDE_MODAL,
    SHOW_MODAL,
    LOGOUT,
    OPEN_VIDEO_MODAL,
    OPEN_PASSWORD_MODAL,
    OPEN_COVER_LETTER_MODAL,
    OPEN_RESUME_MODAL,
    OPEN_RESUME_VIEW_MODAL,
    OPEN_SEND_RESUME_MODAL,
    OPEN_HH_RESUMES_MODAL,
    OPEN_MOTIVATION_MODAL,
    OPEN_PERSONALITY_MODAL,
    OPEN_CRITERIA_MODAL,
    OPEN_PRESENTATION_MODAL,
    OPEN_PRESENTATION_VIEW_MODAL,
    OPEN_COVER_LETTER_VIEW_MODAL,
    OPEN_EVENT_MODAL,
    OPEN_CONSULTATION_MODAL,
    OPEN_CONSULTATION_VIEW_MODAL,
    OPEN_CANCEL_CONSULTATION_MODAL,
    OPEN_PROMOTION_REQUEST_MODAL,
    OPEN_TEST_REQUEST_MODAL,
    OPEN_DOCUMENT_MODAL,
} from '../constants/actionTypes';

export default (state = [], action) => {
    switch (action.type) {
        case OPEN_DOCUMENT_MODAL:
        case OPEN_VIDEO_MODAL:
        case OPEN_PASSWORD_MODAL:
        case OPEN_COVER_LETTER_MODAL:
        case OPEN_RESUME_MODAL:
        case OPEN_RESUME_VIEW_MODAL:
        case OPEN_SEND_RESUME_MODAL:
        case OPEN_HH_RESUMES_MODAL:
        case OPEN_MOTIVATION_MODAL:
        case OPEN_PERSONALITY_MODAL:
        case OPEN_CRITERIA_MODAL:
        case OPEN_PRESENTATION_MODAL:
        case OPEN_PRESENTATION_VIEW_MODAL:
        case OPEN_COVER_LETTER_VIEW_MODAL:
        case OPEN_EVENT_MODAL:
        case OPEN_CONSULTATION_MODAL:
        case OPEN_CONSULTATION_VIEW_MODAL:
        case OPEN_CANCEL_CONSULTATION_MODAL:
        case OPEN_TEST_REQUEST_MODAL:
        case OPEN_PROMOTION_REQUEST_MODAL: {
            const modal = {
                type: action.type,
                params: action.payload,
                visible: true
            };

            return append(modal, state);
        }
        case CLOSE_MODAL:
            return remove(action.payload, 1, state);
        case HIDE_MODAL:
            return assocPath([action.payload, 'visible'], false, state);
        case SHOW_MODAL:
            return assocPath([action.payload, 'visible'], true, state);
        case LOGOUT:
        case LOCATION_CHANGE:
            return [];
        default:
            return state;
    }
};
