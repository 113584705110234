import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import * as yup from 'yup';
import { Button } from 'antd';
import styled from 'styled-components';

import { withFormWrapper } from '@meconsultant/common';
import Input from './formComponents/Input';
import { PhoneInput } from './formComponents/MaskedInput';
import Checkbox from './formComponents/Checkbox';

const ButtonWrapper = styled.div`
  text-align: right;
`;

const ButtonBox = styled(Button)`
  border-radius: 0;
  box-shadow: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 8px;
`;

class EmployeeListForm extends Component {
    static propTypes = {
        isSubmitting: PropTypes.bool
    };

    render() {
        const { isSubmitting, formItemLayout } = this.props;

        return <Fragment>
            <Field
                name='name'
                component={Input}
                {...formItemLayout}
                label='ФИО' />
            <Field
                name='company'
                component={Input}
                {...formItemLayout}
                label='Компания' />
            <Field
                name='position'
                component={Input}
                {...formItemLayout}
                label='Должность' />
            <Field
                name='email'
                component={Input}
                type='email'
                {...formItemLayout}
                label='E-mail' />
            <Field
                name='phone'
                component={PhoneInput}
                {...formItemLayout}
                label='Телефон' />
            <Field
                name='personalDataAgreement'
                component={Checkbox}
                text='Даю свое согласие на обработку персональных данных' />
            <ButtonWrapper>
                <ButtonBox
                    disabled={isSubmitting}
                    onClick={this.props.onCancel}
                >
                    Отмена
                </ButtonBox>
                <ButtonBox
                    type='primary'
                    htmlType='submit'
                    disabled={isSubmitting}
                >
                    Отправить
                </ButtonBox>
            </ButtonWrapper>
        </Fragment>;
    }
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    company: yup.string().required(),
    position: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    personalDataAgreement: yup.bool().nullable().oneOf([true], 'Обязательное поле').required(),
});

export default withFormWrapper(EmployeeListForm, {
    validationSchema,
    mapPropsToValues: ({ item }) => ({
        ...(item || {}),
    }),
});
