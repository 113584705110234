import React, { Component } from 'react';
import { toSuccess, asyncConnect } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { Button, Spin, Row, Col } from 'antd';
import { generateMotivationPdf } from '@meconsultant/common';
import { path, pathOr } from 'ramda';
import styled from "styled-components";
import { FilePdfOutlined, EditOutlined } from '@ant-design/icons';

import { getProfilePath } from '../../actions/asyncActions';
import { openMotivationModal } from '../../actions/modalActions';
import { PUT_PROFILE_PATH } from '../../constants/actionTypes';
import { MOTIVATION_ANALISYS_FIELDS } from '@meconsultant/common/lib/constants/motivationAnalysis';

const Label = styled.div`

`;

const SubLabel = styled.div`
    font-weight: 300;
`;

class Motivation extends Component {
    renderData = () => {
        const { getProfilePath, getSettings } = this.props;
        const scale = pathOr([], ['data', 'motivation', 'scale'], getSettings);

        return MOTIVATION_ANALISYS_FIELDS.map(item =>
            <Row key={item.name} gutter={16} style={{ marginBottom: 15 }}>
                <Col span={18}>
                    <div>
                        <Label>{ item.label }</Label>
                    </div>
                    <SubLabel>{ item.subLabel }</SubLabel>
                </Col>
                <Col span={6}>
                    { scale[path(['data', item.name], getProfilePath.data)] || '-' }
                </Col>
            </Row>
        );
    }

    render() {
        const { getProfilePath: { meta, data }, openMotivationModal, getSettings } = this.props;

        return <div>
            <div style={{ textAlign: 'right', marginBottom: 15, paddingRight: 15 }}>
                <Button.Group>
                    <Button
                        icon={<FilePdfOutlined />}
                        disabled={meta.pending && !meta.lastSucceedAt}
                        onClick={() => generateMotivationPdf(path(['data'], data), pathOr([], ['data', 'motivation', 'scale'], getSettings))}>
                        Скачать
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        type='primary'
                        disabled={meta.pending && !meta.lastSucceedAt}
                        onClick={() => openMotivationModal(data)}>
                        Редактировать
                    </Button>
                </Button.Group>
                <a href='link' ref={node => this.pdfLink = node} download style={{ display: 'none' }}>#</a>
            </div>
            { ((meta.pending && !meta.lastSucceedAt) || (getSettings.meta.pending && !getSettings.meta.lastSucceedAt)) ?
                <Spin /> :
                this.renderData()
            }
        </div>;
    }
}

export default asyncConnect({
    getProfilePath: getProfilePath
        .withPayload(() => 'motivation')
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_PROFILE_PATH)], () => {
                getProps().getProfilePath.refresh();
            })
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true, dispatchOnUpdate: true })
}, null, { openMotivationModal })(Motivation);
