import React, { Component } from 'react';
import { Modal, Row, Col } from 'antd';
import { PRESENTATION_DATA } from '@meconsultant/common';
import styled from 'styled-components';

const ItemRow = styled(Row)`
    margin-bottom: 8px;
`;

const TitleCol = styled(Col)`
    color: #848484;
`;

const ValueCol = styled(Col)`
    color: #333;
`;

export default class PresentationViewModal extends Component {
    render() {
        const { params, modal } = this.props;

        return <Modal
            {...modal}
            title='Самопрезентация'
            footer={null}>
            <ItemRow gutter={16}>
                <TitleCol span={8}>
                    Название:
                </TitleCol>
                <Col span={16}>
                    { params.name }
                </Col>
            </ItemRow>
            { PRESENTATION_DATA.map(item =>
                (params.content || {})[item.field] ?
                    <ItemRow key={`view-${item.field}`} gutter={16}>
                        <TitleCol span={8}>
                            { item.label }:
                        </TitleCol>
                        <ValueCol span={16}>
                            { params.content[item.field] }
                        </ValueCol>
                    </ItemRow> : null
            )}
        </Modal>;
    }
}
