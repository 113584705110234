import { take, put } from 'redux-saga/effects';
import { toSuccess, toError } from 'react-async-client';

import { POST_LOGIN, GET_USER, LOGOUT, POST_RESET, POST_REGISTRATION, POST_REGISTRATION_PASSWORD } from '../constants/actionTypes';
import { getUser } from '../actions/asyncActions';
import { handleTokenChange } from '../utils/token';

export default function* () {
    while(true) {
        const action = yield take([
            toSuccess(POST_LOGIN),
            toSuccess(POST_RESET),
            toSuccess(POST_REGISTRATION),
            toSuccess(POST_REGISTRATION_PASSWORD)
        ]);
        handleTokenChange(action.payload.token, action.payload.refresh_token);
        yield put(getUser());

        yield take([LOGOUT, toError(GET_USER)]);
        yield put(getUser.reset());
        handleTokenChange(null);

        if (window.carrotquest) {
            window.location.reload();
        }
    }
}
