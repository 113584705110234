import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withAsyncActions } from 'react-async-client';
import { message } from 'antd';
import { pathEq } from 'ramda';

import { postRecovery } from '../actions/asyncActions';
import RecoveryForm from './forms/RecoveryForm';

class Recovery extends Component {
    static propTypes = {
        postRecovery: PropTypes.object
    };

    render() {
        return <Fragment>
            <RecoveryForm formAction={this.props.postRecovery} />
            <Link to='/'>
                Войти
            </Link>
        </Fragment>;
    }
}

export default withAsyncActions({
    postRecovery: postRecovery
        .withSuccessHandler(({ history }) => {
            message.success('Ссылка на восстановление пароля была успешно отправлена');
            history.push('/');
        })
        .withErrorHandler(({ postRecovery: { meta }}) =>
            message.error(
                pathEq(['error', 'data', 'errors', 'email', 0, 'message'], 'User with that email not found.', meta) ?
                'Пользователь с данным email не существует' :
                'Не удалось отправить ссылку на восстановление пароля'
            )
        )
        .withOptions({ resetOnUnmount: true })
})(Recovery);
