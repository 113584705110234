import React, { Component } from 'react';
import { withAsyncActions } from 'react-async-client';
import { Alert, message } from 'antd';
import { path } from 'ramda';

import { getParticipationRequestRole, postParticipationRequest, postParticipationRequestRole } from '../actions/asyncActions';
import ParticipationRequestForm from './forms/ParticipationRequestForm';

class ParticipationRequest extends Component {
    render() {
        const { formAction, match: { params: { company, role } } } = this.props;

        return (
            <div className='invite-registration-wrapper'>
                <div className='header'>
                    <div className="container">
                        <svg width="156" height="30" viewBox="0 0 156 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M145.6 0.529411L140.746 7.5V0.529411H131.994V29.3824H140.746V10.8529H148.72L156 0.529411H145.6ZM112.147 21.7058C108.42 21.7058 105.733 18.8824 105.733 15C105.733 11.1177 108.507 8.20589 112.147 8.20589C115.787 8.20589 118.56 11.1177 118.56 15C118.646 18.8824 115.873 21.7058 112.147 21.7058ZM112.147 0C103.653 0 96.98 6.61765 96.98 15C96.98 23.3824 103.653 30 112.147 30C120.64 30 127.314 23.3824 127.314 15C127.4 6.61765 120.726 0 112.147 0ZM84.6733 21.7058C80.6866 21.7058 77.9134 18.9706 77.9134 15C77.9134 11.1177 80.7734 8.20589 84.5 8.20589C86.4066 8.20589 88.3134 9 89.7 10.4118L94.4666 3.61765C91.78 1.32353 88.2266 0 84.5 0C75.92 0 69.16 6.61765 69.16 15C69.16 23.5589 75.7466 30 84.5 30C88.66 30 92.1266 28.7647 94.9866 26.2058L90.22 19.3235C88.92 20.5589 87.0134 21.7058 84.6733 21.7058ZM53.3 0C50.7866 0 46.6266 1.23529 45.1534 3.08824V0.529411H36.66V29.3824H45.4134V11.1177C46.3666 9.88235 48.62 8.20589 51.3066 8.20589C52.78 8.20589 54.08 8.64706 54.86 9.52942C55.5534 10.2353 56.2466 11.2941 56.2466 14.1176V29.3824H65V10.8529C65 3.61765 59.8 0 53.3 0ZM15.1666 21.7058C11.44 21.7058 8.75333 18.8824 8.75333 15C8.75333 11.1177 11.5267 8.20589 15.1666 8.20589C18.8066 8.20589 21.58 11.1177 21.58 15C21.58 18.8824 18.8934 21.7058 15.1666 21.7058ZM21.2334 3.08824C19.4134 0.882353 16.7266 0 14.04 0C6.15334 0 0 6.61765 0 15C0 18.7942 1.3 22.3235 3.64 25.0589C6.24 28.1471 10.3133 29.9118 14.4734 29.9118C16.9866 29.9118 19.8466 28.8529 21.32 26.9118V29.3824H29.8134V0.529411H21.32V3.08824H21.2334Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="body">
                    <div className="container">
                        <div className="wrap">
                            <h1>
                                <svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.2137 0H34.4828L20.2691 20H0L14.2137 0Z" fill="#F54D2E"/>
                                </svg>
                                Регистрация
                            </h1>
                            { path(['data', 'errors', 'code'], formAction.meta.error) &&
                                <Alert style={{ marginBottom: 15 }} type='error' message={formAction.meta.error.data.errors.code[0].message} />
                            }
                            <div className="alert-wrap">
                                { company ? company === 'success' ? (
                                    <Alert showIcon type='success' message="Мы отправили вашу заявку." description="Ожидайте подверждение на свой почтовый ящик." />
                                ) : (
                                    <ParticipationRequestForm
                                        formAction={formAction}
                                        item={{
                                            company,
                                            role
                                        }}
                                    />
                                ) : (
                                    <Alert type='error' message='Некорректная ссылка' />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAsyncActions(props => ({
    getParticipationRequestRole: getParticipationRequestRole
        .withPayload(({ match: { params: { company, role }}}) => ({ company, role }))
        .withSuccessHandler(({ getParticipationRequestRole, history, match }) => {
            if (!getParticipationRequestRole.data.fastRegistrationAvailable) {
                history.replace(`/registration/${match.params.company}`);
            }
        })
        .withOptions({ dispatchOnMount: !!props.match.params.role, resetOnUnmount: true }),
    formAction: (props.match.params.role ? postParticipationRequestRole : postParticipationRequest)
        .withSuccessHandler(({ history, formAction, match }) => {
            message.success('Заявка отправлена');
            history.push(formAction.data.publicId && match.params.role ? `/password/${formAction.data.publicId}` : '/registration/success');
        })
        .withErrorHandler(() => message.error('Ошибка регистрации'))
}))(ParticipationRequest);
