import React, { Component } from 'react';
import { toSuccess, asyncConnect } from 'react-async-client';
import { takeEvery } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import { Table, Button, Popconfirm, message } from 'antd';
import { generatePresentationPdf } from '@meconsultant/common';
import { PlusOutlined, EyeOutlined, DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { getPresentations, deletePresentation } from '../../actions/asyncActions';
import { PUT_PRESENTATION, POST_PRESENTATION, DELETE_PRESENTATION } from '../../constants/actionTypes';
import { openPresentationViewModal, openPresentationModal } from '../../actions/modalActions';

class Presentations extends Component {
    render() {
        const { getPresentations, openPresentationModal, openPresentationViewModal } = this.props;

        return <div>
            <div style={{ textAlign: 'right', padding: 15, borderBottom: '1px solid #e8e8e8', paddingTop: 0 }}>
                <Button icon={<PlusOutlined />} type='primary' onClick={() => openPresentationModal()}>
                    Добавить
                </Button>
            </div>
            <Table
                dataSource={pathOr([], ['data', 'items'], getPresentations)}
                pagination={false}
                showHeader={false}
                rowKey='id'
                columns={[
                    {
                        key: 'name',
                        dataIndex: 'name'
                    },
                    {
                        key: 'actions',
                        align: 'right',
                        render: item =>
                            <Button.Group>
                                <Button
                                    icon={<EyeOutlined />}
                                    onClick={() => openPresentationViewModal(item)} />
                                <Button
                                    icon={<DownloadOutlined />}
                                    onClick={() => generatePresentationPdf(item.content || {}, `${item.name}.pdf`)} />
                                <Button
                                    type='primary'
                                    icon={<EditOutlined />}
                                    onClick={() => openPresentationModal(item)} />
                                <Popconfirm
                                    title='Вы уверены, что хотите удалить самопрезентацию?'
                                    okText='Да'
                                    cancelText='Нет'
                                    placement='left'
                                    onConfirm={() => this.props.deletePresentation.dispatch(item.id)}>
                                    <Button
                                        danger
                                        icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Button.Group>
                    }
                ]} />
            <a ref={node => this.pdfLink = node} href='presentation' download style={{ display: 'none' }}>presentation</a>
        </div>;
    }
}

export default asyncConnect({
    getPresentations: getPresentations
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_PRESENTATION), toSuccess(POST_PRESENTATION), toSuccess(DELETE_PRESENTATION)], function() {
                getProps().getPresentations.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
    deletePresentation: deletePresentation
        .withSuccessHandler(() => message.success('Самопрезентация успешно удалена'))
        .withOptions({ resetOnUnmount: true })
}, null, { openPresentationModal, openPresentationViewModal })(Presentations);
