export const LOGOUT = 'LOGOUT';

export const POST_LOGIN = 'POST_LOGIN';
export const POST_RECOVERY = 'POST_RECOVERY';
export const POST_RESET = 'POST_RESET';
export const PUT_PASSWORD = 'PUT_PASSWORD';
export const GET_REGISTRATION_PASSWORD = 'GET_REGISTRATION_PASSWORD';
export const POST_REGISTRATION_PASSWORD = 'POST_REGISTRATION_PASSWORD';
export const GET_AGREEMENT_STATUS = 'GET_AGREEMENT_STATUS';

export const GET_USER = 'GET_USER';

export const PATCH_PROFILE = 'PATCH_PROFILE';

export const POST_DOCUMENT = 'POST_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

export const OPEN_VIDEO_MODAL = 'OPEN_VIDEO_MODAL';
export const OPEN_PASSWORD_MODAL = 'OPEN_PASSWORD_MODAL';
export const OPEN_COVER_LETTER_MODAL = 'OPEN_COVER_LETTER_MODAL';
export const OPEN_RESUME_MODAL = 'OPEN_RESUME_MODAL';
export const OPEN_RESUME_VIEW_MODAL = 'OPEN_RESUME_VIEW_MODAL';
export const OPEN_SEND_RESUME_MODAL = 'OPEN_SEND_RESUME_MODAL';
export const OPEN_HH_RESUMES_MODAL = 'OPEN_HH_RESUMES_MODAL';
export const OPEN_MOTIVATION_MODAL = 'OPEN_MOTIVATION_MODAL';
export const OPEN_PERSONALITY_MODAL = 'OPEN_PERSONALITY_MODAL';
export const OPEN_CRITERIA_MODAL = 'OPEN_CRITERIA_MODAL';
export const OPEN_PRESENTATION_MODAL = 'OPEN_PRESENTATION_MODAL';
export const OPEN_PRESENTATION_VIEW_MODAL = 'OPEN_PRESENTATION_VIEW_MODAL';
export const OPEN_COVER_LETTER_VIEW_MODAL = 'OPEN_COVER_LETTER_VIEW_MODAL';
export const OPEN_EVENT_MODAL = 'OPEN_EVENT_MODAL';
export const OPEN_CONSULTATION_MODAL = 'OPEN_CONSULTATION_MODAL';
export const OPEN_CONSULTATION_VIEW_MODAL = 'OPEN_CONSULTATION_VIEW_MODAL';
export const OPEN_CANCEL_CONSULTATION_MODAL = 'OPEN_CANCEL_CONSULTATION_MODAL';
export const OPEN_PROMOTION_REQUEST_MODAL = 'OPEN_PROMOTION_REQUEST_MODAL';
export const OPEN_TEST_REQUEST_MODAL = 'OPEN_TEST_REQUEST_MODAL';
export const OPEN_DOCUMENT_MODAL = 'OPEN_DOCUMENT_MODAL';

export const GET_STAGES = 'GET_STAGES';
export const GET_STAGE = 'GET_STAGE';

export const PUT_MATERIAL_VIEW = 'PUT_MATERIAL_VIEW';
export const GET_MATERIAL = 'GET_MATERIAL';
export const PUT_MATERIAL_RESULT = 'PUT_MATERIAL_RESULT';
export const PUT_CHECK_LIST = 'PUT_CHECK_LIST';

export const GET_FAQ = 'GET_FAQ';

export const GET_RESUME = 'GET_RESUME';
export const PUT_RESUME = 'PUT_RESUME';
export const GET_RESUMES = 'GET_RESUMES';
export const POST_RESUME = 'POST_RESUME';

export const POST_REFRESH_TOKEN = 'POST_REFRESH_TOKEN';

export const POST_REGISTRATION = 'POST_REGISTRATION';
export const POST_PARTICIPATION_REQUEST = 'POST_PARTICIPATION_REQUEST';

export const GET_LETTERS = 'GET_LETTERS';
export const GET_LETTER = 'GET_LETTER';
export const DELETE_LETTER = 'DELETE_LETTER';
export const POST_LETTER = 'POST_LETTER';
export const PUT_LETTER = 'PUT_LETTER';

export const GET_SETTINGS = 'GET_SETTINGS';

export const GET_PROFILE_PATH = 'GET_PROFILE_PATH';
export const PUT_PROFILE_PATH = 'PUT_PROFILE_PATH';

export const POST_SEND_RESUME = 'POST_SEND_RESUME';

export const POST_HH_TOKEN = 'POST_HH_TOKEN';

export const GET_HH_RESUMES = 'GET_HH_RESUMES';
export const GET_HH_RESUME = 'GET_HH_RESUME';

export const GET_PRESENTATION = 'GET_PRESENTATION';
export const GET_PRESENTATIONS = 'GET_PRESENTATIONS';
export const POST_PRESENTATION = 'POST_PRESENTATION';
export const PUT_PRESENTATION = 'PUT_PRESENTATION';
export const DELETE_PRESENTATION = 'DELETE_PRESENTATION';

export const GET_REGISTRATION_CHECK = 'GET_REGISTRATION_CHECK';

export const GET_TESTS = 'GET_TESTS';
export const GET_TEST = 'GET_TEST';
export const PUT_TEST_PARTICIPATE = 'PUT_TEST_PARTICIPATE';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENT = 'GET_EVENT';
export const GET_SPEAKERS = 'GET_SPEAKERS';
export const PUT_EVENT_PARTICIPATE = 'PUT_EVENT_PARTICIPATE';
export const DELETE_EVENT_PARTICIPATE = 'DELETE_EVENT_PARTICIPATE';

export const GET_CONSULTATIONS = 'GET_CONSULTATIONS';
export const GET_CONSULTATION = 'GET_CONSULTATION';
export const POST_PROMOTIONS = 'POST_PROMOTIONS';

export const GET_PROMOTION_REQUESTS = 'GET_PROMOTION_REQUESTS';
export const GET_PROMOTION_REQUESTS_THEMES = 'GET_PROMOTION_REQUESTS_THEMES';
export const POST_PROMOTION_REQUESTS = 'POST_PROMOTION_REQUESTS';
export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQUEST';

export const POST_FEEDBACK_CONSULTATION = 'POST_FEEDBACK_CONSULTATION';
export const POST_FEEDBACK_EVENT = 'POST_FEEDBACK_EVENT';
export const GET_EVENT_HAS_FEEDBACK = 'GET_EVENT_HAS_FEEDBACK';
export const GET_CONSULTATION_HAS_FEEDBACK = 'GET_CONSULTATION_HAS_FEEDBACK';

export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const POST_EMPLOYEE_LIST = 'POST_EMPLOYEE_LIST';

export const GET_PARTICIPATION_REQUEST_ROLE = 'GET_PARTICIPATION_REQUEST_ROLE';
export const POST_PARTICIPATION_REQUEST_ROLE = 'POST_PARTICIPATION_REQUEST_ROLE';
