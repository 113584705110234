import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Spin, Table, Modal, Input, Button, message } from 'antd';
import styled from 'styled-components';
import { withAsyncActions } from 'react-async-client';
import { path } from 'ramda';
import qs from 'qs';
import moment from 'moment';
import { withState } from 'recompose';

import { getEmployeeList, postEmployeeList } from '../actions/asyncActions';
import Logo from './Logo';
import EmployeeListForm from './forms/EmployeeListForm';

const WrapBox = styled.div`
  display: flex;
  flex-direction: column;
  color: #2b3d4f;
  background: #F4F7FC;
  min-height: 100vh;
  min-width: 980px;
  .ant-pagination-item,
  .ant-pagination-item-link,
  .ant-select-selector {
    border-radius: 0;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #2B3D4E;
    border-color: #2B3D4E;
    a {
      color: #fff;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #c3cad2;
  }
  .ant-btn {
    border-radius: 0;
    box-shadow: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
`;

const HeadWrap = styled.div`
  padding: 0px 0;
  background: #fff;
  @media (max-width: 767px) {
    .earusm {
      position: static;
      width: 160px;
      height: 70px;
      margin: 0;
      background-size: 160px auto;
      background-position: 0;
    }
  }
`;

const TitleWrap = styled.div`
  padding: 16px 0;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #2b3d4f;
`;

const Content = styled.div`
  max-width: 1280px;
  padding: 0 24px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const ModalWrap = styled(Modal)`
  .ant-modal-content {
    border-radius: 0;
    .ant-modal-title {
      font-weight: bold;
    }
  }
  .ant-form-item-control-input input{
    border-radius: 0;
  }
`;

const ButtonBox = styled(Button)`
  border-radius: 0;
  box-shadow: none;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
`;

const TableWrap = styled(Table)`
  .ant-table-thead > tr > th {
    font-weight: bold;
    color: #2b3d4f;
    font-size: 13px;
  }
  tr:nth-child(2n) td {
    background-color: #F9FAFC;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e4eefa;
  }
`;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const sortDefault = {
  sort_by: 'programStartedAt',
  sort_order: 'desc',
};

const sortOrder = {
  ascend: 'asc',
  descend: 'desc',
};

const getAuthCode = location => path(['auth_code'], qs.parse(location.search, { ignoreQueryPrefix: true }));

const Employment = ({ postEmployeeList, getEmployeeList, match: { params: { link } }, authCode, setAuthCode, history }) => {
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState(sortDefault);
  const [formValues, setFormValues] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGetItems = (auth_code, filter = {}, sort = sortDefault) => {
    getEmployeeList.dispatch({
      id: link,
      auth_code,
      q: {
        ...filter,
      },
      ...sort,
    });
  };
  const onTableChange = (pagination, filter, sorter) => {
    const newFilter = {
      industry: path(['industryName', 0], filter),
      function: path(['functionName', 0], filter),
    };

    const newSort = {
      sort_by: sorter.field,
      sort_order: sorter.order ? sortOrder[sorter.order] : sort.sort_order === 'desc' ? 'asc' : 'desc',
    };

    setSort(newSort);
    setFilter(newFilter);

    handleGetItems(authCode, newFilter, newSort)
  }

  if (getEmployeeList.meta.pending) {
    return <WrapBox><Spin /></WrapBox>;
  }

  if (!link) {
    return (
      <WrapBox>
        <div>
          {path(['data', 'message'], getEmployeeList.meta.error) || 'Некорректная ссылка'}
        </div>
      </WrapBox>
    );
  }

  if (!authCode || getEmployeeList.meta.error) {
    return (
      <WrapBox>
        <div>
          <HeadWrap>
            <Content>
              <Logo wizard="1" />
            </Content>
          </HeadWrap>
          <TitleWrap>
            <Content>
              <Head>
                <Title>Таблица участников доступных для трудоустройства</Title>
              </Head>
            </Content>
          </TitleWrap>

          <Content>
            Введите код доступа:
            <Input.Search
              name="auth_code"
              placeholder="Введите код доступа к ссылке"
              enterButton="Проверить"
              size="large"
              defaultValue={authCode}
              onSearch={(value) => {
                setAuthCode(value);
                handleGetItems(value);
              }}
            />
            <p>{path(['data', 'message'], getEmployeeList.meta.error)}</p>
          </Content>
        </div>
      </WrapBox>
    );
  }

  const dataSource = path(['data', 'items'], getEmployeeList) || [];
  const industryHash = {};
  const industryFilters = [];
  const functionHash = {};
  const functionFilters = [];

  dataSource.forEach(item => {
    if (item.industry && !industryHash[item.industry]) {
      industryHash[item.industry] = item.industryName;
      industryFilters.push({
        text: item.industryName,
        value: item.industry,
      });
    }
    if (item.function && !industryHash[item.function]) {
      functionHash[item.function] = item.functionyName;
      functionFilters.push({
        text: item.functionName,
        value: item.function,
      });
    }
  })

  const columns = [
    {
      title: 'Уровень должности',
      dataIndex: 'position',
      key: 'position',
      width: '25%',
    },
    {
      title: 'Отрасль',
      dataIndex: 'industryName',
      key: 'industryName',
      filterMultiple: false,
      filteredValue: filter.industry ? [filter.industry] : null,
      filters: industryFilters,
    },
    {
      title: 'Направление',
      dataIndex: 'functionName',
      key: 'functionName',
      filterMultiple: false,
      filteredValue: filter.function ? [filter.function] : null,
      filters: functionFilters,
    },
    {
      title: 'Локация',
      dataIndex: 'location',
      key: 'location',
      width: '200px',
      sorter: sort['sort_by'] === 'location',
      sortOrder: sort['sort_by'] === 'location' ? (sort['sort_order'] === 'desc' ? 'descend' : 'ascend') : null,
    },
    {
      title: 'Дата увольнения',
      dataIndex: 'programStartedAt',
      key: 'programStartedAt',
      width: '180px',
      sorter: sort['sort_by'] === 'programStartedAt',
      sortOrder: sort['sort_by'] === 'programStartedAt' ? (sort['sort_order'] === 'desc' ? 'descend' : 'ascend') : null,
      render: date => date ? moment(date).format('MM.YYYY') : '',
    },
  ];

  return <WrapBox>
          <div>
            <HeadWrap>
              <Content>
                <Logo wizard="1" />
              </Content>
            </HeadWrap>
            <TitleWrap>
              <Content>
                <Head>
                  <Title>Таблица участников доступных для трудоустройства</Title>
                  <ButtonBox type="primary" disabled={!path(['length'], selectedRowKeys)} onClick={showModal}>Сделать запрос на контакты</ButtonBox>
                </Head>
              </Content>
            </TitleWrap>

            <Content>
              <TableWrap
                rowSelection={{
                  selectedRowKeys,
                  onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                scroll={{ y: 'calc(100vh - 300px)' }}
                rowKey={item => JSON.stringify(item)}
                onChange={onTableChange}
              />
            </Content>

            <ModalWrap title="Запрос кандидатов"
              width={500}
              visible={isModalOpen}
              onCancel={handleCancel}
              footer={null}
            >
              <p>Просим заполнить контактные данные для получения доступа к кандидатам</p>
              { isModalOpen &&
                <EmployeeListForm
                  isSubmitting={postEmployeeList.meta.pending}
                  formItemLayout={formItemLayout}
                  formAction={postEmployeeList}
                  onSubmitSuccess={(formProps) => {
                    setFormValues(path(['data'], formProps.formAction) || {});
                    handleOk();
                  }}
                  onCancel={handleCancel}
                  formLayout="horizontal"
                  item={{
                    ...formValues,
                    id: link,
                    auth_code: authCode,
                    employeesListLink: link,
                    selectedEmployees: selectedRowKeys.map(item => JSON.parse(item)),
                  }}
                />
              }
            </ModalWrap>
          </div>
      </WrapBox>;
}

export default withState('authCode', 'setAuthCode', ({ location }) => getAuthCode(location))(withAsyncActions({
  getEmployeeList: getEmployeeList
    .withPayload(({ match: { params: { link } }, authCode }) => ({ id: link, auth_code: authCode, ...sortDefault }))
    .withOptions(({ authCode }) => ({ dispatchOnMount: !!authCode, resetOnUnmount: true })),
  postEmployeeList: postEmployeeList
    .withSuccessHandler(() => message.success('Запрос отправлен'))
    .withErrorHandler(() => message.error('Не удалось отправить запрос'))
    .withOptions({ resetOnUnmount: true }),
})(connect(null, {})(Employment)));
