import React, { Component, Fragment } from 'react';
import { asyncConnect } from 'react-async-client';
import { Table, Menu, Dropdown, Tooltip, Button } from 'antd';
import styled from 'styled-components';
import { generateResumePdf, downloadResumeAsDocx, getHHAuthLink } from '@meconsultant/common';
import { takeEvery } from 'redux-saga/effects';
import { FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { toSuccess } from 'react-async-client/lib/actionHelpers';
import { pathOr } from 'ramda';
import { PlusOutlined, EyeOutlined, DownloadOutlined, MailOutlined, EditOutlined } from '@ant-design/icons';

import { openResumeModal, openResumeViewModal, openSendResumeModal } from '../../actions/modalActions';
import { PUT_RESUME, POST_RESUME } from '../../constants/actionTypes';
import { getResumes } from '../../actions/asyncActions';
import HHResumes from '../HHResumes';

const WrapBtn = styled.div`
    text-align: right;
    padding: 0 15px 15px;
    border-bottom: 1px solid #e8e8e8;
    @media (max-width: 767px) {
        padding: 0 0 10px;
    }
`;

const WrapBtnIcon = styled(Button.Group)`
    white-space: nowrap;
`;

class Resumes extends Component {
    getOverlay = resume => {
        return <Menu>
            <Menu.Item onClick={() => generateResumePdf(resume, `${resume.position}.pdf`)}>
                <FilePdfOutlined />
                PDF-файл
            </Menu.Item>
            <Menu.Item onClick={() => downloadResumeAsDocx(resume)}>
                <FileWordOutlined />
                Word-файл
            </Menu.Item>
        </Menu>;
    }

    onOpenResume = (item, hhResume) => {
        this.props.openResumeModal({
            item,
            hhResume
        });
    }

    openHH = () => {
        window.hhLocation = window.open(getHHAuthLink(), '_blank');
    }

    render() {
        const { getResumes, openResumeModal, openResumeViewModal, openSendResumeModal } = this.props;

        return <HHResumes onOpenResume={this.onOpenResume}>
            <Fragment>
                <WrapBtn>
                    <Button.Group>
                        <Button icon={<PlusOutlined />} type='primary' onClick={() => openResumeModal()}>
                            Добавить
                        </Button>
                        <Tooltip title='Импортировать данные с резюме HeadHunter'>
                            <Button onClick={this.openHH}>
                                <img src='/hh.svg' width={18} style={{ marginRight: 7 }} alt='hh' />
                                Импортировать
                            </Button>
                        </Tooltip>
                    </Button.Group>
                </WrapBtn>
                <Table
                    dataSource={pathOr([], ['data', 'items'], getResumes)}
                    pagination={false}
                    showHeader={false}
                    rowKey='id'
                    columns={[
                        {
                            key: 'resume',
                            dataIndex: ['content', 'position']
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            render: item =>
                                <WrapBtnIcon>
                                    <Button
                                        icon={<EyeOutlined />}
                                        onClick={() => openResumeViewModal(item)} />
                                    <Dropdown trigger={['click']} overlay={this.getOverlay(item.content)}>
                                        <Button
                                            icon={<DownloadOutlined />} />
                                    </Dropdown>
                                    <Tooltip title='Отправить резюме в кадровый холдинг АНКОР'>
                                        <Button
                                            icon={<MailOutlined />}
                                            onClick={() => openSendResumeModal(item.content)} />
                                    </Tooltip>
                                    <Button
                                        type='primary'
                                        icon={<EditOutlined />}
                                        onClick={() => openResumeModal({ item })} />
                                </WrapBtnIcon>
                        }
                    ]} />
            </Fragment>
        </HHResumes>;
    }
}

export default asyncConnect({
    getResumes: getResumes
        .withSaga(function* (getProps) {
            yield takeEvery([toSuccess(PUT_RESUME), toSuccess(POST_RESUME)], function() {
                getProps().getResumes.refresh();
            });
        })
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true })
}, null, { openResumeViewModal, openSendResumeModal, openResumeModal })(Resumes);
