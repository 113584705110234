import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { Link } from 'react-router-dom';
import { Spin, Row, Col, Layout } from 'antd';
import { pathOr, propEq } from 'ramda';
import styled from 'styled-components';

import { getStages, getUser } from '../../actions/asyncActions';

const WrapBox = styled(Layout)`
    background: #fff;
    box-shadow: 0px 4px 4px rgba(132,138,163,0.1);
    border-radius: 12px;
    padding: 24px;
    padding-top: 12px;
    flex: initial;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 16px;
    }

    h3 {
        font-weight: 300;
        margin: 0;
        margin-top: 12px;
    }
`;

const ListForm = styled.section`
    position: relative;
    color: #363636;

    .label{
        font-weight: 300;
        font-size: 16px;
    }
    .value {
        font-size: 16px;
    }
    .row-group{
        margin-bottom: 10px;
        b {
            word-break: break-all;
        }
    }
`;

class ProfileFavorites extends Component {
    getDictName = (dict, value) => {
        return pathOr(null, ['name'], dict.find(propEq('id', value)));
    }

    renderRow = (title, value) => {
        return <Row className='row-group' gutter={16}>
            <Col xs={24} md={12}>
                <span className='label'>{ title }</span>
            </Col>
            <Col xs={24} md={12}>
                <span className='value'>{ value || '-' }</span>
            </Col>
        </Row>;
    }

    render() {
        const { getStages: { data, meta }} = this.props;
        const items = data.items || [];

        return <ListForm>
            { meta.pending && !meta.lastSucceedAt ? <Spin /> : items.length ? items.map(item =>
                <WrapBox key={item.id}>
                    { item.themes.map((theme, index) =>
                        <div key={`theme-${index}`}>
                            <h3>{ theme.title }</h3>
                            { theme.materials.map(material =>
                                <div key={material.id}>
                                    <Link to={`/stage/${item.id}/material/${material.id}`}>{ material.title }</Link>
                                </div>
                            )}
                        </div>
                    )}
                </WrapBox>
            ) : <div>Нет материалов</div>}
        </ListForm>;
    }
}

const stateToProps = state => ({
    user: getUser.selectData(state)
});

export default asyncConnect({
    getStages: getStages
        .withPayload(() => ({
            q: { favorite: true }
        }))
        .withOptions({ dispatchOnMount: true, resetOnUnmount: true }),
}, stateToProps)(ProfileFavorites);
