import React, { Component } from 'react';
import { asyncConnect } from 'react-async-client';
import { PersonalityAnalysisComponent } from '@meconsultant/common';

import { putProfilePath, getProfilePath, getUser } from '../actions/asyncActions';

class PersonalityAnalysis extends Component {
    render() {
        const { putProfilePath, gender } = this.props;

        return <PersonalityAnalysisComponent
            {...this.props}
            formAction={putProfilePath}
            getItem={getProfilePath}
            gender={gender} />
    }
}

const stateToProps = state => ({
    gender: getUser.selectData(state).gender
});

export default asyncConnect({
    putProfilePath: putProfilePath
        .withOptions({ resetOnUnmount: true })
}, stateToProps)(PersonalityAnalysis);
