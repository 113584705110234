import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

import GuestRoutes from '../../GuestRoutes';
import Logo from '../../Logo';

const GuestLayout = styled(Layout)`
    text-align: center;
    display: block !important;
    background: #2b3d4f;
    padding: 0 16px;
    &:before {
        vertical-align: middle;
        display: inline-block;
        content: '';
        height: 100vh;
        width: 1px;
    }
    .ant-form-item,button{
        margin-bottom: 25px;
        width: 100%;
    }
    .ant-form-item-has-error {
        .ant-form-item-explain {
            position: absolute;
            bottom: -24px;
            font-size: 12px;
            text-align: center;
            width: 100%;
        }
    }
`;

const GuestContent = styled.div`
    vertical-align: middle;
    display: inline-block;
    width: 400px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 35px 40px;
    @media (max-width: 767px) {
        max-width: 90%;
        padding: 20px 25px;
    }
    .ant-form-item-extra {
        padding-top: 8px;
    }
`;

const GuestHeader = styled.div`
    margin-bottom: 30px;
    text-align: center;
    h2 {
        font-size: 16px;
        color: #444c63;
        font-weight: 300;
        text-align: center;
    }
`;

const GuestLogo = styled(Logo)`
    @media (max-width: 767px) {
        background-size: 140px auto;
        background-position: center 8px;
        height: 60px;
        top: 0;
        margin: 0;
    }
`;

const GuestLayoutComponent = () => (
    <GuestLayout>
        <GuestContent>
            <GuestHeader>
                <GuestLogo guest={1} />
            </GuestHeader>
            <GuestRoutes />
        </GuestContent>
    </GuestLayout>
);

export default GuestLayoutComponent;
