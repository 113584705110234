import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logout } from '../../../actions/appActions';
import MaterialSider from './MaterialSider';
import { isMobile } from '../../../utils/screen';

class Sider extends Component {
    static propTypes = {
        logout: PropTypes.func,
        openVideoModal: PropTypes.func,
        pathname: PropTypes.string,
    };

    state = {
        collapsed: false
    };

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        if (!isMobile() && this.state.collapsed) {
            this.setState({ collapsed: false });
        }
    }

    onCollapse = collapsed => this.setState({ collapsed });

    toggle = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    onClickMenuItem = () => {
        if (isMobile()) {
            this.setState({ collapsed: true });
        }
    }

    render() {
        const { pathname } = this.props;
        const isMaterialRoute = /material/.test(pathname);

        return isMaterialRoute ? <MaterialSider /> : false;
    }
}

const stateToProps = ({ router }) => ({
    pathname: router.location.pathname
});

export default connect(stateToProps, { logout })(Sider);
