import React, { Component } from 'react';
import { Button, message, Modal } from 'antd';
import { path } from 'ramda';
import qs from 'qs';
import moment from 'moment';
import { GoogleOutlined } from '@ant-design/icons';
import pluralize from 'pluralize-ru';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { openCancelConsultationModal } from '../../actions/modalActions';
import { getOnlineIcon } from '../../utils/events';
import CopyToClipboard from 'react-copy-to-clipboard';

const Centered = styled.div`
    display: flex;
    align-items: center;
    img {
        margin-right: 5px;
    }
    button {
        margin-left: 5px;
    }
`;

class ConsultationViewModal extends Component {
    state = {
        consultant: null
    };

    onCopy = (_, result) => {
        result ? message.success('Ссылка успешно скопирована') : message.error('Не удалось скопировать ссылку');
    };

    getGoogleLink = () => {
        const { params: { item }} = this.props;
        const consultant = path(['_embedded', 'consultant'], item);

        return `https://www.google.com/calendar/event?${qs.stringify({
            action: 'TEMPLATE',
            text: `Консультация (${consultant.lastName} ${consultant.firstName}${consultant.middleName ? ` ${consultant.middleName}` : ''})`,
            details: item.description,
            location: item.location,
            dates: moment(item.start).format('YYYYMMDDTHHmmss') + '/' + moment(item.end).format('YYYYMMDDTHHmmss')
        })}`;
    }

    render() {
        const { params: { item, onCloseCancel }, modal, openCancelConsultationModal } = this.props;
        const duration = moment(item.end).diff(moment(item.start), 'minutes');
        const hours = Math.floor(duration / 60);
        const minutes = duration - hours * 60;
        const consultant = path(['_embedded', 'consultant'], item);

        return <Modal
            {...modal}
            wrapClassName="modal-consultation"
            closeIcon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C13.1819 3 14.3522 3.23279 15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4441 20.3149C14.3522 20.7672 13.1819 21 12 21L12 21Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M9 9L15 15" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M15 9L9 15" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            }
            title='Консультация'
            footer={null}>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3.00244" y="6" width="18" height="15" rx="2" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M3.00244 10C3.00244 8.11438 3.00244 7.17157 3.58823 6.58579C4.17401 6 5.11682 6 7.00244 6H17.0024C18.8881 6 19.8309 6 20.4167 6.58579C21.0024 7.17157 21.0024 8.11438 21.0024 10H3.00244Z" fill="#A7B9CF"/>
                    <path d="M7.00244 3L7.00244 6" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M17.0024 3L17.0024 6" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <rect x="7.00244" y="12" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="7.00244" y="16" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="13.0024" y="12" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                    <rect x="13.0024" y="16" width="4" height="2" rx="0.5" fill="#A7B9CF"/>
                </svg>
                { moment(item.start).format('DD.MM.YYYY') }, { moment(item.start).format('dddd') }
            </div>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.0024" cy="12" r="9" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M16.5024 12H12.2524C12.1144 12 12.0024 11.8881 12.0024 11.75V8.5" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                { moment(item.start).format('HH:mm') }-{ moment(item.end).format('HH:mm') }
                <div style={{ fontSize: 11, color: '#fff', background: '#2B3D4F', borderRadius: 4, padding: '3px 8px', display: 'inline-block', marginLeft: 10 }}>
                    { `${hours ? `${hours} ${pluralize(hours, '', 'час', 'часа', 'часов')} ` : ''}${minutes || ''} ${pluralize(minutes, '', 'минута', 'минуты', 'минут')}` }
                </div>
            </div>
            <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.798 20.7059C17.4561 19.6427 16.7029 18.7033 15.655 18.0332C14.6071 17.3632 13.3232 17 12.0024 17C10.6816 17 9.39774 17.3632 8.34987 18.0332C7.30201 18.7033 6.54874 19.6427 6.20689 20.7059" stroke="#A7B9CF" strokeWidth="2"/>
                    <circle cx="12.0024" cy="10" r="3" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <rect x="3.00244" y="3" width="18" height="18" rx="3" stroke="#A7B9CF" strokeWidth="2"/>
                </svg>
                <div style={{ lineHeight: 1.3 }}>
                    <div>{ consultant.lastName } { consultant.firstName } { consultant.middleName || '' }</div>
                    <div><small style={{ marginLeft: 0 }}>Консультант</small></div>
                </div>
            </div>
            { item.location &&
                <Centered className="modal-item">
                    { getOnlineIcon(item.location) ? <img src={getOnlineIcon(item.location).icon} alt='icon' /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4V16C8 16.5304 8.21071 17.0391 8.58579 17.4142C8.96086 17.7893 9.46957 18 10 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V7.242C20 6.97556 19.9467 6.71181 19.8433 6.46624C19.7399 6.22068 19.5885 5.99824 19.398 5.812L16.083 2.57C15.7094 2.20466 15.2076 2.00007 14.685 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V4Z" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16 18V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V9C4 8.46957 4.21071 7.96086 4.58579 7.58579C4.96086 7.21071 5.46957 7 6 7H8" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>}
                    { getOnlineIcon(item.location) && getOnlineIcon(item.location).text }
                    <CopyToClipboard text={item.location} onCopy={this.onCopy}><Button size="small" type='dashed' danger>Скопировать ссылку</Button></CopyToClipboard>
                </Centered>
            }
            { item.description && <div className="modal-item">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5.00244" y="4" width="14" height="17" rx="2" stroke="#A7B9CF" strokeWidth="2"/>
                    <path d="M9.00244 9H15.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M9.00244 13H15.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M9.00244 17H13.0024" stroke="#A7B9CF" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                { item.description }
            </div>}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button className='cancel-btn' onClick={() => openCancelConsultationModal({ externalId: item.externalId, onClose: onCloseCancel })}>
                    отменить или перенести
                </Button>
                { !moment().isAfter(moment(item.start)) && <Button
                    target='_blank'
                    href={this.getGoogleLink()}
                    icon={<GoogleOutlined />} />
                }
            </div>
        </Modal>;
    }
}

export default connect(null, { openCancelConsultationModal })(ConsultationViewModal);
