import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { FileTextOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { getStage } from '../../../actions/asyncActions';
import {isMobile} from "../../../utils/screen";

const TriggerBox = styled.section`
    display: none;
    @media (max-width: 767px) {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: initial;
        font-size: 20px;
        color: #222;
        padding: 7px 12px;
    }
`;

const Header = styled.div`
    @media (max-width: 767px) {
        display: inline-block;
        position: fixed;
        z-index: 9;
        top: 0;
        background: #fff;
        left: 0;
        right: 0;
        color: #222;
        padding: 7px 12px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    }
`;

const MenuItemStyled = styled(Menu.Item)`
    height: auto!important;
    white-space: normal!important;
    line-height: 1.5em!important;
    padding: 5px 8px 5px 16px!important;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 25px!important;
    .anticon{
        display: table-cell;
        vertical-align: middle;
        padding-right: 10px;
    }
    span{
        display: table-cell;
    }
`;

const MenuItemHead = styled(Menu.Item)`
    height: auto!important;
    white-space: normal!important;
    line-height: 1.5em!important;
    background: #F7F8FC;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #576684;
    padding: 8px !important;
    display: inline-block;
    &.ant-menu-item {
        .anticon {
            border: 0;
            padding-left: 8px;
        }
    }
    @media (max-width: 767px) {
        box-shadow: none;
        background: transparent;
        margin-bottom: 0!important;
        min-height: 40px;
        display: inline-flex;
        padding: 4px 8px 0 22px !important;
        align-items: center;
    }
    &.ant-menu-item > a {
        color: #2c3d4e;
    }
    &:hover {
        .anticon,span {
            color: #F54D2E;
            border-color: #F54D2E;
        }
    }
    .anticon{
        display: table-cell;
        vertical-align: middle;
        padding-right: 8px;
        color: #2B3D4F;
        font-size: 16px;
        @media (max-width: 767px) {
            display: none;
        }
    }
    span{
        display: table-cell;
        border-left: 1px solid #2B3D4F;
        padding-left: 8px;
    }
`;

const Sider = styled(Layout.Sider)`
    position: fixed;
    z-index: 980;
    top: 16px;
    padding: 16px 16px 16px 0;
    background: transparent !important;
    .ant-menu {
            border: 0;
            background: transparent !important;
        }
    @media (max-width: 767px) {
        display: none;
        background: #efefef !important;
        left: 0;
        .ant-menu {
            background: #efefef !important;
            border-right: 1px solid #e4e4e4;
        }
    }
    .ant-menu-item-group-title {
        padding: 24px 0px 4px 0px;
        font-size: 12px;
        font-weight: 300;
        font-weight: normal;
        text-transform: uppercase;
        color: #333;
    }
    .ant-menu-item-group-list {
        .ant-menu-item .anticon {
            font-size: 0;
            width: 11px;
            height: 11px;
            display: inline-block;
            float: left;
            border: 1px solid #BDBDBD;
            background: #fff;
            border-radius: 50%;
            min-width: 11px;
            padding: 0;
            position: absolute;
            left: 1px;
            top: 50%;
            margin-top: -5px;
            @media (max-width: 767px) {
                left: 7px;
            }
        }
        .ant-menu-item {
            margin: 0;
            width: 100%;
            &:first-child {
                & > a {
                    &:before {
                        top: 50%;
                    }
                }
            }
            &:last-child {
                & > a {
                    &:before {
                        bottom: 50%;
                    }
                }
            }
            & > a{
                font-weight: 300;
                color: #000;
                &:before {
                    content: '';
                    width: 1px;
                    background: #BDBDBD;
                    position: absolute;
                    left: 6px;
                    top: 0;
                    bottom: 0;
                    @media (max-width: 767px) {
                        left: 12px;
                    }
                }
            }
            &:after {
                border: 0;
            }
            &:not(:last-child) {
                margin: 0;
            }
        }
        .ant-menu-item-selected {
            background-color: transparent!important;
            & > a {
                font-weight: 500;
                .anticon {
                    background: #E3FFE7;
                    border-color: #3AB54A;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .ant-layout-sider-children{
            display: flex;
            position: absolute;
            top: 0;
            flex-direction: column;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
    &.ant-layout-sider-collapsed{
        ${TriggerBox}{
            right: initial;
            left: 0;
            color: #000;
        }
    }
    .ant-menu-inline-collapsed{
        width: 100%;
    }
`;

class MaterialSider extends Component {
    state = {
        collapsed: false
    };

    static propTypes = {
        location: PropTypes.object,
        stage: PropTypes.object,
        type: PropTypes.string,
        id: PropTypes.string
    };

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount () {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        if (!isMobile() && this.state.collapsed) {
            this.setState({ collapsed: false });
        }
    }

    onCollapse = collapsed => this.setState({ collapsed });

    toggle = () => this.setState(prev => ({ collapsed: !prev.collapsed }));

    onClickMenuItem = () => {
        if (isMobile()) {
            this.setState({ collapsed: true });
        }
    }

    renderThemes = () => {
        const { stage, type } = this.props;

        return (stage.themes || []).map((theme, index) =>
            <Menu.ItemGroup key={`theme-${index}`} title={theme.title}>
                { theme.materials.map(material =>
                    <MenuItemStyled key={material.id}>
                        <NavLink to={{
                            pathname: `/stage/${type}/material/${material.id}`,
                            state: {
                                stage,
                                title: material.title
                            }
                        }}>
                            <FileTextOutlined /><span>{ material.title }</span>
                        </NavLink>
                    </MenuItemStyled>
                )}
            </Menu.ItemGroup>
        );
    }

    render() {
        const { type, id, stage } = this.props;
        const { collapsed } = this.state;

        return <Sider collapsedWidth="0" breakpoint="md" collapsed={collapsed} onCollapse={this.onCollapse} className='left-menu'>
            <Header>
                <TriggerBox onClick={this.toggle}>
                    { collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined /> }
                </TriggerBox>
            </Header>
            <Menu
                className='sub-group-menu'
                mode='inline'
                onClick={this.onClickMenuItem}
                selectedKeys={[id]}>
                <MenuItemHead className='menu-back' key='01'>
                    <NavLink to={`/stage/${type}`}>
                        <ArrowLeftOutlined />
                        <span>{ stage.title }</span>
                    </NavLink>
                </MenuItemHead>
                { this.renderThemes() }
            </Menu>
        </Sider>;
    }
}

const stateToProps = (state, { location }) => {
    const paths = location.pathname.split('/');
    const type = paths[2];

    return {
        type,
        id: paths[4],
        stage: pathOr(getStage.selectData(state), ['state', 'stage'], location)
    };
}

export default withRouter(
    connect(stateToProps)(MaterialSider)
);
